
export const state = () => ({
    searchArea: {},
    loading: false,
    properties: [],
});

export const mutations = {
    
    setProperties(state, change) {
        state.properties = change;
    },
    
    setLoading(state, change) {
        state.loading = change;
    },

    setSearchArea(state, change) {
        state.searchArea = change;
    },

    clearLoading(state) {
        state.loading = false;
    },
    
};

export const getters = {
    
    getProperties(state) {
        return state.properties;
    },
    
    getLoading(state) {
        return state.loading;
    },  

    getSearchArea(state) {
        return state.searchArea;
    }
};

export default {
    namespaced: true,
    state,
    getters,
    mutations,
};
