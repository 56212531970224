
import EntityCard from "./components/EntityCard.vue";

export default {
  components: { EntityCard },

  props: {
    controlsListHideAddAction: {
      type: Boolean,
      default: false,
    },

    bfeNumber: {
      type: [String, Number],
      required: true,
    },

    shadow: {
      type: Boolean,
      default: false,
    },

    rounded: {
      type: Boolean,
      default: false,
    },

    trackingOrigin: {
      type: String,
      required: true,
    },

    lists: {
      type: Array,
      default: () => {
        return [];
      },
    },

    remarks: {
      type: Array,
      default: () => {
        return [];
      },
    },

    colorBackground: {
      type: String,
      required: true,
    },

    colorSeperators: {
      type: String,
      required: true,
    },

    colorForeground: {
      type: String,
      required: true,
    },
  },

  methods: {
    navigateToList(item) {
      this.$emit("navigate");

      setTimeout(() => {
        this.$amplitude.track({
          event_type: "Open list",
          event_properties: {
            route: this.trackingOrigin.toString(),
          },
        });

        this.$router.push({
          name: "my-estaid-lists-id",
          params: { id: item.id },
        });
      }, 0);
    },

    navigateToReferenceProperties() {
      this.$emit("navigate");

      setTimeout(() => {
        this.$amplitude.track({
          event_type: "Reference ejendomme",
          event_properties: {
            bfe: this.bfeNumber,
          },
        });

        this.$router.push({
          name: "explore-reference-properties-id",
          params: { id: this.bfeNumber },
        });
      }, 0);
    },

    controlsEndPopulated() {
      return !!this.$slots.controlsEnd;
    },
  },
};
