import "core-js/modules/es7.array.includes.js";
import { hasValue } from "~/helpers/common-helpers";
var units = ["m2", "ft2"];
export var useTranslateUnit = function useTranslateUnit() {
  var translate = useTranslator();
  var filter = useFilter();
  var isUnit = function isUnit(unit) {
    return hasValue(unit) && units.includes(unit);
  };
  return function (unit) {
    return isUnit(unit) ? translate("UNITS_".concat(filter.uppercase(unit))) : unit !== null && unit !== void 0 ? unit : "";
  };
};