import { useQuery } from "@vue/apollo-composable";
import { PropertyTenancyTypesDocument } from "~/graphql/generated/graphql";
export var usePropertyTenancyTypesQuery = function usePropertyTenancyTypesQuery(propertyId) {
  var tenancyTypesQuery = useQuery(PropertyTenancyTypesDocument, {
    id: propertyId
  });
  var result = computed(function () {
    var _tenancyTypesQuery$re, _tenancyTypesQuery$re2;
    return (_tenancyTypesQuery$re = tenancyTypesQuery.result.value) === null || _tenancyTypesQuery$re === void 0 ? void 0 : (_tenancyTypesQuery$re2 = _tenancyTypesQuery$re.assetManagementProperty) === null || _tenancyTypesQuery$re2 === void 0 ? void 0 : _tenancyTypesQuery$re2.tenancyTypes;
  });
  return {
    loading: tenancyTypesQuery.loading,
    result: result
  };
};