import _slicedToArray from "@babel/runtime/helpers/esm/slicedToArray";
import "core-js/modules/es6.regexp.split.js";
import "core-js/modules/es6.object.to-string.js";
import "core-js/modules/es6.date.to-string.js";
import "core-js/modules/es6.regexp.to-string.js";
import "core-js/modules/es6.array.map.js";
import "core-js/modules/es6.array.filter.js";
import "core-js/modules/es7.object.entries.js";
import "core-js/modules/es6.string.starts-with.js";
import { AssetManagementCustomFieldType } from "~/graphql/generated/graphql";
export var useCustomUrlParam = function useCustomUrlParam(field) {
  var urlKey = "c_".concat(field.id);
  return useUrlParam(urlKey, "", function (value) {
    try {
      switch (field.fieldType) {
        case AssetManagementCustomFieldType.Number:
          var _ref = value.split("..."),
            _ref2 = _slicedToArray(_ref, 2),
            from = _ref2[0],
            to = _ref2[1];
          return [parseFloat(from) || undefined, parseFloat(to) || undefined];
        case AssetManagementCustomFieldType.Text:
          return value;
        case AssetManagementCustomFieldType.Boolean:
          return value === "true" ? true : value === "false" ? false : undefined;
        case AssetManagementCustomFieldType.Option:
          return value.split(",");
      }
    } catch (_unused) {
      switch (field.fieldType) {
        case AssetManagementCustomFieldType.Number:
          return [,];
        case AssetManagementCustomFieldType.Text:
          return "";
        case AssetManagementCustomFieldType.Boolean:
          return false;
        case AssetManagementCustomFieldType.Option:
          return [];
      }
    }
  }, function (value) {
    var _value$toString, _join;
    try {
      switch (field.fieldType) {
        case AssetManagementCustomFieldType.Number:
          var range = value;
          return !!range[0] || !!range[1] ? range.join("...") : "";
        case AssetManagementCustomFieldType.Text:
        case AssetManagementCustomFieldType.Boolean:
          return (_value$toString = value === null || value === void 0 ? void 0 : value.toString()) !== null && _value$toString !== void 0 ? _value$toString : "";
        case AssetManagementCustomFieldType.Option:
          return (_join = value === null || value === void 0 ? void 0 : value.join(",")) !== null && _join !== void 0 ? _join : "";
      }
    } catch (_unused2) {
      return "";
    }
  });
};
export var useCustomUrlParams = function useCustomUrlParams() {
  var route = useRoute();

  /** Note Sven: We grab all "c_" url params here, so we don't have to wait for a server query to see if we have custom fields active */
  return computed(function () {
    return Object.entries(route.query).filter(function (_ref3) {
      var _ref4 = _slicedToArray(_ref3, 2),
        key = _ref4[0],
        value = _ref4[1];
      return key.startsWith("c_") && typeof value === "string";
    }).map(function (_ref5) {
      var _ref6 = _slicedToArray(_ref5, 2),
        key = _ref6[0],
        value = _ref6[1];
      return [key, useUrlParamString(key)];
    });
  });
};