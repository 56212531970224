const key = "snackbar";

export const state = () => ({
    snack: "",
    show: false,
    type: "",
    keepOpen: false,
    timeout: 5000,
    icon: null,
    dismissable: false,
});

export const getters = {
    snack(state) {
        return state.snack;
    },
    show(state) {
        return state.show;
    },
    type(state) {
        return state.type;
    },
    keepOpen(state) {
        return state.keepOpen;
    },
    timeout(state) {
        return state.timeout;
    },
    icon(state) {
        return state.icon;
    },
    dismissable(state) {
        return state.dismissable;
    },
};

export const actions = {
    setSnack({ commit }, snackData) {
        commit("setSnack", snackData);
    },
    closeSnack({ commit }) {
        commit("closeSnack");
    },
};

export const mutations = {
    setSnack(state, snackData) {
        state.type = snackData.type ? snackData.type : "";
        state.keepOpen = snackData.keepOpen ? snackData.keepOpen : false;
        state.icon = snackData.icon ? snackData.icon : null;
        state.timeout = snackData.timeout ? snackData.timeout : 5000;
        state.dismissable = snackData.dismissable ? snackData.dismissable : false;

        state.show = true;
        state.snack = snackData.text;
    },
    closeSnack(state) {
        state.show = false;
        state.snack = "";
        state.type = "";
        state.keepOpen = false;
        state.timeout = 5000;
        state.dismissable = false;
        state.icon = null;
    },
};

export default {
    namespaced: true,
    state,
    getters,
    actions,
    mutations,
};
