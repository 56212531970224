
import Portfolio from "~/graphql/Person/Portfolio.gql"
import SymbolLayer from "./basicLayers/SymbolLayer.vue"
import {
  PROPERTY_TYPE_MAIN_PROPERTY,
  PROPERTY_TYPE_CONDO,
  PROPERTY_TYPE_BUILDING_ON_FOREIGN_PLOT,
  getPropertyType
} from "~/helpers/property-helpers.js"
import {
  onClickPropertyPin,
  fitToGeoJsonBounds
} from "~/helpers/map-helpers.js"

import {
  formatPropertyMarkers,
  formatCondoMarkers,
  formatBOFPMarkers,
  person_pins
} from "../MapBoxConfig.js"

export default {
  components: {
    SymbolLayer
  },

  data() {
    return {
      person_pins
    }
  },

  apollo: {
    person: {
      query: Portfolio,

      result(result) {
        const propertiesFlat = result.data.person?.properties || []

        const properties =
          formatPropertyMarkers(
            propertiesFlat.filter(
              x => getPropertyType(x) === PROPERTY_TYPE_MAIN_PROPERTY
            )
          ) ?? []

        const condos =
          formatCondoMarkers(
            propertiesFlat.filter(
              x => getPropertyType(x) === PROPERTY_TYPE_CONDO
            )
          ) ?? []

        const bofp =
          formatBOFPMarkers(
            propertiesFlat.filter(
              x => getPropertyType(x) === PROPERTY_TYPE_BUILDING_ON_FOREIGN_PLOT
            )
          ) ?? []

        const data = {
          type: "FeatureCollection",
          features: [...properties, ...condos, ...bofp]
        }

        this.$map.then(map => {
          map?.getSource("person")?.setData(data)
          this.fitToGeoJsonBounds(data, map)
        })
        return
      },

      variables() {
        return {
          id: this.$route.params.id
        }
      }
    }
  },

  methods: {
    onClickPropertyPin,
    fitToGeoJsonBounds
  }
}
