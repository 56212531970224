
import municipalitieslist from "~/static/municipalities.json";
import SelectList from "../SelectList.vue";

export default {
  components: { SelectList },
  data() {
    return {
      key: 0,
      geographyFilterText: "",
      geographyAutoCompleteItem: null,
    };
  },
  computed: {
    geography() {
      return this.$store.getters["explore/getGeography"];
    },
    geographyFilter() {
      return municipalitieslist.filter((x) =>
        x.name
          .toLowerCase()
          .replace("å", "aa")
          .includes(this.geographyFilterText.toLowerCase().replace("å", "aa"))
      );
    },
    storeSelectedItems() {
      return [...this.$store.getters["explore/getGeography"]];
    },
  },
  watch: {
    geography(val) {
      if (val == 0) {
        this.key++;
      }
    },
  },
  methods: {
    setGeography(val) {
      this.$store.commit("explore/setGeography", val);
    },
    // geographyOnEnter() {
    //     this.$refs.geographyList.add(this.geographyFilter[0]);
    //     this.geographyFilterText = "";
    // },
  },
};
