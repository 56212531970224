
export default {
  methods: {
    startBottomPopulated() {
      return this.$slots.startBottom && this.$slots.startBottom.length > 0;
    },

    endBottomPopulated() {
      return this.$slots.endBottom && this.$slots.endBottom.length > 0;
    },

    bottomPopulated() {
      return this.$slots.bottom && this.$slots.bottom.length > 0;
    },
  },
};
