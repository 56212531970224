import VueI18n from "vue-i18n";
import { i18n } from "~/plugins/translator";
import { TranslationKey } from "~/helpers/common-helpers";

export const useTranslator = () => {
  return i18n.t.bind(i18n) as (key: TranslationKey, values?: VueI18n.Values) => string;
};

export const useCountTranslator = () => {
  return i18n.tc.bind(i18n) as (key: TranslationKey, count?: number, values?: VueI18n.Values) => string;
};
