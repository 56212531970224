import { getLatestSalesFinances, getDaysOnMarket, type SalesFinance } from "./portfolio-sales-helpers";
import { type Units, convertArea as turfConvertArea } from "@turf/helpers";
import { hasValue, prepareNumber, TranslationKey } from "../common-helpers";
import moment from "moment";
import type { ExternalEntity, FlagsEntity, Tenancy } from "./portfolio-tenancy-helpers";
import type { RentMetric } from "./portfolio-tenant-helpers";
import type { AssetManagementCategoryType, AssetManagementPropertyFlagValue, AssetManagementPropertySubType } from "~/graphql/generated/graphql";
import { Budget } from "./portfolio-category-helpers";
import { FinancialRecord } from "./portfolio-financial-records-helpers";
import { Currency } from "../apollo/types";

export type Account = CategoryChild & BudgetRecordsEntity & { accountId?: Nullable<string>; accountName?: Nullable<string>; accountNumber?: Nullable<string> };

export type PropertyFlag = { id: string; value: AssetManagementPropertyFlagValue };

export type Location = {
  name?: Nullable<string>;
  zipCode?: Nullable<string>;
  city?: Nullable<string>;
};

export type Portfolio = {
  id: string;
  name?: Nullable<string>;
  color?: Nullable<string>;
};

export type BusinessPlan = {
  id: string;
  propertyId?: Nullable<string>;
  updatedAt?: Nullable<string>;
  allocatedPurchasePrice?: Nullable<number>;
  allocatedPurchasePriceWithCost?: Nullable<number>;
  businessPlanPrice?: Nullable<number>;
};

export type BudgetRecord = BudgetChild &
  AccountChild & {
    id: string;
    currency?: Nullable<string>;
    entryDate: string;
    actualCostWithVat?: Nullable<number>;
  };

export type PropertiesEntity = {
  assetManagementProperties?: {
    items?: Nullable<Property[]>;
    metadata?: {
      totalCount?: Nullable<number>;
    } | null;
  } | null;
};

export type TenanciesEntity = {
  assetManagementTenancies?: {
    items?: Nullable<Tenancy[]>;
    metadata?: {
      totalCount?: Nullable<number>;
    } | null;
  } | null;
};

export type AccountsEntity = {
  assetManagementAccounts?: Nullable<{
    items?: Nullable<Account[]>;
  }>;
};

export type Category = AccountsEntity & {
  id: string;
  name?: Nullable<string>;
  parentCategoryId?: Nullable<string>;
  categoryType?: Nullable<AssetManagementCategoryType>;
};

export type CategoryChild = {
  categoryId?: Nullable<string>;
  assetManagementCategory?: Nullable<Category>;
};

export type BudgetChild = {
  budgetId?: Nullable<string>;
  assetManagementBudget?: Nullable<Budget>;
};

export type AccountChild = {
  accountId?: Nullable<string>;
  assetManagementAccount?: Nullable<Account>;
};

export type BudgetRecordsEntity = {
  id: string;
  validFrom?: any | null;
  validTo?: any | null;
  description?: string | null;
  propertyId?: string | null;

  assetManagementBudgetRecords?: Nullable<{
    items?: Nullable<BudgetRecord[]>;
  }>;
};

export type BudgetsEntity = {
  assetManagementBudgets?: Nullable<{
    items?: Nullable<BudgetRecordsEntity[]>;
  }>;
};

export type FinancialRecordsEntity = {
  assetManagementFinancialRecords?: Nullable<{
    items?: Nullable<FinancialRecord[]>;
  }>;
};

export type PlannedRentEntity = {
  latestPlannedRentMetric?: Nullable<RentMetric>;
};

export type BusinessPlansEntity = {
  assetManagementBusinessPlans?: Nullable<{
    items: BusinessPlan[];
  }>;
};

export type Property = Location &
  AccountsEntity &
  TenanciesEntity &
  ExternalEntity &
  BudgetsEntity &
  FinancialRecordsEntity &
  PlannedRentEntity &
  BusinessPlansEntity &
  FlagsEntity<PropertyFlag> & {
    id: string;
    administrationStartDate?: Nullable<string>;
    administrationEndDate?: Nullable<string>;
    usage?: Nullable<string>;
    subType?: Nullable<AssetManagementPropertySubType>;
    status?: string | null;
    assetManagementPortfolio?: Nullable<Portfolio>;
    ownershipStartDate?: string | null;
  };

export const getTenanciesForProperty = (property: Nullable<TenanciesEntity>) => {
  return property?.assetManagementTenancies?.items ?? [];
};

export const getNumberOfTenanciesForProperty = (property: Nullable<TenanciesEntity>) => {
  return property?.assetManagementTenancies?.metadata?.totalCount ?? 0;
};

export const getTenantsForProperty = (property: Property) => {
  const tenancies = getTenanciesForProperty(property);

  return tenancies.flatMap((tenancy) => tenancy.assetManagementLatestTenant).filter((tenant) => tenant != null);
};

export const getTotalRentForProperty = (property: Nullable<PlannedRentEntity>) => {
  return property?.latestPlannedRentMetric?.amount || 0;
};

export const getRentCurrencyForProperty = (property: Nullable<PlannedRentEntity>) =>
  (property?.latestPlannedRentMetric?.currency ?? undefined) as Currency | undefined;

export const getMonthlyRentForProperty = (property: Nullable<PlannedRentEntity>) => {
  return getTotalRentForProperty(property) / 12;
};

export const getPropertyAddress = (property: Property) => {
  return property.name || "";
};

export const getExternalIdForProperty = (property: ExternalEntity) => {
  const externalId = property.assetManagementExternalIDs?.items.find((item) => {
    return item.externalIDType == "id";
  });

  return externalId ? externalId.externalID : null;
};

const areaUnitsTurfMap: { [k: string]: Units } = {
  m2: "meters",
  ft2: "feet",
};

export const convertArea = (area: Nullable<number | string>, sourceUnit: Nullable<string>, targetUnit: Nullable<string>) => {
  if (!sourceUnit || !targetUnit) {
    if (area) throw `missing area units, sourceUnit: ${sourceUnit}, targetUnit: ${targetUnit}, area: ${area}`;
    else return 0;
  }

  const turfSourceUnit = areaUnitsTurfMap[sourceUnit];
  const turfTargetUnit = areaUnitsTurfMap[targetUnit];

  if (area && (!turfSourceUnit || !turfTargetUnit)) throw `unsupported area units sourceUnit: ${sourceUnit}, targetUnit: ${targetUnit}`;

  try {
    return turfConvertArea(prepareNumber(area), turfSourceUnit, turfTargetUnit);
  } catch {
    return 0;
  }
};

export const getPortfolioArea = (portfolio: PropertiesEntity) => {
  let areaUnit: Nullable<string> = undefined;

  const area =
    portfolio.assetManagementProperties?.items?.reduce((acc, property) => {
      const { area, areaUnit: unit } = getPropertyArea(property);

      if (!areaUnit) areaUnit = unit;

      return acc + area;
    }, 0) ?? 0;

  return { area, areaUnit };
};

export const getPropertyArea = (property: Nullable<TenanciesEntity>, targetUnit?: Nullable<string>) => {
  const area = getTenanciesForProperty(property).reduce((acc, tenancy) => {
    if (!targetUnit) targetUnit = tenancy.areaUnit;

    const area = tenancy.area ?? 0;
    const convertedArea = convertArea(area, tenancy.areaUnit, targetUnit);
    return acc + convertedArea;
  }, 0);

  return { area, areaUnit: targetUnit };
};

export const getSqmRentForProperty = (property: Nullable<PlannedRentEntity & TenanciesEntity>) => {
  const { area, areaUnit } = getPropertyArea(property);

  const rent = getTotalRentForProperty(property) / area;

  return { rent, areaUnit };
};

export const getTenancyTypeDistribution = (property: Nullable<TenanciesEntity>) => {
  const tenancies = getTenanciesForProperty(property);

  const tenancyTypeDistribution = tenancies.reduce((acc, tenancy) => {
    const tenancyType = tenancy.tenancyType;

    if (!tenancyType) return acc;

    if (!acc[tenancyType]) {
      acc[tenancyType] = 0;
    }

    acc[tenancyType] += 1;

    return acc;
  }, {} as Record<string, number>);

  return tenancyTypeDistribution;
};

export const getTenancyAreaDistribution = (property: Nullable<TenanciesEntity>, key: KeyOfType<Tenancy, Nullable<string>>) => {
  const tenancies = getTenanciesForProperty(property);

  let areaUnit: Nullable<string> = undefined;

  const distribution = tenancies.reduce((acc, tenancy) => {
    const value = tenancy[key] as string;

    areaUnit ??= tenancy.areaUnit;

    if (!value) return acc;

    if (!acc[value]) {
      acc[value] = 0;
    }

    acc[value] += convertArea(Math.round(tenancy.area ?? 0), tenancy.areaUnit, areaUnit);

    return acc;
  }, {} as Record<string, number>);

  return { distribution, areaUnit };
};

export const getRentRegulationDistributionPrSquareMeter = (property: Nullable<Property>) => getTenancyAreaDistribution(property, "rentRegulationPrinciple");

export const getTenancyTypeDistributionPrSquareMeter = (property: Nullable<TenanciesEntity>) => getTenancyAreaDistribution(property, "tenancyType");

export const getFinancialRecordsForProperty = (property: Nullable<TenanciesEntity & FinancialRecordsEntity>) => {
  if (!hasValue(property)) return [];

  const propertyOpexes = property.assetManagementFinancialRecords?.items || [];
  const tenancyOpexes = property.assetManagementTenancies?.items?.flatMap((tenancy) => tenancy.assetManagementFinancialRecords?.items ?? []) || [];

  return [...propertyOpexes, ...tenancyOpexes];
};

export const getPropertyDaysOnMarket = (tenancy: {
  assetManagementSales?: Nullable<{
    items: Nullable<SalesFinance[]>;
  }>;
}) => {
  const latestSales = getLatestSalesFinances(tenancy.assetManagementSales?.items);
  if (!latestSales) {
    return 0;
  }
  return getDaysOnMarket(latestSales);
};

export const getBusinessPlanForProperty = (property: Nullable<BusinessPlansEntity>) => {
  // Note (Morten): Pick the latest updated business plan
  if (!hasValue(property?.assetManagementBusinessPlans?.items) || property.assetManagementBusinessPlans?.items.length == 0) {
    return null;
  }

  return [...property.assetManagementBusinessPlans.items].sort((a, b) => {
    return a?.updatedAt && b?.updatedAt ? moment(b.updatedAt).diff(moment(a.updatedAt)) : 0;
  })[0];
};

export const TYPES = {
  Butik: "SHOP",
  Industri: "INDUSTRY",
  Kontor: "OFFICE",
  Andet: "OTHER",
  Bolig: "HOUSING",
} as const;

export const getTypesText = (usage: keyof typeof TYPES) => {
  switch (usage) {
    case "Butik":
      return "PORTFOLIO_TYPES_SHOP";
    case "Industri":
      return "PORTFOLIO_TYPES_INDUSTRY";
    case "Kontor":
      return "PORTFOLIO_TYPES_OFFICE";
    case "Andet":
      return "PORTFOLIO_TYPES_OTHER";
    case "Bolig":
      return "PORTFOLIO_TYPES_HOUSING";
    default:
      return "Unknown";
  }
};

export const getTranslationKeyForTypes = (usage: (typeof TYPES)[keyof typeof TYPES]): TranslationKey => {
  return `PORTFOLIO_TYPES_${usage}`;
};
