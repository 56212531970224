
import PersonLink from "./components/PersonLink.vue";
import CompanyLink from "./components/CompanyLink.vue";

export default {
  components: {
    PersonLink,
    CompanyLink,
  },
  props: {
    owner: {
      type: Object,
      default: null,
    },

    trackingOrigin: {
      type: String,
      default: null,
    },
  },
};
