import { useQuery } from "@vue/apollo-composable";
import { Operator } from "~/helpers/apollo/apollo-utility-helpers";
import { PropertyFinancialDocument } from "~/graphql/generated/graphql";
export var usePropertyFinancialQuery = function usePropertyFinancialQuery(propertyId, currentPage, pageSize, sortKey, sortOrder) {
  var _useQueryFilters = useQueryFilters({
      filters: [function () {
        return {
          matches: [{
            category_type: [Operator.In, ["capex", "CAPEX"]]
          }]
        };
      }]
    }),
    queryFilters = _useQueryFilters.value;
  var query = useQuery(PropertyFinancialDocument, function () {
    return {
      id: propertyId,
      filters: queryFilters.value.filters,
      sort: {
        key: sortKey,
        direction: sortOrder
      },
      pagination: {
        page: currentPage,
        pageSize: pageSize
      }
    };
  });
  var refetch = query.refetch;
  var loading = computed(function () {
    return query.loading.value;
  });
  var result = computed(function () {
    var _query$result$value$a, _query$result$value, _query$result$value$a2, _query$result$value$a3;
    return (_query$result$value$a = (_query$result$value = query.result.value) === null || _query$result$value === void 0 ? void 0 : (_query$result$value$a2 = _query$result$value.assetManagementProperty) === null || _query$result$value$a2 === void 0 ? void 0 : (_query$result$value$a3 = _query$result$value$a2.assetManagementFinancialRecords) === null || _query$result$value$a3 === void 0 ? void 0 : _query$result$value$a3.items) !== null && _query$result$value$a !== void 0 ? _query$result$value$a : [];
  });
  var metadata = computed(function () {
    var _query$result$value2, _query$result$value2$, _query$result$value2$2;
    return (_query$result$value2 = query.result.value) === null || _query$result$value2 === void 0 ? void 0 : (_query$result$value2$ = _query$result$value2.assetManagementProperty) === null || _query$result$value2$ === void 0 ? void 0 : (_query$result$value2$2 = _query$result$value2$.assetManagementFinancialRecords) === null || _query$result$value2$2 === void 0 ? void 0 : _query$result$value2$2.metadata;
  });
  return {
    loading: loading,
    result: result,
    metadata: metadata,
    refetch: refetch
  };
};