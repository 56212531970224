
import SymbolLayer from "./basicLayers/SymbolLayer.vue"
import { getPropertyGeometryQuery } from "~/helpers/apollo/apollo-property-query-helpers.js"
import {
  fitToGeoJsonBounds,
  onClickBuildingOrFacilityPin
} from "~/helpers/map-helpers.js"

import {
  formatBuildingMarker,
  formatCondoMarker,
  formatFacilityMarker,
  formatPropertyPolygon,
  formatBOFPBuildingMarkers,
  property_plot_polygon_fill,
  property_plot_polygon_border,
  property_pins,
  emptyGeoJsonSourceWithClusters
} from "../MapBoxConfig.js"

export default {
  apollo: {
    propertyByBFENumber: {
      ...getPropertyGeometryQuery(),
      result(result) {
        const data = result.data?.propertyByBFENumber
        const polygons = formatPropertyPolygon(data)
        const buildings = formatBuildingMarker(data) ?? []
        const facilities = formatFacilityMarker(data) ?? []
        const condo = formatCondoMarker(data) ?? {}
        const bofp = formatBOFPBuildingMarkers(data) ?? []
        const property_data = {
          type: "FeatureCollection",
          features: [...buildings, ...facilities, ...bofp, condo].filter(
            x => !!x?.geometry
          )
        }

        this.property_data = { ...property_data }
        this.$map.then(map => {
          map?.getSource("property-plot")?.setData(polygons)
          map?.getSource("property")?.setData(property_data)
        })

        // We need to switch to mapbox to ensure that fitting works
        this.$store.commit("mapstate/maptype", "default")

        // Then we need to ensure the map has switched back
        setTimeout(() => {
          this.fitToGeoJsonBounds(
            {
              type: "FeatureCollection",
              features: [...polygons.features, ...property_data.features]
            },
            200
          )
        })
      }
    }
  },

  components: {
    SymbolLayer
  },

  data() {
    return {
      property_plot_polygon_fill,
      property_plot_polygon_border,
      property_pins,
      infoWindowOpen: false,
      infoWindowId: null,
      infoWindowData: null,
      infoWindowPosition: null,
      infoWindowType: null,
      currentHoverId: null,
      isPropertyPinsVisible: true,
      property_data: null
    }
  },

  computed: {
    filters() {
      return this.$store.getters["mapstate/getPropertyPinsFilters"]
    }
  },

  watch: {
    filters: {
      immediate: true,
      handler(val) {
        // Cursed. Ask Christoffer
        // https://github.com/mapbox/mapbox-gl-js/issues/2613#issuecomment-841144839
        this.isPropertyPinsVisible = false
        this.$map.then(map => {
          map?.removeLayer("property_pins")
          map?.removeSource("property")
          this.$nextTick(() => {
            map?.addSource("property", {
              ...emptyGeoJsonSourceWithClusters,
              filter: val
            })
            map?.getSource("property").setData(this.property_data)
            map?.addLayer(property_pins)
            this.isPropertyPinsVisible = true
          })
        })
      }
    }
  },

  methods: {
    fitToGeoJsonBounds,
    onClickBuildingOrFacilityPin
  }
}
