export const state = () => ({
    isThirdPartyError: false,
    thirdPartyErrorTime: {},
});
export const actions = {
    setThirdPartyError({ commit }, change) {
        commit("setThirdPartyError", change);
    },
};
export const mutations = {
    setThirdPartyError(state, change) {
        state.isThirdPartyError = change;
        state.thirdPartyErrorTime = Date.now();
    },
};
export const getters = {
    isThirdPartyError(state) {
        return state.isThirdPartyError;
    },
    lastThirdPartyErrorAt(state) {
        return state.thirdPartyErrorTime;
    },
};

export default {
    namespaced: true,
    state,
    getters,
    actions,
    mutations,
};
