// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(function(i){return i[1]});
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".searchResult_ZSbzK{display:flex;flex-direction:column;gap:.25rem;padding:.75rem 1rem}.searchResult_ZSbzK.searchResultSelected_tWfiG,.searchResult_ZSbzK:hover{background-color:var(--color-green-100);cursor:pointer}.company-list_Cei70{display:flex;flex-direction:column;gap:4px;margin-top:48px}.company-list-item_VbRKh{display:flex;height:40px}.company-list-item-text_3G1bv{align-items:center;background:var(--color-black-high-emphasis);border:1px solid var(--color-black-high-emphasis);border-radius:4px;box-sizing:border-box;color:#fff;display:flex;gap:18px;padding-left:24px;width:100%}.company-list-item-text_3G1bv h3{color:#fff}.company-list-item-text_3G1bv span{font-size:12px}.company-list-close_igviC{align-items:center;cursor:pointer;display:flex;font-size:24px;justify-content:center;width:40px}", ""]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"searchResult": "searchResult_ZSbzK",
	"searchResultSelected": "searchResultSelected_tWfiG",
	"company-list": "company-list_Cei70",
	"company-list-item": "company-list-item_VbRKh",
	"company-list-item-text": "company-list-item-text_3G1bv",
	"company-list-close": "company-list-close_igviC"
};
module.exports = ___CSS_LOADER_EXPORT___;
