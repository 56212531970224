// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(function(i){return i[1]});
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".container_cDm7u{align-items:center;display:inline-flex;position:relative}.explodeWidth_iOica{width:100%}.abbr_5AWxE{border-bottom:1px!important;border-bottom-color:inherit!important;cursor:inherit!important;display:inline-flex;position:relative;-webkit-text-decoration:none!important;text-decoration:none!important;white-space:inherit;width:100%}.abbr_5AWxE.cursorHelp_bSSd3{cursor:help!important}.abbr_5AWxE.underline_lQa8N,.abbr_5AWxE:hover{border-bottom-style:dashed!important}.abbr_5AWxE.hoverEffectsDisable_QzYnw:hover{border-bottom:none!important}.span_jXUpH{display:inline;white-space:inherit}", ""]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"container": "container_cDm7u",
	"explodeWidth": "explodeWidth_iOica",
	"abbr": "abbr_5AWxE",
	"cursorHelp": "cursorHelp_bSSd3",
	"underline": "underline_lQa8N",
	"hoverEffectsDisable": "hoverEffectsDisable_QzYnw",
	"span": "span_jXUpH"
};
module.exports = ___CSS_LOADER_EXPORT___;
