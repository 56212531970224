
import SymbolLayer from "./basicLayers/SymbolLayer.vue";
import { getCompanyPropertiesQuery } from "~/helpers/apollo/apollo-company-query-helpers.js";
import { PROPERTY_TYPE_MAIN_PROPERTY, PROPERTY_TYPE_CONDO, PROPERTY_TYPE_BUILDING_ON_FOREIGN_PLOT, getPropertyType } from "~/helpers/property-helpers.js";

import { onClickPropertyPin, fitToGeoJsonBounds } from "~/helpers/map-helpers.js";

import { formatPropertyMarkers, formatCondoMarkers, formatBOFPMarkers, company_pins } from "../MapBoxConfig.js";

export default {
  components: {
    SymbolLayer,
  },

  data() {
    return {
      company_pins,
    };
  },

  apollo: {
    companyProperties: {
      ...getCompanyPropertiesQuery(),
      result(result) {
        const propertiesFlat = result?.data?.subCompaniesFlat.flatMap((x) => x.properties);

        const properties = formatPropertyMarkers(propertiesFlat?.filter((x) => getPropertyType(x) === PROPERTY_TYPE_MAIN_PROPERTY)) ?? [];

        const condos = formatCondoMarkers(propertiesFlat?.filter((x) => getPropertyType(x) === PROPERTY_TYPE_CONDO)) ?? [];

        const bofp = formatBOFPMarkers(propertiesFlat?.filter((x) => getPropertyType(x) === PROPERTY_TYPE_BUILDING_ON_FOREIGN_PLOT)) ?? [];

        const data = {
          type: "FeatureCollection",
          features: [...properties, ...condos, ...bofp],
        };
        this.$map.then((map) => {
          map?.getSource("company")?.setData(data);
          this.fitToGeoJsonBounds(data, map);
        });
      },
    },
  },

  methods: {
    onClickPropertyPin,
    fitToGeoJsonBounds,
  },
};
