// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(function(i){return i[1]});
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".content_da1ku{align-items:center;display:inline-flex;justify-content:space-between;width:100%}.simpleTableRow_nwGyu:hover{cursor:pointer}.delete_KrOY2{display:inline-flex;transition-duration:var(--transition-duration-shortest);transition-property:transform;transition-timing-function:ease-in-out}.delete_KrOY2:hover{transform:scale(1.2)}", ""]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"content": "content_da1ku",
	"simpleTableRow": "simpleTableRow_nwGyu",
	"delete": "delete_KrOY2"
};
module.exports = ___CSS_LOADER_EXPORT___;
