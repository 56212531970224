
const TRACK_PERSON_LOOKUP_EVENT_NAME = "Person lookup";

export const trackPersonLookup = (personId, personName, route) => {
        $nuxt.$amplitude.track({
                event_type: TRACK_PERSON_LOOKUP_EVENT_NAME, event_properties: {
                        route: route,
                        personId: personId,
                        personName: personName,
                }
        });
}
