import { useQuery } from "@vue/apollo-composable";
import { TenancySalesDocument } from "~/graphql/generated/graphql";
export var useTenancySalesQuery = function useTenancySalesQuery(tenancyId) {
  var query = useQuery(TenancySalesDocument, {
    id: tenancyId
  });
  var refetch = query.refetch;
  var loading = computed(function () {
    return query.loading.value;
  });
  var result = computed(function () {
    var _query$result$value, _query$result$value$a, _query$result$value$a2;
    return (_query$result$value = query.result.value) === null || _query$result$value === void 0 ? void 0 : (_query$result$value$a = _query$result$value.assetManagementTenancy) === null || _query$result$value$a === void 0 ? void 0 : (_query$result$value$a2 = _query$result$value$a.assetManagementSales) === null || _query$result$value$a2 === void 0 ? void 0 : _query$result$value$a2.items;
  });
  return {
    loading: loading,
    result: result,
    refetch: refetch
  };
};