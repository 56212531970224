
export default {
  props: {
    items: {
      type: Array,
      required: true,
    },
    selectedItems: {
      type: Array,
      required: true,
    },
    multicoloumn: {
      type: Boolean,
      default: false,
    },
    color: {
      type: String,
      required: true,
    },
  },

  computed: {
    calculateColumnStyles() {
      let cols = (this.items.length - (this.items.length % 3)) / 3;
      cols = cols == 0 ? 1 : cols;
      return `-webkit-columns: ${cols} 300px; moz-columns: ${cols} 300px; columns: ${cols} 300px;`;
    },
    
    _selectedItems: {
      get() {
        return this.selectedItems;
      },
      set(value) {
        this.$emit("input", value);
      }
    },
  },
};
