import { useLazyQuery } from "@vue/apollo-composable";
import { DataWarehouseCategoriesDocument } from "~/graphql/generated/graphql";
export var useLazyCategoryChildrenQuery = function useLazyCategoryChildrenQuery(categoryId) {
  var query = useLazyQuery(DataWarehouseCategoriesDocument, function () {
    var _categoryId$value;
    return {
      filters: {
        groups: [{
          matches: [{
            parent_category_id: (_categoryId$value = categoryId.value) !== null && _categoryId$value !== void 0 ? _categoryId$value : null
          }]
        }]
      }
    };
  });
  var result = computed(function () {
    var _query$result$value$a, _query$result$value;
    return (_query$result$value$a = (_query$result$value = query.result.value) === null || _query$result$value === void 0 ? void 0 : _query$result$value.assetManagementCategories.items) !== null && _query$result$value$a !== void 0 ? _query$result$value$a : [];
  });
  var loading = computed(function () {
    return query.loading.value;
  });
  return {
    load: query.load,
    refetch: query.refetch,
    result: result,
    loading: loading
  };
};