// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(function(i){return i[1]});
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".container_9gGgK{align-self:center;border-radius:var(--border-radius-large);box-shadow:var(--box-shadow-large);cursor:default;display:flex;flex-direction:column;height:0;justify-self:flex-start;margin-left:1rem;margin-right:1rem;opacity:0;overflow-y:hidden;transform:translateY(-1rem);transition-delay:var(--transition-duration-short);transition-duration:var(--transition-duration-shortest);transition-property:opacity,transform,overflow;transition-timing-function:ease-in-out}.visible_oFAvl{background-color:cyan;height:100%;margin-bottom:1.25rem;opacity:1;transform:translateY(0)}.layout_87rC8{display:flex;flex-direction:column;height:100%;width:100%}", ""]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"container": "container_9gGgK",
	"visible": "visible_oFAvl",
	"layout": "layout_87rC8"
};
module.exports = ___CSS_LOADER_EXPORT___;
