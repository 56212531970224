
import PropertyCard from "~/components/EntityCard/PropertyCard.vue";
import AddToListDialog from "~/components/list/AddToListDialog.vue";
import { mapPropertyRemarks } from "~/helpers/property-helpers.js";

export default {
  components: { PropertyCard, AddToListDialog },

  props: {
    data: {
      type: Object,
      default: null,
      required: false,
    },

    loading: {
      type: Boolean,
      required: true,
    },
  },

  data() {
    return {
      showAddToList: false,
    };
  },

  computed: {
    propertyToSave: function () {
      if (this.data && this.data.bfeNumber) {
        return [this.data.bfeNumber];
      }

      return [];
    },

    address() {
      if (this.data.plots?.some((p) => p.address)) {
        return this.data.plots?.find((plot) => plot != null && plot.address != null)?.address;
      } else if (this.data.plots?.some((p) => p?.addresses?.length > 0)) {
        return this.data.plots.find((p) => p?.addresses.length > 0).addresses[0];
      } else if (this.data.condo?.unit?.address) {
        return this.data.condo.unit.address;
      } else if (this.data.buildingOnForeignPlot) {
        return this.data.buildingOnForeignPlot.buildings?.find((b) => b?.address)?.address;
      } else {
        return undefined;
      }
    },

    bfeNumber() {
      return this.data.bfeNumber;
    },

    lists() {
      return this.data.listProperties?.map((list) => list.list) ?? [];
    },

    propertyRemarks() {
      return mapPropertyRemarks(this.data);
    },

    isDividedInCondos() {
      return this.data.isDividedInCondos;
    },

    matrikelNumber() {
      if (this.data && this.data.plots) {
        return this.data.plots.find((plot) => plot != null).matrikelNumber;
      } else {
        return this.data.matrikelNumber;
      }
    },

    ownersGuildCode() {
      if (this.data && this.data.plots) {
        return this.data.plots.find((plot) => plot != null).ownersGuildCode;
      } else {
        return this.data.ownerId;
      }
    },

    cadastrals() {
      if (this.data && this.data.plots) {
        return this.data.plots.map((plot) => {
          return {
            matrikelNumber: plot.matrikelNumber,
            ownersGuildName: plot.ownersGuildName,
          };
        });
      }
      return [];
    },

    owners() {
      return this.data?.owners?.filter((owner) => owner);
    },

    administrators() {
      return this.data?.administrators
        ?.filter((administrator) => administrator)
        .map((x) => {
          return { ...x, type: "ADMINISTRATOR" };
        });
    },
  },

  methods: {
    search(e) {
      if (e.ctrlKey) {
        window.open(`${window.location.origin}/${this.bfeNumber}`);
      } else {
        this.$emit("close");
        let address = null;
        const a = this.data.plots ? this.data.plots[0].address : null;
        if (a) {
          address = `${a.streetName} ${a.streetNumber}, ${a.postalCode} ${a.city}`;
        } else if (this.address) {
          address = this.address;
        }
        const search = {
          id: this.bfeNumber,
          searchTerm: address,
        };
        this.$store.dispatch("search/initiateAddressSearch", {
          search,
          track: "clicksearch",
        });
      }
    },
  },
};
