
import { group_digits_in_number } from "~/plugins/common-fns.js";

export default {
  props: {
    data: {
      type: Object,
      required: true,
    },
  },

  methods: {
    group_digits_in_number: group_digits_in_number,

    goToAreas(event) {
      this.$router.push({
        name: "explore-property-id-buildings",
        params: this.$route.params,
        query: {
          activeTab: 3
        },
      });

      this.$emit("close", event);
    },
  },
};
