import { BalancePreferencesInjectionKey } from "~/helpers/portfolio/portfolio-balance-helpers";
export var useBalanceCellCopyColor = function useBalanceCellCopyColor(rowValue) {
  var preferences = useInject(BalancePreferencesInjectionKey, true);
  return computed(function () {
    var isChangePercentage = rowValue.type === "changePercentage";
    var value = rowValue.value;
    var base = preferences.value.useChangeColors && isChangePercentage && Math.round(value) !== 0 && value !== -0 ? value > 0 ? "green" : "pink" : "black";
    var saturation = isChangePercentage ? base === "black" ? 300 : 900 : 900;
    return "--color-".concat(base, "-").concat(saturation);
  });
};