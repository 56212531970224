
import ExploreSlider from "~/components/explore/filters/ExploreSlider.vue";
export default {
  components: {
    ExploreSlider,
  },
  computed: {
    siteArea: {
      get: function() {
        return this.$store.getters["explore/getSizeSiteArea"];
      },
      set: function(val) {
        this.$store.commit("explore/setSizeSizeSiteArea", val);
      },
    },
    buildingArea: {
      get: function() {
        return this.$store.getters["explore/getSizeBuildingArea"];
      },
      set: function(val) {
        this.$store.commit("explore/setSizeBuildingArea", val);
      },
    },
    noOfFloors: {
      get: function() {
        return this.$store.getters["explore/getSizeNoOfFloors"];
      },
      set: function(val) {
        this.$store.commit("explore/setSizeNoOfFloors", val);
      },
    },
    noOfUnits: {
      get: function() {
        return this.$store.getters["explore/getSizeNoOfUnits"];
      },
      set: function(val) {
        this.$store.commit("explore/setSizeNoOfUnits", val);
      },
    },
  },
};
