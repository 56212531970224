import { useMutation } from "@vue/apollo-composable";
import { DeleteAssetManagementValuationDocument, type DeleteAssetManagementValuationMutationVariables } from "~/graphql/generated/graphql";

export const useDeleteValuationMutation = (id: string) => {
  const variables: DeleteAssetManagementValuationMutationVariables = {
    id: id,
  };

  return useMutation(DeleteAssetManagementValuationDocument, { variables });
};
