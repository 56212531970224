import "core-js/modules/es6.object.to-string.js";
export default (function (context, inject) {
  var setter;
  var mapPromise = new Promise(function (resolve, reject) {
    setter = function setter(value) {
      if (value) {
        resolve(value);
      }
    };
  });
  Object.defineProperty(mapPromise, "map", {
    set: setter,
    get: function get() {
      return mapPromise;
    }
  });
  inject("map", mapPromise);
});