
export default {
  inheritAttrs: false,

  props: {
    enabled: {
      type: Boolean,
      default: false,
    },

    disabled: {
      type: Boolean,
      default: false,
    },
  },
};
