
export default {
  props: {
    visible: {
      type: Boolean,
      default: false,
    },

    layerId: {
      type: String,
      required: true,
    },
  },

  watch: {
    visible(val) {
      this.setVisible(val);
    },
  },

  mounted() {
    this.setVisible(this.visible);
    this.$map.then((map) => {
      const events = Object.keys(this.$listeners);
      for (let i = 0; i < events.length; i++) {
        map?.on(
          events[i],
          this.layerId,
          function (e) {
            this.$emit(events[i], e, this.layerId);
          }.bind(this)
        );
      }
    });
  },

  destroyed() {
    this.setVisible(false);
    const events = Object.keys(this.$listeners);
    this.$map.then((map) => {
      for (let i = 0; i < events.length; i++) {
        map?.off(
          events[i],
          this.layerId,
          function (e) {
            this.$emit(events[i], e, this.layerId);
          }.bind(this)
        );
      }
    });
  },

  methods: {
    setVisible(val) {
      this.$map.then((map) => {
        map?.setLayoutProperty(this.layerId, "visibility", val ? "visible" : "none");
      });
    },
  },
};
