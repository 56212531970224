import { getDateRange } from "~/helpers/portfolio/portfolio-balance-helpers";
export var useBalanceSelectedDateRange = function useBalanceSelectedDateRange() {
  var preferencesQuery = usePreferencesNamespace("preferencesNamespacePortfolioPropertyBalance");
  return {
    loading: preferencesQuery.loading,
    result: computed(function () {
      var preferences = preferencesQuery.result.value;
      if (!preferences) return;
      return getDateRange(preferences.dateRange, preferences.periodType, preferences.customDateStart, preferences.customDateEnd);
    })
  };
};