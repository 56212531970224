import { useQuery } from "@vue/apollo-composable";
import { PortfolioTenancyTypesDocument } from "~/graphql/generated/graphql";
export var usePortfolioTenancyTypesQuery = function usePortfolioTenancyTypesQuery(portfolioId) {
  var query = useQuery(PortfolioTenancyTypesDocument, {
    id: portfolioId
  });
  var result = computed(function () {
    var _query$result$value;
    return (_query$result$value = query.result.value) === null || _query$result$value === void 0 ? void 0 : _query$result$value.assetManagementPortfolio.tenancyTypes;
  });
  return {
    loading: query.loading,
    result: result
  };
};