const middleware = {}

middleware['auth0'] = require('../middleware/auth0.ts')
middleware['auth0'] = middleware['auth0'].default || middleware['auth0']

middleware['drawing-hooks'] = require('../middleware/drawing-hooks.js')
middleware['drawing-hooks'] = middleware['drawing-hooks'].default || middleware['drawing-hooks']

middleware['feature-flags-hooks'] = require('../middleware/feature-flags-hooks.js')
middleware['feature-flags-hooks'] = middleware['feature-flags-hooks'].default || middleware['feature-flags-hooks']

middleware['page-visit'] = require('../middleware/page-visit.js')
middleware['page-visit'] = middleware['page-visit'].default || middleware['page-visit']

middleware['url-address-converter'] = require('../middleware/url-address-converter.js')
middleware['url-address-converter'] = middleware['url-address-converter'].default || middleware['url-address-converter']

export default middleware
