/**
 * Returns the address as a string with streetname, streetnumber, floornumber, doornumber, postal code and city
 */
export function getAddressAsLongString(address) {
  if (address == null) return null;

  let addressString = `${address.streetName} ${address.streetNumber}`;

  if (address.floorNumber || address.doorNumber) {
    addressString = addressString + `, ${address.floorNumber ? address.floorNumber : ""} ${address.doorNumber ? address.doorNumber : ""}`;
  }

  if (address.postalCode || address.city) {
    addressString += `, ${address.postalCode} ${address.city}`;
  }

  return addressString;
}

/**
 * Returns the address as a string with streetname, streetnumber, floornumber and doornumber
 */
export function getAddressAsShortString(address) {
  if (address == null) return null;

  let addressString = `${address.streetName} ${address.streetNumber}`;

  if (address.floorNumber || address.doorNumber) {
    addressString = addressString + `, ${address.floorNumber ? address.floorNumber : ""} ${address.doorNumber ? address.doorNumber : ""}`;
  }

  return addressString;
}
