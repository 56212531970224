
export default {

  props: {
    component: {
      type: Object,
      required: true
    }
  },
};
