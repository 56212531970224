import { type CellObject, type NumberFormat, utils, writeFile, type WorkSheet, type Range } from "xlsx-js-style";
import { hasValue, prepareNumber } from "./common-helpers";

export type XLSXCellType = CellObject["t"];
export type XLSXCellValue = CellObject["v"] | null;
export type ExcelValueObject = {
  __type: "ExcelValueObject";
  value: XLSXCellValue;
  bold?: boolean;
  underline?: boolean;
  fill?: string;
};

export const excelCellTypeMap = {
  boolean: "b",
  date: "d",
  error: "e",
  number: "n",
  percentage: "n",
  string: "s",
  blank: "z",
} as const satisfies { [k in string]: XLSXCellType };

export type ExcelCellType = keyof typeof excelCellTypeMap;

export type ExcelHeader = { label: string; type: ExcelCellType; format?: NumberFormat; colSpan?: number; rowSpan?: number };

export const exportGenerateSheet = (rows: (ExcelValueObject | XLSXCellValue)[][], headers: ExcelHeader[]) => {
  const sanitize = (v: XLSXCellValue, cellType: ExcelCellType) => {
    const type = excelCellTypeMap[cellType];

    if (type === "d") {
      return hasValue(v) ? new Date(v as number | string) : undefined;
    } else if (type === "n") {
      try {
        let number = prepareNumber(v as string | number);

        if (cellType === "percentage") number /= 100;

        return number;
      } catch {
        return undefined;
      }
    } else if (v instanceof Date || typeof v === "boolean" || typeof v === "string") {
      return v;
    } else {
      return "";
    }
  };

  const isValueObject = (value: ExcelValueObject | XLSXCellValue): value is ExcelValueObject =>
    hasValue(value) && typeof value === "object" && "__type" in value && value.__type === "ExcelValueObject";

  const mappedRows = rows.map((row) =>
    row.map((value, index): CellObject => {
      const header = headers[index];
      if (isValueObject(value)) {
        const borderColor = "D4D4D4";
        const borderObject = { style: "thin", color: { rgb: borderColor } };
        return {
          t: excelCellTypeMap[header.type],
          z: header.format,
          v: sanitize(value.value, header.type),
          s: {
            font: { bold: value.bold, underline: value.underline },
            fill: value.fill ? { fgColor: { rgb: value.fill } } : undefined,
            border: {
              top: borderObject,
              bottom: borderObject,
              left: borderObject,
              right: borderObject,
            },
          },
        };
      } else {
        return { t: excelCellTypeMap[header.type], z: header.format, v: sanitize(value, header.type) };
      }
    })
  );

  const sheet = utils.aoa_to_sheet(mappedRows);

  return sheet;
};

/**
 *
 * @param character character in a-z
 * @returns numerical position of {@link character} in a-z
 */
const toAlphabetPosition = (character: string) => parseInt(character, 36) - 10;

/**
 *
 * @param position numerical position in A-Z
 * @returns character at {@link position} in A-Z (uppercase). If {@link position} is greater than alphabet, it will insert A, B, C... similar to excel columns
 */
const fromAlphabetPosition = (position: number) => {
  let character = "";

  while (position > 25) {
    character += "A";
    position -= 26;
  }

  character += (position + 10).toString(36).toUpperCase();

  return character;
};

export const exportAddSheetHeaderRow = (sheet: WorkSheet, headers: ExcelHeader[], origin: string) => {
  utils.sheet_add_aoa(sheet, [headers.map((header) => ({ v: header.label, s: { alignment: { horizontal: "center" }, font: { bold: true } } }))], { origin });

  let originColumn = 0;
  let originRow = 1;

  for (let i = 0; i < origin.length; i++) {
    const char = origin[i];
    if (isNaN(parseInt(char))) {
      originColumn += toAlphabetPosition(char);
    } else {
      originRow = parseInt(origin.slice(i));
    }
  }

  const merges: Range[] = (sheet["!merges"] ??= []);

  for (let i = 0; i < headers.length; i++) {
    const header = headers[i];

    let { colSpan, rowSpan } = header;

    if ((hasValue(colSpan) && colSpan > 1) || (hasValue(rowSpan) && rowSpan > 1)) {
      const colStart = originColumn + i;
      const colEnd = colStart + (header.colSpan ?? 1) - 1;

      const rowStart = originRow;
      const rowEnd = originRow + (header.rowSpan ?? 1) - 1;

      const range = `${fromAlphabetPosition(colStart)}${rowStart}:${fromAlphabetPosition(colEnd)}${rowEnd}`;

      merges.push(utils.decode_range(range));
    }
  }

  sheet["!merges"] = merges;
};

export const exportDownloadSheet = (sheet: WorkSheet, filename: string) => {
  const book = utils.book_new();

  utils.book_append_sheet(book, sheet);

  writeFile(book, `${filename}.xlsx`);
};

export const exportDownload = (headers: ExcelHeader[], rows: (XLSXCellValue | ExcelValueObject)[][], filename: string) => {
  const sheet = exportGenerateSheet([[], ...rows], headers);

  exportAddSheetHeaderRow(sheet, headers, "A1");

  exportDownloadSheet(sheet, filename);
};
