import moment from "moment";
import { useQuery } from "@vue/apollo-composable";
import { MetricPeriodType, PlannedRentsMetricsCategory, PortfolioPlannedRentDocument } from "~/graphql/generated/graphql";
export var usePortfolioPlannedRentQuery = function usePortfolioPlannedRentQuery(portfolioId) {
  var input = function input() {
    return {
      input: {
        portfolioId: portfolioId,
        periodType: MetricPeriodType.Monthly,
        rentCategory: PlannedRentsMetricsCategory.Rent,
        to: moment().utc(),
        from: moment().subtract(1, "year").utc()
      }
    };
  };
  var query = useQuery(PortfolioPlannedRentDocument, input);
  var loading = computed(function () {
    return query.loading.value;
  });
  var result = computed(function () {
    var _query$result$value;
    return (_query$result$value = query.result.value) === null || _query$result$value === void 0 ? void 0 : _query$result$value.plannedRentsMetricsForPortfolio;
  });
  var metadata = computed(function () {
    var _query$result$value2;
    return (_query$result$value2 = query.result.value) === null || _query$result$value2 === void 0 ? void 0 : _query$result$value2.plannedRentsMetricsForPortfolio;
  });
  return {
    loading: loading,
    result: result,
    metadata: metadata
  };
};