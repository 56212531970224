
import usagetypeslist from "~/static/usagetypeslist.json";

export default {
  data() {
    return {
      treeviewSearch: "",
    };
  },
  computed: {
    usageEnabled() {
      return this.$store.getters["explore/getUsageEnabled"];
    },

    usage: {
      get: function() {
        return this.$store.getters["explore/getUsage"];
      },
      set: function(value) {
        this.$store.commit("explore/setUsage", value);
      },
    },
    treeviewItems() {
      return usagetypeslist;
    },
  },
};
