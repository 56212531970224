
import MapLegendWMSItem from "./MapLegendWMSItem.vue";

export default {
  name: "MapLegends",

  components: { MapLegendWMSItem },

  inheritAttrs: false,

  props: {
    showOrtoForaar: {
      type: Boolean,
      default: false,
    },

    showSoilContaminations: {
      type: Boolean,
      default: false,
    },

    legends: {
      type: Array,
      default: () => [],
    },
  },
};
