// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(function(i){return i[1]});
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".containerInput_Arbjr{display:grid;grid-template-columns:1fr 1fr 1fr;margin-bottom:3rem}", ""]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"containerInput": "containerInput_Arbjr"
};
module.exports = ___CSS_LOADER_EXPORT___;
