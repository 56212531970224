import Vue from "vue";
Vue.mixin({
    created() {
        const title = this.getTitle(this);
        if (title) {
            document.title = "Estaid - " + title;
        }
    },
    methods: {
        getTitle(vm) {
            const { title } = vm.$options;
            if (title) {
                return typeof title === "function" ? title.call(vm) : title;
            }
        },
    },
});

export const sortable = function (defaultSort = "", defaultSortProp = "") {
    // PREREQUISITES
    //
    // mixins: [sortable(DEFAULT_SORTING_FNC, DEFAULT_SORTING_PROP)],
    //
    // Computed array for sorting called "dataForSorting"
    //
    // On simple table cell:
    // sorting-enable
    // :sorting-active="item.active"
    // :sorting-order="isDesc ? 'desc' : 'asc'"
    //  @click="selectSorting(item, headers)"
    //
    //  where item is a header item, w. "name", "active", and optionally "sortFnc"

    return {
        data() {
            return {
                sortFnc: defaultSort,
                sortProp: defaultSortProp,
                isDesc: false,
                daSearch: new Intl.Collator("da", {
                    numeric: true,
                    sensitivity: "base",
                }),
            };
        },
        methods: {
            selectSorting(item, headers) {
                if (item.active) {
                    this.isDesc = !this.isDesc;
                    return;
                }
                if (item.sortProp) {
                    this.sortProp = item.sortProp;
                }
                headers.forEach((itm) => (itm.active = false));
                item.active = true;
                this.isDesc = false;
                this.sortFnc = item.sortFnc || null;
            },

            by_string(a, b) {
                return this.daSearch.compare(a[this.sortProp] ?? "", b[this.sortProp] ?? "");
            },

            by_number(a, b) {
                return (a[this.sortProp] ?? Number.MIN_SAFE_INTEGER) - (b[this.sortProp] ?? Number.MIN_SAFE_INTEGER);
            },
        },

        computed: {
            sortedData() {
                if (!this.dataForSorting?.length) {
                    return [];
                }

                let result = [...this.dataForSorting];

                const values = result.filter((x) => !!x[this.sortProp]);
                const typeVal = (values && values[0]) ?? {};

                if (this.sortFnc) {
                    result.sort(this[this.sortFnc]);
                } else if (typeof typeVal[this.sortProp] == "string") {
                    result.sort(this.by_string);
                } else if (typeof typeVal[this.sortProp] == "number") {
                    result.sort(this.by_number);
                }

                if (this.isDesc) {
                    return result.reverse();
                }

                return result;
            },
        },
    };
};
