import CompaniesOverview from "~/graphql/Company/CompanyOverview.gql";
import CompanyOwners from "~/graphql/Company/CompanyOwners.gql";
import CompanySubCompanies from "~/graphql/Company/CompanySubCompanies.gql";
import CompanyProperties from "~/graphql/Company/CompanyProperties.gql";
import CompanyPropertiesCount from "~/graphql/Company/CompanyPropertiesCount.gql";
import CompanyStickyFigures from "~/graphql/Company/CompanyStickyFigures.gql";
import CompanyPortfolio from "~/graphql/Company/CompanyPortfolio.gql";
import CompanyMembersByCvr from "~/graphql/Company/CompanyMembersByCvr.gql";

export const getOverviewQuery = function overviewQuery() {
    return {
        query: CompaniesOverview,
        variables: getCvrNumber,
        update(data) {
            return data.companyByCvr;
        },
        error(error) {
            console.error("Company overview failed:", error);
        },
    };
};

export const getCompanyOwnersQuery = function ownersQuery() {
    return {
        query: CompanyOwners,
        variables: getCvrNumberAndDepth,
        update(data) {
            return data.companyStructure;
        },
        error(error) {
            console.error("Company owners failed:", error);
        },
    };
};
export const getCompanyMembersQuery = function members() {
    return {
        query: CompanyMembersByCvr,
        variables: getCvrNumber,
        update(data) {
            return data.companyByCvr.members;
        },
        error(error) {
            console.error("Company owners failed:", error);
        },
    };
};

export const getCompanySubCompaniesQuery = function subcompanies() {
    return {
        query: CompanySubCompanies,
        variables: getCvrNumber,
        update(data) {
            return data.subCompanies;
        },
        error(error) {
            console.error("Company subcompanies failed:", error);
        },
    };
};

export const getCompanyPropertiesQuery = function propertyQuery() {
    return {
        query: CompanyProperties,
        variables: getCvrNumber,
        update(data) {
            return data.subCompaniesFlat;
        },
        error(error) {
            console.error("Company properties failed:", error);
        },
    };
};

export const getCompanyPropertiesCountQuery = function propertyCountQuery() {
    return {
        query: CompanyPropertiesCount,
        variables: getCvrNumber,
        update(data) {
            return data.subCompaniesFlat;
        },
        error(error) {
            console.error("Company properties count failed:", error);
        },
    };
};

export const getCompanyStickyFiguresQuery = function stickyFiguresQuery() {
    return {
        query: CompanyStickyFigures,
        variables: getCvrNumber,
        update(data) {
            return data.companyByCvr;
        },
        error(error) {
            console.error("Company sticky figures failed:", error);
        },
    };
};

export const getCompanyPortfolioQuery = function portfolioQuery() {
    return {
        query: CompanyPortfolio,
        variables: getCvrNumber,
        update(data) {
            return data.subCompaniesFlat;
        },
        error(error) {
            console.error("Company portfolio failed:", error);
        },
    };
};

export const getCvrNumber = function cvrNumber() {
    return { cvrNumber: parseInt(this.$route.params.id, 10) };
};

export const getCvrNumberAndDepth = function cvrNumberAndDepth() {
    return { cvrNumber: parseInt(this.$route.params.id, 10), depth: 20 };
};