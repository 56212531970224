export const state = () => ({
    enabled: false,
    documents: [],
    language: "da",
    recipients: [],
    message: "",
});

export const getters = {
    getState(state) {
        return state;
    },
    getEnabled(state) {
        return state.enabled;
    },
    getDocuments(state) {
        return state.documents;
    },
    getLanguage(state) {
        return state.language;
    },
    getRecipients(state) {
        return state.recipients;
    },
    getMessage(state) {
        return state.message;
    },
};

export const mutations = {
    setEnabled(state, change) {
        state.enabled = change;
    },
    setDocuments(state, change) {
        state.documents = change;
    },
    setLanguage(state, change) {
        state.language = change;
    },
    setRecipients(state, change) {
        state.recipients = change;
    },
    setMessage(state, change) {
        state.message = change;
    },
};

export default {
    namespaced: true,
    state,
    getters,
    mutations,
};
