// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(function(i){return i[1]});
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".drawingControls_xGUkS{display:flex;height:2.15rem;position:relative}.drawingControlsInfo_1bFfX{background-color:var(--color-cyan-200);border-radius:var(--border-radius-small);padding:.35rem .5rem;position:absolute;z-index:1}.container_OpPIG,.layersContainer_BrefW{height:100%;width:100%}.container_OpPIG{position:relative}.mapboxgl-ctrl-attrib{display:none!important}.mapboxgl-ctrl-logo{display:none!important}", ""]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"drawingControls": "drawingControls_xGUkS",
	"drawingControlsInfo": "drawingControlsInfo_1bFfX",
	"container": "container_OpPIG",
	"layersContainer": "layersContainer_BrefW"
};
module.exports = ___CSS_LOADER_EXPORT___;
