// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(function(i){return i[1]});
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".container_RsuSZ{background-color:var(--color-white);border-radius:var(--border-radius-medium);box-shadow:var(--box-shadow-large);display:flex;flex-direction:column;max-height:75vh;overflow:auto;padding-bottom:.5rem;padding-top:.5rem}.label_sEFko{display:flex;margin-bottom:.75rem;margin-top:.75rem;padding:0 1rem}", ""]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"container": "container_RsuSZ",
	"label": "label_sEFko"
};
module.exports = ___CSS_LOADER_EXPORT___;
