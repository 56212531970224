export default function ({ app, route, store }) {
  const routesWithDrawing = ["explore", "explore-area-rent-levels"];

  if (routesWithDrawing.indexOf(route.name) < 0) {
    app.$map.then((map) => {
      store.commit("mapstate/setDrawingEnabled", false);
      map.fire("draw.deleteAllDrawings");
    });
  }
}
