
import NativeLayer from "./basicLayers/NativeLayer.vue";
import SymbolLayer from "./basicLayers/SymbolLayer.vue";
import MapPositionUpdateLayer from "./basicLayers/MapPositionUpdateLayer.vue";
import PlotsGeoJSONByBbox from "~/graphql/Map/PlotsGeoJSONByBbox.gql";
import { onClickMatrikelPolygon } from "~/helpers/map-helpers.js";
import { mapboxLayers } from "../mapboxLayers";
import { emptyGeoJson, plot_polygon_fills, plot_polygon_borders, plot_polygon_matrikel_numbers } from "../MapBoxConfig.js";
import { mapWritableState } from "pinia";
import { useChromeStore } from "~/composables/stores/chrome";

export default {
  components: {
    NativeLayer,
    SymbolLayer,
    MapPositionUpdateLayer,
  },

  data() {
    return {
      plot_polygon_fills,
      plot_polygon_borders,
      plot_polygon_matrikel_numbers,
      zoom: 0,
      plots: null,
      infoWindowLoading: false,
      infoWindowOpen: false,
      infoWindowContent: null,
      infoWindowPosition: null,
      currentHoverId: null,
      showOrtoForaar: this.$store.getters["mapstate/showOrtoForaar"],
      layerTooltipDebounce: null,
    };
  },

  computed: {
    ...mapWritableState(useChromeStore, { chromeSplitPercentageDragging: "splitPercentageDragging" }),

    showPlotsOnMap() {
      return this.$store.getters["mapstate/showPlots"] && this.zoom > 14;
    },

    showPlotNumbers() {
      return this.$store.getters["mapstate/showPlotNumbers"] && this.showPlotsOnMap;
    },

    mapboxLayers() {
      return mapboxLayers.flatMap((mbl) => mbl.layer);
    },
  },

  watch: {
    showPlotsOnMap(val) {
      if (val) {
        this.loadPlots();
      }
    },
  },

  mounted() {
    this.$map.then((map) => {
      this.zoom = map?.getZoom();
    });

    this.$store.watch(
      () => this.$store.getters["mapstate/showOrtoForaar"],

      (val) => {
        this.showOrtoForaar = val;
      }
    );
  },

  methods: {
    onClickMatrikelPolygon,
    loadData() {
      if (this.showPlotsOnMap) {
        this.loadPlots();
      }
    },

    async loadPlots() {
      const map = await this.$map;
      const bbox = map.getBounds().toArray();
      const resp = await this.$apollo.query({
        query: PlotsGeoJSONByBbox,
        fetchPolicy: "no-cache",
        variables: {
          bbox: `${bbox[0][0]},${bbox[0][1]},${bbox[1][0]},${bbox[1][1]}`,
        },
      });

      this.plots = resp.data.plotsGeoJSONByBbox || emptyGeoJson;
    },

    onMouseMovePlot(e) {
      if (this.chromeSplitPercentageDragging) {
        return;
      }

      this.$map.then((map) => {
        if (map && this.currentHoverId !== null) {
          map.setFeatureState({ source: "common-plots", id: this.currentHoverId }, { hover: false });
          map.getCanvas().style.cursor = "";
        }

        // Morten: Somehow e.features is not here anymore and then hoover wont work -- why??
        if (map && map.queryRenderedFeatures(e.point)[0].layer.id === "plot_polygon_fills") {
          this.currentHoverId = map.queryRenderedFeatures(e.point)[0].id;
          map.setFeatureState({ source: "common-plots", id: this.currentHoverId }, { hover: true });
          map.getCanvas().style.cursor = "pointer";
        }
      });
    },

    onMouseLeavePlot() {
      this.$map.then((map) => {
        if (map && this.currentHoverId !== null) {
          map.setFeatureState({ source: "common-plots", id: this.currentHoverId }, { hover: false });
        }
        map.getCanvas().style.cursor = "";
      });
    },

    mousemove(e, id) {
      clearTimeout(this.layerTooltipDebounce);

      this.layerTooltipDebounce = setTimeout(() => {
        this.$emit("show-layer-tooltip", id, e);
      }, 800);
    },

    mouseout(e, id) {
      clearTimeout(this.layerTooltipDebounce);

      this.$emit("hide-layer-tooltip", id, e);
    },
  },
};
