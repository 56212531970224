
import { getListsQuery, addPropertyToList, addPropertiesToList, createList } from "~/helpers/apollo/apollo-list-query-helpers.js";
import Me from "~/graphql/Authentication/Me.gql";

export default {
  name: "AddToList",

  props: {
    visible: {
      type: Boolean,
      default: false,
    },

    properties: {
      type: Array,
      default: null,
    },

    origin: {
      type: String,
      required: true,
    },
  },

  apollo: {
    lists: getListsQuery,

    me: {
      query: Me,
    },
  },

  data() {
    return {
      selectedList: {
        name: this.$t("ADD_TO_LIST_NOT_SELECTED"),
      },
      listItemDescription: "",
      displayMode: "addToList", // addToList, createList
      createListName: "",
      createListDescription: "",
      blockSubmit: false,
      loading: false,
    };
  },

  computed: {
    listsLoading() {
      return this.$apollo.queries.lists.loading;
    },

    validLists: function () {
      return this.lists.lists;
    },

    showListItemDescription: function () {
      return this.properties.length === 1;
    },
  },

  methods: {
    createList() {
      createList.call(this, this.createListName, this.createListDescription, "PRIVATE", [{ userId: this.me.id, permission: "ADMIN" }]).finally(() => {
        this.lists.lists.forEach((list) => {
          if (list.name == this.createListName) {
            this.selectedList = list;
            this.displayMode = "addToList";
          }
        });
      });
    },

    addToList() {
      if (this.properties.length === 1) {
        this.addPropertyToList();
      } else {
        this.addPropertiesToList();
      }
    },

    async addPropertyToList() {
      let input = {
        listId: this.selectedList.id,
        bfeNumber: this.properties[0],
        description: this.listItemDescription,
      };

      this.$amplitude.track({
        event_type: "Single save property",
        event_properties: {
          origin: this.origin,
          bfe: this.properties[0],
          description: this.description?.length > 0,
        },
      });

      if (this.description?.length > 0) {
        this.$amplitude.track({
          event_type: "Write description",
          event_properties: {
            origin: "add to list",
            description: this.description,
            bfeNumber: this.properties[0],
          },
        });
      }

      this.loading = true;

      await addPropertyToList.call(this, input).finally(() => {
        this.loading = false;
        this.$emit("properties-added");
        setTimeout(() => this.$emit("close"), 500);
      });
    },

    addPropertiesToList() {
      let propertyIds = this.properties.map((property) => {
        return property.bfeNumber;
      });

      let input = {
        listId: this.selectedList.id,
        properties: propertyIds,
      };

      this.$amplitude.track({
        event_type: "Bulk add properties to list",
        event_properties: {
          amount: propertyIds.length,
        },
      });

      this.loading = true;

      addPropertiesToList.call(this, input).finally(() => {
        this.success = true;
        this.loading = false;
        this.$emit("properties-added");
        setTimeout(() => this.$emit("close"), 500);
      });
    },
  },
};
