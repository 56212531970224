import _defineProperty from "@babel/runtime/helpers/esm/defineProperty";
export var useReactiveClone = function useReactiveClone(value, key) {
  var clone = reactive(_defineProperty({}, key, structuredClone(value.value)));
  watch(value, function (newvalue) {
    return clone[key] = structuredClone(newvalue);
  }, {
    immediate: true
  });
  var reset = function reset() {
    return clone[key] = structuredClone(value.value);
  };
  return {
    clone: clone,
    reset: reset
  };
};