import { useQuery } from "@vue/apollo-composable";
import { TenancyTenantsDocument, PlannedRentsMetricsCategory } from "~/graphql/generated/graphql";
export var useTenancyTenantsQuery = function useTenancyTenantsQuery(tenancyId) {
  var query = useQuery(TenancyTenantsDocument, function () {
    return {
      id: tenancyId,
      rentCategory: PlannedRentsMetricsCategory.Rent
    };
  });
  var loading = computed(function () {
    return query.loading.value;
  });
  var result = computed(function () {
    var _query$result$value;
    return (_query$result$value = query.result.value) === null || _query$result$value === void 0 ? void 0 : _query$result$value.assetManagementTenancy;
  });
  return {
    loading: loading,
    result: result
  };
};