export var useWindowSize = function useWindowSize() {
  var size = reactive({
    width: null,
    height: null
  });
  var handleResize = function handleResize() {
    var _window$innerWidth, _window, _window$innerHeight, _window2;
    size.width = (_window$innerWidth = (_window = window) === null || _window === void 0 ? void 0 : _window.innerWidth) !== null && _window$innerWidth !== void 0 ? _window$innerWidth : null;
    size.height = (_window$innerHeight = (_window2 = window) === null || _window2 === void 0 ? void 0 : _window2.innerHeight) !== null && _window$innerHeight !== void 0 ? _window$innerHeight : null;
  };
  onMounted(function () {
    return window.addEventListener("resize", handleResize);
  });
  onUnmounted(function () {
    return window.removeEventListener("resize", handleResize);
  });
  return size;
};