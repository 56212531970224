import { useQuery } from "@vue/apollo-composable";
import { PropertyDecomissionedFilter, PropertySubTypeFilter, TenancySoldFilter, TenancyDecomissionedFilter, excludePropertyFromResultsFilter, excludeTenancyFromResultsFilter } from "~/helpers/apollo/apollo-portfolio-filter-helpers";
import { MetricPeriodType, PlannedRentsMetricsCategory, PortfoliosDocument } from "~/graphql/generated/graphql";
export var usePortfoliosQuery = function usePortfoliosQuery() {
  var _useQueryFilters = useQueryFilters(),
    queryFilters = _useQueryFilters.value,
    setQueryFilters = _useQueryFilters.setValue;
  setQueryFilters("portfolioFilters", function () {
    return {
      matches: [{
        active: true
      }]
    };
  });
  setQueryFilters("propertyFilters", function () {
    return PropertyDecomissionedFilter();
  });
  setQueryFilters("propertyFilters", function () {
    return excludePropertyFromResultsFilter();
  });
  setQueryFilters("propertyFilters", function () {
    return PropertySubTypeFilter();
  });
  setQueryFilters("tenancyFilters", function () {
    return TenancyDecomissionedFilter();
  });
  setQueryFilters("tenancyFilters", function () {
    return TenancySoldFilter();
  });
  setQueryFilters("tenancyFilters", function () {
    return excludeTenancyFromResultsFilter();
  });
  var query = useQuery(PortfoliosDocument, function () {
    return {
      portfolioFilters: queryFilters.value.portfolioFilters,
      propertyFilters: queryFilters.value.propertyFilters,
      tenancyFilters: queryFilters.value.tenancyFilters,
      periodType: MetricPeriodType.Yearly,
      rentCategory: PlannedRentsMetricsCategory.Rent
    };
  });
  var loading = computed(function () {
    return query.loading.value;
  });
  var result = computed(function () {
    var _query$result$value$a, _query$result$value;
    return (_query$result$value$a = (_query$result$value = query.result.value) === null || _query$result$value === void 0 ? void 0 : _query$result$value.assetManagementPortfolios.items) !== null && _query$result$value$a !== void 0 ? _query$result$value$a : [];
  });
  var metadata = computed(function () {
    var _query$result$value2;
    return (_query$result$value2 = query.result.value) === null || _query$result$value2 === void 0 ? void 0 : _query$result$value2.assetManagementPortfolios.metadata;
  });
  return {
    loading: loading,
    result: result,
    metadata: metadata
  };
};