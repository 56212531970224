import { defineStore } from "pinia";
export var useSearchStore = defineStore("search", function () {
  var searchTerm = ref("");
  var results = ref([]);
  var selection = ref(-1);
  var selectedItem = ref();
  var updateSelection = function updateSelection(val) {
    var newSelection = selection.value + val;
    if (newSelection < -1) {
      selection.value = results.value.length - 1;
    } else if (newSelection > results.value.length - 1) {
      selection.value = -1;
    } else {
      selection.value = newSelection;
    }
  };
  var selectItem = function selectItem() {
    selectedItem.value = results.value[selection.value];
  };
  return {
    searchTerm: searchTerm,
    results: results,
    selection: selection,
    selectedItem: selectedItem,
    updateSelection: updateSelection,
    selectItem: selectItem
  };
});