// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(function(i){return i[1]});
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".listItem_tqZaG{-moz-column-break-inside:avoid;break-inside:avoid-column;column-break-inside:avoid;page-break-inside:avoid}.itemLetter_ONUO0{align-items:center;color:#383f45;display:flex;font-size:16px;font-style:normal;font-weight:500;height:40px;line-height:19px;padding-left:10px;text-align:center}", ""]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"listItem": "listItem_tqZaG",
	"itemLetter": "itemLetter_ONUO0"
};
module.exports = ___CSS_LOADER_EXPORT___;
