// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(function(i){return i[1]});
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".group_Kpd-k{display:flex;flex-direction:column}.group_Kpd-k:not(:last-child){border-bottom:1px dashed var(--color-supportive-3-900);margin-bottom:.5rem;padding-bottom:1rem}.explainer_RTyz8{display:flex;flex-direction:column;padding-left:.25rem;padding-top:.5rem}.soilconType_qJ9Ug{border-radius:50%;height:1.5rem;width:1.5rem}", ""]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"group": "group_Kpd-k",
	"explainer": "explainer_RTyz8",
	"soilconType": "soilconType_qJ9Ug"
};
module.exports = ___CSS_LOADER_EXPORT___;
