export const state = () => ({
  portfolios: [
    {
      id: 1,
      name: "Alice",
      type: "Hold portfolio",
      properties: 25,
      units: 310,
      color: 0,
      aquired: "2020-01-20",
    },
    {
      id: 2,
      name: "Benedict",
      type: "Hold portfolio",
      properties: 1,
      units: 29,
      warnings: ["Vacant units"],
      color: 0,
      aquired: "2020-01-20",
    },
    {
      id: 3,
      name: "Challenge",
      type: "Hold portfolio",
      properties: 31,
      units: 998,
      color: 1,
      aquired: "2020-01-20",
    },
    {
      id: 4,
      name: "Kayden",
      type: "Hold portfolio",
      properties: 21,
      units: 910,
      color: 1,
      aquired: "2020-01-20",
    },
    {
      id: 5,
      name: "Natalie",
      type: "Hold portfolio",
      properties: 2,
      units: 103,
      color: 5,
      aquired: "2020-01-20",
    },
    {
      id: 6,
      name: "Tommy",
      type: "Hold portfolio",
      properties: 1,
      units: 16,
      warnings: ["Here's one more!"],
      color: 5,
      aquired: "2020-01-20",
    },
    {
      id: 7,
      name: "Allison",
      type: "Breakup portfolio",
      properties: 3,
      units: 49,
      color: 9,
      aquired: "2020-01-20",
    },
    {
      id: 8,
      name: "Emerick",
      type: "Breakup portfolio",
      properties: 27,
      units: 101,
      color: 7,
      aquired: "2020-01-20",
    },
    {
      id: 9,
      name: "Naomi",
      type: "Breakup portfolio",
      properties: 9,
      units: 651,
      color: 9,
      aquired: "2020-01-20",
    },
    {
      id: 9,
      name: "Theodore",
      type: "Breakup portfolio",
      properties: 125,
      units: 598,
      color: 9,
      aquired: "2020-01-20",
    },
    {
      id: 10,
      name: "Freedom",
      type: "Commercial portfolio",
      properties: 21,
      units: 25,
      color: 10,
      aquired: "2020-01-20",
    },
  ],
});

export const mutations = {
  setPortfolioColor(state, payload) {
    const portfolio = state.portfolios.find((portfolio) => portfolio.id === payload.id);

    portfolio.color = payload.color;
  },
};

export const getters = {
  getPortfolio: (state) => (id) => {
    return state.portfolios.find((portfolio) => portfolio.id === id);
  },

  getPortfolios: (state) => {
    return state.portfolios;
  },
};

export default {
  namespaced: true,
  state,
  getters,
  mutations,
};
