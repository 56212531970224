
export default ({
  props: {
    layer: { type: Object, required: true },
  },

  computed: {
    active: {
      get() {
        return this.$store.getters["mapstate/" + this.layer.id];
      },
      set(value) {
        this.$store.dispatch("mapstate/setMapStateWithPreferences", { key: this.layer.id, value: value });
      },
    },
  },

  watch: {
    //TODO: remove this emit and have parent component rely on Vuex for getting the active state
    active: {
      handler(value) {
        this.$emit("toggle", this.layer.id, value);
      },
      immediate: true,
    },
  },
});
