import moment from "moment";

const MAX_STORED_LAST_VISITED_PROPERTIES = 5;
const LOCAL_STORAGE_KEY = "estaid:last-visited-properties";

const state = () => ({
  lastVisitedProperties: loadFromLocalStorage(LOCAL_STORAGE_KEY),
});

const getters = {
  lastVisitedProperties(state) {
    return state.lastVisitedProperties;
  },
};

const mutations = {
  pushToLastVisitedProperties(state, propertyVisit) {
    if (!propertyVisit) {
      return;
    }

    if (state.lastVisitedProperties == null) {
      state.lastVisitedProperties = [];
    }

    const existingPropertyIndex = state.lastVisitedProperties.findIndex((p) => p.bfeNumber == propertyVisit.bfeNumber);

    if (existingPropertyIndex > -1) {
      state.lastVisitedProperties[existingPropertyIndex].timestamp = propertyVisit.timestamp;
    } else {
      state.lastVisitedProperties.push(propertyVisit);
    }

    state.lastVisitedProperties.sort((a, b) => {
      return new moment(a.timestamp) - new moment(b.timestamp);
    });

    if (state.lastVisitedProperties.length > MAX_STORED_LAST_VISITED_PROPERTIES) {
      state.lastVisitedProperties.shift();
    }

    saveToLocalStorage(LOCAL_STORAGE_KEY, state.lastVisitedProperties);
  },
};

const saveToLocalStorage = (key, value) => {
  localStorage.setItem(key, JSON.stringify(value));
};

const loadFromLocalStorage = (key) => {
  return localStorage.getItem(key) ? JSON.parse(localStorage.getItem(key)) : [];
};

export default {
  namespaced: true,
  state,
  getters,
  mutations,
};
