
import MapLayerControlAccordionLayer from "./MapLayerControlAccordionLayer.vue";

export default {
  components: { MapLayerControlAccordionLayer },

  props: {
    icon: { type: String, required: true },
    translationKey: { type: String, required: true },
    layers: { type: Array, required: true },
    searchTerm: { type: String, required: true },
  },

  data() {
    return {
      activeLayers: [],
    };
  },

  computed: {
    filteredLayers() {
      if (!this.searchTerm || this.$t(this.translationKey).toLowerCase().includes(this.searchTerm.toLowerCase())) {
        // If no search term or the accordion's translation key matches the search term, return all layers
        return this.layers;
      } else {
        // Filter and return only the layers that match the search term within the accordion
        return this.layers.filter((layer) => {
          return this.$t(layer.translationKey).toLowerCase().includes(this.searchTerm.toLowerCase());
        });
      }
    },

    enabled() {
      if (!this.searchTerm) return true; // If no search term is provided, always show the accordion

      // Check if the current accordion's translation key matches the search term
      if (this.$t(this.translationKey).toLowerCase().includes(this.searchTerm.toLowerCase())) return true;

      // Check if there are any matching layers within this accordion
      return this.filteredLayers.length > 0;
    },

    searchView() {
      return !!this.searchTerm;
    },
  },

  mounted() {
    //TODO: remove this emit and have parent component rely on Vuex for getting the active state
    this.layers.forEach((layer) => {
      const active = this.$store.getters["mapstate/" + layer.id];
      this.handleToggle(layer.id, active);
    });
  },

  methods: {
    //TODO: remove this emit and have parent component rely on Vuex for getting the active state
    handleToggle(layer, enabled) {
      const index = this.activeLayers.indexOf(layer);
      if (index === -1 && enabled) {
        this.activeLayers.push(layer);
      } else if (index !== -1 && !enabled) {
        this.activeLayers.splice(index, 1);
      }
    },
  },
};
