import { PropertyNamesDocument } from "~/graphql/generated/graphql";
import { useQuery } from "@vue/apollo-composable";
export var usePropertyNamesQuery = function usePropertyNamesQuery(variables) {
  var query = useQuery(PropertyNamesDocument, function () {
    var filters = variables.value.filters;
    return {
      filters: filters
    };
  });
  var result = computed(function () {
    return query.result.value;
  });
  var loading = computed(function () {
    return query.loading.value;
  });
  return {
    result: result,
    loading: loading
  };
};