
export default {
  name: "MapLegendItem",

  inheritAttrs: false,

  props: {
    textHeader: {
      type: String,
      required: true,
    },

    colorHeader: {
      type: String,
      required: true,
    },

    /**
     * [{ text:String, color:String }]
     */
    items: {
      type: Array,
      required: true,
    },
  },
};
