import "core-js/modules/es6.object.keys.js";
import "core-js/modules/es6.symbol.js";
import "core-js/modules/es6.array.filter.js";
import "core-js/modules/es6.object.get-own-property-descriptor.js";
import "core-js/modules/es7.object.get-own-property-descriptors.js";
import _defineProperty from "@babel/runtime/helpers/esm/defineProperty";
function ownKeys(e, r) { var t = Object.keys(e); if (Object.getOwnPropertySymbols) { var o = Object.getOwnPropertySymbols(e); r && (o = o.filter(function (r) { return Object.getOwnPropertyDescriptor(e, r).enumerable; })), t.push.apply(t, o); } return t; }
function _objectSpread(e) { for (var r = 1; r < arguments.length; r++) { var t = null != arguments[r] ? arguments[r] : {}; r % 2 ? ownKeys(Object(t), !0).forEach(function (r) { _defineProperty(e, r, t[r]); }) : Object.getOwnPropertyDescriptors ? Object.defineProperties(e, Object.getOwnPropertyDescriptors(t)) : ownKeys(Object(t)).forEach(function (r) { Object.defineProperty(e, r, Object.getOwnPropertyDescriptor(t, r)); }); } return e; }
import { defineStore } from "pinia";
export var useChromeStore = defineStore("chrome", function () {
  var _usePreferencesNamesp = usePreferencesNamespace("preferencesNamespaceChrome"),
    updatePreferences = _usePreferencesNamesp.update,
    preferencesQueryResult = _usePreferencesNamesp.result,
    loading = _usePreferencesNamesp.loading;
  var splitPercentage = ref(50);
  var debouncedSplitPercentage = useDebounce(function () {
    var preferences = preferencesQueryResult.value;
    if (preferences) updatePreferences(_objectSpread(_objectSpread({}, preferences), {}, {
      splitPercentage: splitPercentage.value
    }));
  }, 600);
  watch(preferencesQueryResult, function () {
    if (preferencesQueryResult.value) splitPercentage.value = preferencesQueryResult.value.splitPercentage;
  }, {
    immediate: true
  });
  watch(splitPercentage, debouncedSplitPercentage);
  return {
    blockUI: ref(false),
    notificationActive: ref(0),
    splitPercentageDragging: ref(false),
    splitPercentageBarOmit: ref(false),
    splitPercentage: splitPercentage,
    loading: computed(function () {
      return loading.value;
    })
  };
});