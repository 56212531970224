export const state = () => ({
  configureColumnsVisible: false,
});

export const mutations = {
  setConfigureColumnsVisible(state, change) {
    state.configureColumnsVisible = change;
  },
};

export const getters = {
  getConfigureColumnsVisible(state) {
    return state.configureColumnsVisible;
  },
};

export default {
  namespaced: true,
  state,
  getters,
  mutations,
};
