import { useQuery } from "@vue/apollo-composable";
import { PropertyBudgetsDocument } from "~/graphql/generated/graphql";
export var useBudgetsPropertyQuery = function useBudgetsPropertyQuery(propertyId) {
  var query = useQuery(PropertyBudgetsDocument, function () {
    return {
      assetManagementPropertyId: propertyId
    };
  });
  var loading = computed(function () {
    return query.loading.value;
  });
  var budgets = computed(function () {
    var _query$result$value$a, _query$result$value, _query$result$value$a2, _query$result$value$a3;
    return (_query$result$value$a = (_query$result$value = query.result.value) === null || _query$result$value === void 0 ? void 0 : (_query$result$value$a2 = _query$result$value.assetManagementProperty) === null || _query$result$value$a2 === void 0 ? void 0 : (_query$result$value$a3 = _query$result$value$a2.assetManagementBudgets) === null || _query$result$value$a3 === void 0 ? void 0 : _query$result$value$a3.items) !== null && _query$result$value$a !== void 0 ? _query$result$value$a : [];
  });
  return {
    loading: loading,
    budgets: budgets
  };
};