import { useQuery } from "@vue/apollo-composable";
import { Operator } from "~/helpers/apollo/apollo-utility-helpers";
import { CapexAccountsDocument } from "~/graphql/generated/graphql";
export var useCapexCategoriesQuery = function useCapexCategoriesQuery(propertyId) {
  var _useQueryFilters = useQueryFilters({
      accountFilters: [function () {
        return {
          matches: [{
            property_id: [Operator.Equals, propertyId.value]
          }]
        };
      }],
      categoryFilters: [function () {
        return {
          matches: [{
            category_type: [Operator.In, ["capex", "CAPEX"]]
          }]
        };
      }]
    }),
    queryFilters = _useQueryFilters.value;
  var query = useQuery(CapexAccountsDocument, function () {
    return {
      accountFilters: queryFilters.value.accountFilters,
      categoryFilters: queryFilters.value.categoryFilters,
      skip: !propertyId.value
    };
  });
  var loading = computed(function () {
    return query.loading.value;
  });
  var result = computed(function () {
    var _query$result$value$a, _query$result$value, _query$result$value$a2;
    return (_query$result$value$a = (_query$result$value = query.result.value) === null || _query$result$value === void 0 ? void 0 : (_query$result$value$a2 = _query$result$value.assetManagementCategories) === null || _query$result$value$a2 === void 0 ? void 0 : _query$result$value$a2.items) !== null && _query$result$value$a !== void 0 ? _query$result$value$a : [];
  });
  return {
    loading: loading,
    result: result
  };
};