// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_GET_URL_IMPORT___ = require("../../../node_modules/css-loader/dist/runtime/getUrl.js");
var ___CSS_LOADER_URL_IMPORT_0___ = require("../assets/tile.svg");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(function(i){return i[1]});
var ___CSS_LOADER_URL_REPLACEMENT_0___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_0___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".fremium_egExT{display:flex;height:2.25rem;position:relative}.bar_63T5q,.copy_6C0Yz,.overlay_gBB2m{align-items:center;display:flex;height:100%;justify-content:center;position:absolute}.overlay_gBB2m{background-image:url(" + ___CSS_LOADER_URL_REPLACEMENT_0___ + ");background-origin:top left;background-repeat:repeat;opacity:.05}.copy_6C0Yz,.overlay_gBB2m{width:100%}.anchor_xj0ZZ{margin-left:.5rem}", ""]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"fremium": "fremium_egExT",
	"bar": "bar_63T5q",
	"copy": "copy_6C0Yz",
	"overlay": "overlay_gBB2m",
	"anchor": "anchor_xj0ZZ"
};
module.exports = ___CSS_LOADER_EXPORT___;
