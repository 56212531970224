import "core-js/modules/es6.array.find.js";
import "core-js/modules/es6.object.to-string.js";
import { useQuery } from "@vue/apollo-composable";
import { TenancyFilterBookmarksDocument } from "~/graphql/generated/graphql";
export var useDefaultTenancyFilterBookmarkQuery = function useDefaultTenancyFilterBookmarkQuery(enabled) {
  var query = useQuery(TenancyFilterBookmarksDocument, undefined, function () {
    return {
      enabled: !!enabled.value
    };
  });
  var result = computed(function () {
    var _query$result$value;
    return (_query$result$value = query.result.value) === null || _query$result$value === void 0 ? void 0 : _query$result$value.assetManagementTenancyFilterBookmarks.find(function (fm) {
      return fm.isDefault;
    });
  });
  var loading = computed(function () {
    return query.loading.value;
  });
  var promise = computed(function () {
    return query.loading.value ? new Promise(function (res) {
      return query.onResult(function (result) {
        return res(result.data);
      });
    }) : null;
  });
  return {
    result: result,
    loading: loading,
    promise: promise
  };
};