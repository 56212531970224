
import { trackPersonLookup } from "~/helpers/tracking-helpers/person-tracking-helpers.js";
import { getPersonName } from "~/helpers/person-helpers.js";

export default {
  props: {
    person: {
      type: Object,
      required: true,
    },

    renderAsChip: {
      type: Boolean,
      default: false,
    },

    trackingOrigin: {
      type: String,
      default: null,
    },

    // Override copy props
    copyType: {
      type: String,
      default: "body",
    },

    copySize: {
      type: String,
      default: "medium",
    },
  },
  methods: {
    getPersonName,
    getRoute() {
      return {
        name: "explore-person-id-overview",
        params: { id: this.person.id },
      };
    },

    track() {
      trackPersonLookup(this.person.id, this.person.name, this.trackingOrigin);
    },
  },
};
