export var useOpexSelectedBudgets = function useOpexSelectedBudgets(propertyId) {
  var _usePreferencesNamesp = usePreferencesNamespace("preferencesNamespacePortfolioPropertyOpex"),
    preferences = _usePreferencesNamesp.result,
    loading = _usePreferencesNamesp.loading;
  var result = computed(function () {
    var _preferences$value$pr, _preferences$value;
    return (_preferences$value$pr = (_preferences$value = preferences.value) === null || _preferences$value === void 0 ? void 0 : _preferences$value.propertyBudgets[propertyId.value]) !== null && _preferences$value$pr !== void 0 ? _preferences$value$pr : [];
  });
  return {
    result: result,
    loading: loading
  };
};