import { MetricPeriodType, ResultsPropertyDocument } from "~/graphql/generated/graphql";
import { useQuery } from "@vue/apollo-composable";
import moment from "moment";
import { type Ref } from "vue";

export const useBalanceKeyFigures = (propertyId: Ref<string>) => {
  return useQuery(ResultsPropertyDocument, () => {
    const date = moment().utc(false).startOf("year").toISOString();
    return {
      input: {
        from: date,
        periodType: MetricPeriodType.Yearly,
        propertyId: propertyId.value,
        to: date,
      },
    };
  });
};
