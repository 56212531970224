import { BalancePreferencesInjectionKey } from "~/helpers/portfolio/portfolio-balance-helpers";
export var useBalanceCellCopyText = function useBalanceCellCopyText(rowValue) {
  var filter = useFilter();
  var preferences = useInject(BalancePreferencesInjectionKey, true);
  return computed(function () {
    var value = rowValue.value;
    if (rowValue.type === "changePercentage") return filter.percentage.compact(value, {
      minDigits: 1
    });
    var displayThousands = preferences.value.numberDisplay === "THOUSANDS";
    var displayMillions = preferences.value.numberDisplay === "MILLIONS";
    if (displayThousands) value /= 1000;
    if (displayMillions) value /= 1000000;
    return filter.number(value, {
      maximumFractionDigits: displayMillions ? 2 : displayThousands ? 1 : 0,
      minimumFractionDigits: displayMillions ? 2 : displayThousands ? 1 : 0
    });
  });
};