import { useQuery } from "@vue/apollo-composable";
import { PropertySalesDocument } from "~/graphql/generated/graphql";
import { TenancyDecomissionedFilter, TenancySoldFilter } from "~/helpers/apollo/apollo-portfolio-filter-helpers";
export var usePropertySalesQuery = function usePropertySalesQuery(propertyId) {
  var _useQueryFilters = useQueryFilters({
      filters: [function () {
        return TenancyDecomissionedFilter();
      }, function () {
        return TenancySoldFilter();
      }]
    }),
    queryFilters = _useQueryFilters.value;
  var query = useQuery(PropertySalesDocument, {
    id: propertyId,
    filters: queryFilters.value.filters
  });
  var refetch = query.refetch;
  var loading = computed(function () {
    return query.loading.value;
  });
  var result = computed(function () {
    var _query$result$value, _query$result$value$a, _query$result$value$a2;
    return (_query$result$value = query.result.value) === null || _query$result$value === void 0 ? void 0 : (_query$result$value$a = _query$result$value.assetManagementProperty) === null || _query$result$value$a === void 0 ? void 0 : (_query$result$value$a2 = _query$result$value$a.assetManagementSales) === null || _query$result$value$a2 === void 0 ? void 0 : _query$result$value$a2.items;
  });
  return {
    loading: loading,
    result: result,
    refetch: refetch
  };
};