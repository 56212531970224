import "core-js/modules/es6.set.js";
import "core-js/modules/es6.string.iterator.js";
import "core-js/modules/es6.object.to-string.js";
import "core-js/modules/web.dom.iterable.js";
import "core-js/modules/es6.symbol.js";
import "core-js/modules/es6.array.from.js";
import "core-js/modules/es6.string.starts-with.js";
import { useQuery } from "@vue/apollo-composable";
import { AllTenanciesDocument, PlannedRentsMetricsCategory } from "~/graphql/generated/graphql";
import moment from "moment";
export var useAllTenanciesQuery = function useAllTenanciesQuery(variables, columns) {
  var query = useQuery(AllTenanciesDocument, function () {
    var _ref = variables.value,
      sort = _ref.sort,
      pagination = _ref.pagination,
      filters = _ref.filters,
      _ref$rentCategory = _ref.rentCategory,
      rentCategory = _ref$rentCategory === void 0 ? PlannedRentsMetricsCategory.Rent : _ref$rentCategory,
      date = _ref.date,
      _ref$includeFlags = _ref.includeFlags,
      includeFlags = _ref$includeFlags === void 0 ? false : _ref$includeFlags;
    var columnsSet = new Set(columns.value);
    var includeTenants = columnsSet.has("tenantName") || columnsSet.has("annualRent") || columnsSet.has("rentPsm") || columnsSet.has("monthlyRent") || columnsSet.has("monthlyRentPsm") || columnsSet.has("leaseStart") || columnsSet.has("leaseEnd") || columnsSet.has("arrears") || columnsSet.has("irrevocableUntilLandlord") || columnsSet.has("irrevocableUntilTenant") || columnsSet.has("leaseLength");
    var includeExternalIds = columnsSet.has("unitNumber");
    var includeSales = columnsSet.has("daysOnMarket");
    var includeValuations = columnsSet.has("brokerMandate");
    var includeReponsible = columnsSet.has("assignedTo");
    var includeProperty = columnsSet.has("property") || columnsSet.has("portfolio") || columnsSet.has("zip") || columnsSet.has("propertyAdminEnd") || columnsSet.has("propertyAdminStart");
    var includeCustomFields = Array.from(columnsSet.values()).some(function (col) {
      return col.startsWith("custom");
    });
    var useLatest = !date;
    var useAtDate = !!date;
    if (sort && date) sort.args = {
      at: date
    };
    return {
      filters: filters,
      sort: sort,
      pagination: pagination,
      rentCategory: rentCategory,
      date: date !== null && date !== void 0 ? date : moment.utc().toISOString(),
      tenantDate: date,
      useLatest: useLatest,
      useAtDate: useAtDate,
      includeValuations: includeValuations,
      includeTenants: includeTenants,
      includeSales: includeSales,
      includeExternalIds: includeExternalIds,
      includeProperty: includeProperty,
      includeFlags: includeFlags,
      includeReponsible: includeReponsible,
      includeCustomFields: includeCustomFields
    };
  }, function () {
    return {
      keepPreviousResult: true,
      enabled: !!variables.value && !!columns.value
    };
  });
  var result = computed(function () {
    var _query$result$value$a, _query$result$value;
    return (_query$result$value$a = (_query$result$value = query.result.value) === null || _query$result$value === void 0 ? void 0 : _query$result$value.assetManagementTenancies.items) !== null && _query$result$value$a !== void 0 ? _query$result$value$a : [];
  });
  var loading = computed(function () {
    return query.loading.value || !columns.value;
  });
  var meta = computed(function () {
    var _query$result$value$a2, _query$result$value2;
    return (_query$result$value$a2 = (_query$result$value2 = query.result.value) === null || _query$result$value2 === void 0 ? void 0 : _query$result$value2.assetManagementTenancies.metadata) !== null && _query$result$value$a2 !== void 0 ? _query$result$value$a2 : undefined;
  });
  return {
    result: result,
    loading: loading,
    meta: meta,
    refetch: query.refetch
  };
};