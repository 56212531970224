import "core-js/modules/es6.map.js";
import "core-js/modules/es6.string.iterator.js";
import "core-js/modules/es6.object.to-string.js";
import "core-js/modules/web.dom.iterable.js";
var handlers = new Map();
export default defineNuxtPlugin(function (nuxtApp) {
  nuxtApp.vueApp.directive("clickOutside", {
    bind: function bind(el, binding, vnode) {
      var handler = function handler(event) {
        var target = event.target;
        if (!(el == target || el.contains(target))) {
          binding.value(event);
        }
      };
      handlers.set(el, handler);
      document.body.addEventListener("click", handler);
    },
    unbind: function unbind(el) {
      var handler = handlers.get(el);
      if (handler) document.body.removeEventListener("click", handler);
      handlers.delete(el);
    }
  });
});