import { i18n } from "~/plugins/translator";

const ROLE_FOUNDER = "FOUNDERS";            // Grundlægger
const ROLE_REAL_OWNER = "REAL_OWNER";      // Reel ejer
const ROLE_LEGAL_OWNER = "LEGAL_OWNER";    // Legal ejer
const ROLE_DIRECTOR	= "DIRECTOR";           // Direktør
const ROLE_MANAGEMENT = "MANAGEMENT";	    // Direktionen
const ROLE_ADM_DIRECTOR	= "ADM_DIRECTOR";   // Administrerende direktør
const ROLE_BOARD_MEMBER	= "BOARD_MEMBER";   // Bestyrelsesmedlem
const ROLE_CHAIRMAN	= "CHAIRMAN";           // Bestyrelsesformand
const ROLE_RESPONSIBLE = "RESPONSIBLE";     // Medarbejder med fuldt ansvar
const ROLE_UNKNOWN = "UNKNOWN";

export const getRoleString = function (role) {
    switch (role) {
        case ROLE_FOUNDER:
            return i18n.t("OWNERS_C_FOUNDER")
        case ROLE_LEGAL_OWNER:
            return i18n.t("OWNERS_C_LEGAL")
        case ROLE_REAL_OWNER:
            return i18n.t("OWNERS_C_BEN")
        case ROLE_DIRECTOR:
            return i18n.t("OWNERS_C_MANAGER")
        case ROLE_MANAGEMENT:
            return i18n.t("OWNERS_C_MANAGEMENT")
        case ROLE_ADM_DIRECTOR:
            return i18n.t("OWNERS_C_CEO")
        case ROLE_BOARD_MEMBER:
            return i18n.t("OWNERS_C_BOARD")
        case ROLE_CHAIRMAN:
            return i18n.t("OWNERS_C_CHAIR")
        case ROLE_RESPONSIBLE:
            return i18n.t("OWNERS_C_RESPONSIBLE")
        default:
            return i18n.t("OWNERS_C_FOUNDER")
    }
}

export const calculatePersonAge = function (birthDay) {
    var today = new Date();
    var birthDate = new Date(birthDay);
    var age = today.getFullYear() - birthDate.getFullYear();
    var m = today.getMonth() - birthDate.getMonth();
    if (m < 0 || (m === 0 && today.getDate() < birthDate.getDate())) {
        age--;
    }
    return age;
}

export const getPersonName = function (person) {
    return person.name ? person.name : i18n.t('PERSON_WITH_NAME_PROTECTION')
}
