var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('div',{ref:"container",class:[_vm.$style.container, _vm.explodeWidth && _vm.$style.explodeWidth]},[(!_setup.isMobile)?_c('abbr',_vm._g({class:[
      'abbreviation',
      _vm.$style.abbr,
      !_vm.showOnHover && _vm.$style.cursorHelp,
      _vm.cursorHelp && _vm.$style.cursorHelp,
      _vm.underline && _vm.$style.underline,
      _vm.hoverEffectsDisable && _vm.$style.hoverEffectsDisable,
    ],on:{"click":_setup.show,"mouseenter":() => _vm.showOnHover && _setup.show(),"mouseleave":() => _vm.showOnHover && _setup.hide()}},_vm.$listeners),[_vm._t("default")],2):_c('span',{class:_vm.$style.span},[_vm._t("default")],2)])
}
var staticRenderFns = []

export { render, staticRenderFns }