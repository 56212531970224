// This middleware is responsible for storing page visit. For now we just track last visited properties, but we can extend it to track other pages as well.
import moment from "moment";

const PAGE_PROPERTY = "explore-property";

export default function ({ route, store }) {
  switch (true) {
    case route?.name?.includes(PAGE_PROPERTY):
      store.commit("pagevisit/pushToLastVisitedProperties", { bfeNumber: route.params.id, timestamp: moment().format("YYYY-MM-DD HH:mm:ss") });

      break;
  }
}
