
import SubscriptionLevelQuery from "~/graphql/User/SubscriptionLevel.gql"
import NumUserEventsQuery from "~/graphql/User/NumUserEvents.gql"
import CreateUserEvent from "~/graphql/User/CreateUserEvent.gql"

export default {
  inheritAttrs: false,

  data() {
    return {
      limit: null,
      remaining: null,
      hasAccess: true,
    }
  },

  computed: {
    percentage() {
      return Math.floor((this.remaining / this.limit) * 100)
    },

    colorForeground() {
      if (this.percentage < 33) {
        return "--color-pink-300"
      } else if (this.percentage < 66) {
        return "--color-yellow-300"
      } else {
        return "--color-green-300"
      }
    },

    colorBackground() {
      if (this.percentage < 33) {
        return "--color-pink-100"
      } else if (this.percentage < 66) {
        return "--color-yellow-100"
      } else {
        return "--color-green-100"
      }
    },

    colorText() {
      if (this.percentage < 33) {
        return "--color-pink-900"
      } else if (this.percentage < 66) {
        return "--color-yellow-900"
      } else {
        return "--color-green-900"
      }
    },
  },

  watch: {
    $route: {
      deep: true,
      handler(current, previous) {
        if (current.params.id !== previous.params.id) {
          this.checkSubscription(current)
        } else {
          this.updateAccess(current)
        }
      },
    },
  },

  async mounted() {
    this.checkSubscription(this.$route, true)
  },

  methods: {
    async checkSubscription(route, emitEvent) {
      const subscriptionLevel = await this.$apollo.query({
        query: SubscriptionLevelQuery,
      })

      if (subscriptionLevel?.data?.me?.subscriptionLevel === "FREEMIUM") {
        await this.updateUserEvents()

        if (this.remaining > 0) {
          if (!this.isFreeAccessRoute(route)) {
            let eventType

            if (route.name.lastIndexOf("explore-property-id") !== -1) {
              eventType = "PROPERTY"
            } else if (route.name.lastIndexOf("explore-company-id") !== -1) {
              eventType = "COMPANY"
            } else if (route.name.lastIndexOf("explore-person-id") !== -1) {
              eventType = "PERSON"
            } else {
              return
            }

            await this.$apollo.mutate({
              mutation: CreateUserEvent,
              variables: {
                input: {
                  eventType: eventType,
                  objectId: route.params.id.toString(),
                },
              },
            })
          }
          this.hasAccess = true
          this.updateUserEvents()
        } else {
          this.hasAccess = false
        }

        this.updateAccess(route)
      }
    },

    async updateUserEvents() {
      const numUserEvents = await this.$apollo.query({
        query: NumUserEventsQuery,
        fetchPolicy: "no-cache",
      })

      this.limit = numUserEvents?.data?.numUserEvents?.limit + 1 || 0
      this.remaining = numUserEvents?.data?.numUserEvents?.remaining || 0
    },

    isFreeAccessRoute(route) {
      return route.name === "explore-property-id-easements" || (this.$isFeatureSupported("freeAccessCommercialRent") && route.name === "explore-property-id-commercial-rent")
    },

    updateAccess(route) {
      if (this.isFreeAccessRoute(route)) {
        this.$store.commit("fremium/setBlockAccess", false)
      } else {
        this.$store.commit("fremium/setBlockAccess", this.hasAccess == false)
      }
    },
  },
}
