// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_GET_URL_IMPORT___ = require("../../node_modules/css-loader/dist/runtime/getUrl.js");
var ___CSS_LOADER_URL_IMPORT_0___ = require("../Chrome/components/Logo/assets/logo.svg");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(function(i){return i[1]});
var ___CSS_LOADER_URL_REPLACEMENT_0___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_0___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".container_M4SM5{background-color:var(--color-black-high-emphasis);display:flex;height:100%;-webkit-mask-image:url(" + ___CSS_LOADER_URL_REPLACEMENT_0___ + ");mask-image:url(" + ___CSS_LOADER_URL_REPLACEMENT_0___ + ");-webkit-mask-position:left center;mask-position:left center;-webkit-mask-repeat:no-repeat;mask-repeat:no-repeat;-webkit-mask-size:contain;mask-size:contain;width:100%;width:4rem}", ""]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"container": "container_M4SM5"
};
module.exports = ___CSS_LOADER_EXPORT___;
