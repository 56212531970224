
import ExploreSlider from "~/components/explore/filters/ExploreSlider.vue";
export default {
  components: {
    ExploreSlider,
  },
  computed: {
    construction: {
      get: function() {
        return this.$store.getters["explore/getConstruction"];
      },
      set: function(val) {
        this.$store.commit("explore/setConstruction", val);
      },
    },
  },
};
