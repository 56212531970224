
import EntityCard from "~/components/EntityCard/components/EntityCard.vue";

export default {
  components: { EntityCard },

  props: {
    id: {
      type: String,
      default: undefined,
    },
  },

  methods: {
    exploreSearch() {
      this.$store.dispatch("msga/setDrawDone");
      this.$store.commit("mapstate/setPopupOpen", false);
    },

    rentLevels() {
      this.$store.dispatch("areas/setDrawDone");
      this.$store.commit("mapstate/setPopupOpen", false);
      this.$amplitude.areaSearchLejetjek();
    },
  },
};
