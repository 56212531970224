
export const state = () => ({
  blockAccess: false
});

export const mutations = {
  setBlockAccess(state, change) {
    state.blockAccess = change;
  }
};

export const getters = {
  getBlockAccess(state) {
    return state.blockAccess;
  }
};

export default {
  namespaced: true,
  state,
  getters,
  mutations
};
