import { render, staticRenderFns } from "./ExploreChips.vue?vue&type=template&id=df69b700&scoped=true"
import script from "./ExploreChips.vue?vue&type=script&lang=js"
export * from "./ExploreChips.vue?vue&type=script&lang=js"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "df69b700",
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {Copy: require('/app/node_modules/@estaid/ui/dist/runtime/atoms/Copy/Copy.vue').default,Chip: require('/app/node_modules/@estaid/ui/dist/runtime/molecules/Chip/Chip.vue').default,ChipContainerRow: require('/app/node_modules/@estaid/ui/dist/runtime/molecules/Chip/ChipContainerRow.vue').default,CompositionLayoutSingle: require('/app/node_modules/@estaid/ui/dist/runtime/molecules/Composition/CompositionLayoutSingle.vue').default,Composition: require('/app/node_modules/@estaid/ui/dist/runtime/molecules/Composition/Composition.vue').default})
