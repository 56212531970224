// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_GET_URL_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/getUrl.js");
var ___CSS_LOADER_URL_IMPORT_0___ = require("./assets/tile.svg");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(function(i){return i[1]});
var ___CSS_LOADER_URL_REPLACEMENT_0___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_0___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".background_yPiGM,.container_TiV7e,.foreground_JSDIg{display:flex;height:100%;position:absolute;width:100%;z-index:1}.container_TiV7e{cursor:pointer;transition-duration:var(--transition-duration-shortest);transition-property:opacity;transition-timing-function:ease-in-out}.cursorPointerDisable_rp9q9{cursor:auto}.container_TiV7e:not(.opacityEffectDisable_onbaQ):hover{opacity:.5}.background_yPiGM{background-color:var(--overlay-color-background);opacity:var(--overlay-opacity-background)}.foreground_JSDIg{background-image:url(" + ___CSS_LOADER_URL_REPLACEMENT_0___ + ");background-origin:top left;background-repeat:repeat;opacity:var(--overlay-opacity-foreground)}", ""]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"background": "background_yPiGM",
	"container": "container_TiV7e",
	"foreground": "foreground_JSDIg",
	"cursorPointerDisable": "cursorPointerDisable_rp9q9",
	"opacityEffectDisable": "opacityEffectDisable_onbaQ"
};
module.exports = ___CSS_LOADER_EXPORT___;
