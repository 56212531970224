
import {
  formatReferenceResults,
  reference_property_pins
} from "../MapBoxConfig"
import { onClickPropertyPin, easeToDefault } from "~/helpers/map-helpers.js"
import SymbolLayer from "./basicLayers/SymbolLayer.vue"

export default {
  components: {
    SymbolLayer
  },

  data() {
    return {
      reference_property_pins
    }
  },

  computed: {
    results() {
      return this.$store.getters["reference/getProperties"]?.hits || []
    }
  },

  mounted() {
    if (!this.results) {
      this.easeToDefault()
    }
  },

  methods: {
    onClickPropertyPin,
    formatReferenceResults,
    easeToDefault
  }
}
