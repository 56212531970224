
import GetBuildingFromId from "~/graphql/Property/GetBuildingFromId.gql";

export default {
  apollo: {
    buildingById: {
      query: GetBuildingFromId,

      variables: function() {
        return {
          id: this.id,
        };
      },

      update(data) {
        return data.buildingById;
      },
    },
  },

  props: {
    id: {
      type: String,
      required: true,
    },
  },

  methods: {
    goToAreas(event) {
      this.$router.push({
        name: "explore-property-id-buildings",
        params: this.$route.params,
        query: {
          activeTab: 1,
        },
      });

      this.$emit("close", event);
    },
  },
};
