import { useQuery } from "@vue/apollo-composable";
import { DataWarehouseCategoriesDocument } from "~/graphql/generated/graphql";
import { Operator } from "~/helpers/apollo/apollo-utility-helpers";
export var useAggregationCategoriesQuery = function useAggregationCategoriesQuery() {
  var _useQueryFilters = useQueryFilters(),
    queryFilters = _useQueryFilters.value,
    setQueryFilters = _useQueryFilters.setValue;
  setQueryFilters("filters", function () {
    return {
      matches: [{
        aggregation_kind: [Operator.Equals, null, true /*inverse*/]
      }]
    };
  });
  var query = useQuery(DataWarehouseCategoriesDocument, {
    filters: queryFilters.value.filters
  });
  var result = computed(function () {
    var _query$result$value$a, _query$result$value;
    return (_query$result$value$a = (_query$result$value = query.result.value) === null || _query$result$value === void 0 ? void 0 : _query$result$value.assetManagementCategories.items) !== null && _query$result$value$a !== void 0 ? _query$result$value$a : [];
  });
  var loading = computed(function () {
    return query.loading.value;
  });
  return {
    result: result,
    loading: loading,
    refetch: query.refetch
  };
};