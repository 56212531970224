
import {
  onClickPropertyPin,
  fitToGeoJsonBounds
} from "~/helpers/map-helpers.js"
import {
  formatPropertyMarkers,
  formatCondoMarkers,
  formatBOFPMarkers,
  list_property_pins
} from "../MapBoxConfig"
import {
  PROPERTY_TYPE_MAIN_PROPERTY,
  PROPERTY_TYPE_CONDO,
  PROPERTY_TYPE_BUILDING_ON_FOREIGN_PLOT,
  getPropertyType
} from "~/helpers/property-helpers.js"
import SymbolLayer from "./basicLayers/SymbolLayer.vue"

export default {
  components: {
    SymbolLayer
  },

  data() {
    return {
      list_property_pins
    }
  },

  computed: {
    listEntities() {
      return this.$store.getters["lists/getListEntities"]
    }
  },

  watch: {
    listEntities: {
      immediate: true,

      handler(val) {
        if (!val) return

        const flatdata = val.flatMap(x => x.property).filter(x => !!x)

        const properties =
          formatPropertyMarkers(
            flatdata.filter(
              x => getPropertyType(x) === PROPERTY_TYPE_MAIN_PROPERTY
            )
          ) ?? []

        const condos =
          formatCondoMarkers(
            flatdata.filter(x => getPropertyType(x) === PROPERTY_TYPE_CONDO)
          ) ?? []

        const bofp =
          formatBOFPMarkers(
            flatdata.filter(
              x => getPropertyType(x) === PROPERTY_TYPE_BUILDING_ON_FOREIGN_PLOT
            )
          ) ?? []

        const data = {
          type: "FeatureCollection",
          features: [...properties, ...condos, ...bofp].filter(x => !!x)
        }
        this.$map.then(map => {
          map?.getSource("list")?.setData(data)

          this.fitToGeoJsonBounds(data)
        })
      }
    }
  },

  methods: {
    onClickPropertyPin,
    fitToGeoJsonBounds
  }
}
