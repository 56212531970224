
import building from "~/components/svg/building.vue";

export default {
  components: { building },
  props: {
    data: {
      type: Object,
      required: true,
    },
  },

  computed: {
    owner() {
      if (this.data.owners) {
        return this.data.owners
          .map((x) => {
            return x.name;
          })
          .join(", ");
      } else if (this.data.ownerNames) {
        return this.data.ownerNames.replace(/(,(?=\S)|:)/, ", ");
      }
      return null;
    },
    address() {
      if (this.data.condo?.unit?.addres) {
        return this.data.condo.unit.address;
      } else if (this.data.plots?.some((p) => p?.addresses)) {
        return this.data.plots.find((p) => p?.addresses).addresses[0];
      } else {
        return undefined;
      }
    },
    bfeNumber() {
      return this.data.bfeNumber;
    },
    matrikelNumber() {
      if (this.data && this.data.plots) {
        return this.data.plots.find((plot) => plot != null).matrikelNumber;
      } else {
        return this.data.matrikelNumber;
      }
    },
    ownersGuildCode() {
      if (this.data && this.data.plots) {
        return this.data.plots.find((plot) => plot != null).ownersGuildCode;
      } else {
        return this.data.ownerId;
      }
    },
    matrikelNumberAndGuildCodes() {
      if (this.data && this.data.plots) {
        return this.data.plots.map((plot) => {
          return {
            matrikelNumber: plot.matrikelNumber,
            ownersGuildName: plot.ownersGuildName,
          };
        });
      }
      return [];
    },
  },
  methods: {
    search(e) {
      if (e.ctrlKey) {
        window.open(`${window.location.origin}/${this.bfeNumber}`);
      } else {
        this.$emit("close");
        const search = {
          id: this.bfeNumber,
          searchTerm:
            this.data.address?.streetName +
              " " +
              this.data.address?.streetNumber ||
            this.address ||
            "",
        };
        this.$store.dispatch("search/initiateAddressSearch", {
          search,
          track: "company portfolio",
        });
      }
    },
  },
};
