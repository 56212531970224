const cyan900 = getComputedStyle(document.documentElement).getPropertyValue("--color-cyan-900").replace(" ", "");

export const mapboxDrawStyles = [
  // ACTIVE (being drawn)
  // line stroke
  {
    id: "gl-draw-line",
    type: "line",
    filter: ["all", ["==", "$type", "LineString"], ["!=", "mode", "static"]],
    layout: {
      "line-cap": "round",
      "line-join": "round",
    },
    paint: {
      "line-color": cyan900,
      "line-width": 3,
    },
    visibility: "visible",
  },
  // polygon fill
  {
    id: "gl-draw-polygon-fill",
    type: "fill",
    filter: ["all", ["==", "$type", "Polygon"], ["!=", "mode", "static"]],
    paint: {
      "fill-color": cyan900,
      "fill-outline-color": cyan900,
      "fill-opacity": 0.3,
    },
    visibility: "visible",
  },
  // polygon mid points
  // {
  //     'id': 'gl-draw-polygon-midpoint',
  //     'type': 'circle',
  //     'filter': ['all',
  //         ['==', '$type', 'Point'],
  //         ['==', 'meta', 'midpoint'],
  //     ],
  //     'paint': {
  //         'circle-radius': 0,
  //         'circle-color': "#FF00FF"
  //     },
  // },
  // polygon outline stroke
  // This doesn't style the first edge of the polygon, which uses the line stroke styling instead

  {
    id: "gl-draw-polygon-stroke-active",
    type: "line",
    filter: ["all", ["==", "$type", "Polygon"], ["!=", "mode", "static"]],
    layout: {
      "line-cap": "round",
      "line-join": "round",
    },
    paint: {
      "line-color": cyan900,
      "line-width": 3,
    },
    visibility: "visible",
  },
  // vertex point halos
  // {
  //   "id": "gl-draw-polygon-and-line-vertex-halo-active",
  //   "type": "circle",
  //   "filter": ["all", ["==", "meta", "vertex"], ["==", "$type", "Point"], ["!=", "mode", "static"]
  // ],
  //   "paint": {
  //     "circle-radius": 7,
  //     "circle-color": cyan900
  //   }
  // },
  // vertex points
  {
    id: "gl-draw-polygon-and-line-vertex-active",
    type: "circle",
    filter: ["all", ["==", "meta", "vertex"], ["==", "$type", "Point"], ["!=", "mode", "static"]],
    paint: {
      "circle-radius": 5,
      "circle-color": cyan900,
    },
    visibility: "visible",
  },

  // INACTIVE (static, already drawn)
  // line stroke
  {
    id: "gl-draw-line-static",
    type: "line",
    filter: ["all", ["==", "$type", "LineString"], ["==", "mode", "static"]],
    layout: {
      "line-cap": "round",
      "line-join": "round",
    },
    paint: {
      "line-color": "#000",
      "line-width": 3,
    },
    visibility: "visible",
  },
  // polygon fill
  {
    id: "gl-draw-polygon-fill-static",
    type: "fill",
    filter: ["all", ["==", "$type", "Polygon"], ["==", "mode", "static"]],
    paint: {
      "fill-color": "#000",
      "fill-outline-color": "#000",
      "fill-opacity": 0.3,
    },
    visibility: "visible",
  },
  // polygon outline
  {
    id: "gl-draw-polygon-stroke-static",
    type: "line",
    filter: ["all", ["==", "$type", "Polygon"], ["==", "mode", "static"]],
    layout: {
      "line-cap": "round",
      "line-join": "round",
    },
    paint: {
      "line-color": "#000",
      "line-width": 3,
    },
    visibility: "visible",
  },
];
