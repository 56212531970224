import "core-js/modules/es6.array.slice.js";
import { useQuery } from "@vue/apollo-composable";
import { AgentsDocument } from "~/graphql/generated/graphql";
import { hasValue } from "~/helpers/common-helpers";
export var useAgentsQuery = function useAgentsQuery() {
  var query = useQuery(AgentsDocument);
  var items = computed(function () {
    var _query$result$value$a, _query$result$value, _query$result$value$a2;
    return (_query$result$value$a = (_query$result$value = query.result.value) === null || _query$result$value === void 0 ? void 0 : (_query$result$value$a2 = _query$result$value.assetManagementAgents) === null || _query$result$value$a2 === void 0 ? void 0 : _query$result$value$a2.items) !== null && _query$result$value$a !== void 0 ? _query$result$value$a : [];
  });
  return {
    result: computed(function () {
      return items.value.slice().sort(function (a, b) {
        return hasValue(a.agentName) && hasValue(b.agentName) ? a.agentName.localeCompare(b.agentName) : 0;
      });
    }),
    loading: query.loading,
    refetch: query.refetch
  };
};