import { useQuery } from "@vue/apollo-composable";
import { PortfolioRentRegulationPrinciplesDocument } from "~/graphql/generated/graphql";
export var usePortfolioRentRegulationPrinciplesQuery = function usePortfolioRentRegulationPrinciplesQuery(portfolioId) {
  var query = useQuery(PortfolioRentRegulationPrinciplesDocument, {
    id: portfolioId
  });
  var result = computed(function () {
    var _query$result$value, _query$result$value$a;
    return (_query$result$value = query.result.value) === null || _query$result$value === void 0 ? void 0 : (_query$result$value$a = _query$result$value.assetManagementPortfolio) === null || _query$result$value$a === void 0 ? void 0 : _query$result$value$a.tenancyRentRegulationPrinciples;
  });
  return {
    loading: query.loading,
    result: result
  };
};