
import MapLayerControlAccordion from "./MapLayerControlAccordion.vue";

import { mapboxLayers } from "../mapboxLayers";
import { mapGetters, mapMutations } from "vuex";
import { useIsMobile } from "~/composables/useIsMobile";

export default {
  name: "Controls",

  components: { MapLayerControlAccordion },

  inheritAttrs: false,

  data() {
    return {
      searchTerm: "",
      mapboxLayers,
      oilTanksPlacementFilters: [
        { name: this.$t("OVERLAY_ENV_ALL"), value: null },
        {
          name: this.$t("OVERLAY_ENV_OVERGROUND"),
          value: ["==", ["get", "locationCode"], "2"],
        },
        {
          name: this.$t("OVERLAY_ENV_UNDERGROUND"),
          value: ["==", ["get", "locationCode"], "1"],
        },
        {
          name: this.$t("OVERLAY_ENV_INDOOR"),
          value: ["==", ["get", "locationCode"], "3"],
        },
        {
          name: this.$t("OVERLAY_ENV_UNKNOWN"),
          value: ["==", ["get", "locationCode"], "0"],
        },
      ],
      oilTanksDisamblementFilters: [
        { name: this.$t("OVERLAY_ENV_ALL"), value: null },
        {
          name: this.$t("OVERLAY_ENV_DEMOLISHED"),
          value: ["==", ["get", "demolished"], true],
        },
        {
          name: this.$t("OVERLAY_ENV_NDEMOLISHED"),
          value: ["==", ["get", "demolished"], false],
        },
      ],

      soilconV1: true,
      soilconV2: true,
      isMobile: useIsMobile,
    };
  },

  computed: {
    showAdvanvcedSearchResultsPins: {
      get() {
        return this.$store.getters["mapstate/showAdvanvcedSearchResultsPins"];
      },
      set(value) {
        this.$store.commit("mapstate/showAdvanvcedSearchResultsPins", value);
      },
    },

    showOrtoForaar: {
      get() {
        return this.$store.getters["mapstate/showOrtoForaar"];
      },
      set(value) {
        this.$store.dispatch("mapstate/setMapStateWithPreferences", { key: "showOrtoForaar", value: value });
      },
    },

    showPropertyPlots: {
      get() {
        return this.$store.getters["mapstate/showPropertyPlots"];
      },
      set(value) {
        this.$store.dispatch("mapstate/setMapStateWithPreferences", { key: "showPropertyPlots", value: value });
      },
    },

    showPropertyBuildings: {
      get() {
        return this.$store.getters["mapstate/showPropertyBuildings"];
      },
      set(value) {
        this.$store.dispatch("mapstate/setMapStateWithPreferences", { key: "showPropertyBuildings", value: value });
      },
    },

    showSoilContaminations: {
      get() {
        return this.$store.getters["mapstate/showSoilContaminations"];
      },
      set(value) {
        this.$store.dispatch("mapstate/setMapStateWithPreferences", { key: "showSoilContaminations", value: value });
      },
    },

    showOilTanks: {
      get() {
        return this.$store.getters["mapstate/showOilTanks"];
      },
      set(value) {
        this.$store.dispatch("mapstate/setMapStateWithPreferences", { key: "showOilTanks", value: value });
      },
    },

    showPlots: {
      get() {
        return this.$store.getters["mapstate/showPlots"];
      },
      set(value) {
        this.$store.dispatch("mapstate/setMapStateWithPreferences", { key: "showPlots", value: value });
      },
    },

    showPlotNumbers: {
      get() {
        return this.$store.getters["mapstate/showPlotNumbers"];
      },
      set(value) {
        this.$store.dispatch("mapstate/setMapStateWithPreferences", { key: "showPlotNumbers", value: value });
      },
    },

    oilTanksPlacementFilter: {
      get() {
        return this.$store.getters["mapstate/oilTanksPlacementFilter"];
      },
      set(value) {
        this.$store.dispatch("mapstate/setMapStateWithPreferences", { key: "oilTanksPlacementFilter", value: value });
      },
    },

    oilTanksDisamblementFilter: {
      get() {
        return this.$store.getters["mapstate/oilTanksDisamblementFilter"];
      },
      set(value) {
        this.$store.dispatch("mapstate/setMapStateWithPreferences", { key: "oilTanksDisamblementFilter", value: value });
      },
    },

    poi: {
      get() {
        return this.$store.getters["mapstate/poi"];
      },
      set(value) {
        this.$store.dispatch("mapstate/setMapStateWithPreferences", { key: "poi", value: value });
      },
    },

    building_number_label: {
      get() {
        return this.$store.getters["mapstate/building_number_label"];
      },
      set(value) {
        this.$store.dispatch("mapstate/setMapStateWithPreferences", { key: "building_number_label", value: value });
      },
    },

    transit: {
      get() {
        return this.$store.getters["mapstate/transit"];
      },
      set(value) {
        this.$store.dispatch("mapstate/setMapStateWithPreferences", { key: "transit", value: value });
      },
    },

    soilLoading: {
      ...mapGetters({ get: "mapstate/soilLoading" }),
      ...mapMutations({ set: "mapstate/soilLoading" }),
    },

    heightLoading: {
      ...mapGetters({ get: "mapstate/heightLoading" }),
      ...mapMutations({ set: "mapstate/heightLoading" }),
    },

    plotsLoading: {
      ...mapGetters({ get: "mapstate/plotsLoading" }),
      ...mapMutations({ set: "mapstate/plotsLoading" }),
    },

    buildingsMarkAll: {
      get() {
        return {
          indeterminate: !this.buildingDocuments.every((x, i, a) => x.value === a[0].value),
        };
      },

      set(val) {
        this.buildingDocuments.forEach((x) => (x.value = val));
      },
    },

    numberSearchResultsLayersActive() {
      return this.showAdvanvcedSearchResultsPins ? 1 : 0;
    },

    numberOfPropertyLayersActive() {
      return [this.showPropertyBuildings, this.showPropertyPlots].filter((l) => l).length;
    },

    numberOfEnvironmentalLayersActive() {
      return [this.showSoilContaminations, this.showOilTanks].filter((l) => l).length;
    },

    numberOfSurroundingLayersActive() {
      return [this.showPlots, this.showPlotNumbers, this.poi, this.building_number_label, this.transit].filter((l) => l).length;
    },

    ...mapboxLayers.reduce((acc, section) => {
      acc[`numberOf${section.section}LayersActive`] = {
        get() {
          return section.layer.filter((l) => this[l.id]).length;
        },
      };

      return acc;
    }, {}),
  },

  methods: {
    getLoading(loadingprop) {
      return this[loadingprop];
    },

    amplititudeLogEvent(change, checkbox) {
      if (change) {
        this.$amplitude.track({ event_type: "Map enable " + checkbox.title.toLowerCase() });
      }
    },

    allFalse() {
      this.PTA_Alle_V = true;

      if (this.PTA_Alle_V) {
        this.PTA_Veje_V = true;
      }
    },

    self() {
      return this;
    },
  },
};
