import { defineStore } from "pinia";
export var ACCESS_GROUP_KEY = "accessGroup";
export var useAccessGroupStore = defineStore("accessGroup", function () {
  // We use localStorage to persist the access group
  var accessGroup = ref(localStorage.getItem(ACCESS_GROUP_KEY) || "");
  watch(accessGroup, function (value) {
    localStorage.setItem(ACCESS_GROUP_KEY, value);
  });
  return {
    accessGroup: accessGroup
  };
});