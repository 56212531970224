
export default {
  computed: {
    geography() {
      return this.$store.getters["explore/getGeography"]
    },
    owners() {
      return this.$store.getters["explore/getCvr"]
    },
    ownership() {
      return this.$store.getters["explore/getOwnership"]
    },
    usage() {
      return this.$store.getters["explore/getUsage"]
    },
    filters() {
      return this.$store.getters["explore/getNumberOfAppliedFilters"]
    },
    amountOfFilters() {
      return (
        this.geography.length +
        this.owners.length +
        this.ownership.length +
        this.usage.length
      )
    },
    ranges() {
      let arr = []
      const def = this.$store.getters["explore/getDefaults"]
      //SITE AREA
      // if user has changed the state of the data
      if (this.$store.getters["explore/getSizeSiteAreaEnabled"]) {
        const site = this.$store.getters["explore/getSizeSiteArea"]
        let name = this.$t("LIST_EXPORT_SITEAREA")
        // if user has changed the lower handle
        if (site[0] != def.size.siteArea[0]) {
          //if user has changed both handles handle then
          if (site[1] != def.size.siteArea[1]) {
            name += `: ${site[0]} - ${site[1]} m²`
          } else {
            name += `: ${site[0]}+ m²`
          }
        } else {
          //user must have only changed the upper handle then
          name += `: ${this.$t("EXPLORE_CHIPS_BELOW")} ${site[1]} m²`
        }
        arr.push({ id: 1, name: name, close: "clearSizeSiteArea" })
      }
      //BUILDING AREA
      if (this.$store.getters["explore/getSizeBuildingAreaEnabled"]) {
        const build = this.$store.getters["explore/getSizeBuildingArea"]
        let name = this.$t("EXPLORE_LIST_ITEM_BUILDING_AREA")
        if (build[0] != def.size.buildingArea[0]) {
          if (build[1] != def.size.buildingArea[1]) {
            name += `: ${build[0]} - ${build[1]} m²`
          } else {
            name += `: ${build[0]}+ m²`
          }
        } else {
          name += `: ${this.$t("EXPLORE_CHIPS_BELOW")} ${build[1]} m²`
        }
        arr.push({ id: 2, name: name, close: "clearSizeBuildingArea" })
      }
      //NO OF FLOORS
      if (this.$store.getters["explore/getSizeNoOfFloorsEnabled"]) {
        const floor = this.$store.getters["explore/getSizeNoOfFloors"]
        let name = ""
        if (floor[0] != def.size.noOfFloors[0]) {
          if (floor[1] != def.size.noOfFloors[1]) {
            name += `${floor[0]} - ${floor[1]}`
          } else {
            name += `${floor[0]}+`
          }
        } else {
          name += `${this.$t("EXPLORE_CHIPS_BELOW")} ${floor[1]}`
        }
        name += ` ${this.$t("EXPLORE_CHIPS_FLOORS")}`
        arr.push({ id: 3, name: name, close: "clearSizeNoOfFloors" })
      }
      //NO OF UNITS
      if (this.$store.getters["explore/getSizeNoOfUnitsEnabled"]) {
        const unit = this.$store.getters["explore/getSizeNoOfUnits"]
        let name = ""
        if (unit[0] != def.size.noOfUnits[0]) {
          if (unit[1] != def.size.noOfUnits[1]) {
            name += `${unit[0]} - ${unit[1]}`
          } else {
            name += `${unit[0]}+`
          }
        } else {
          name += `${this.$t("EXPLORE_CHIPS_BELOW")} ${unit[1]}`
        }
        name += ` ${this.$t("EXPLORE_CHIPS_UNITS")}`
        arr.push({ id: 4, name: name, close: "clearSizeNoOfUnits" })
      }
      // CONSTRUCTION YEAR
      if (this.$store.getters["explore/getConstructionEnabled"]) {
        const cons = this.$store.getters["explore/getConstruction"]
        let name = ""
        if (cons[0] != def.construction[0]) {
          if (cons[1] != def.construction[1]) {
            name += `${this.$t("EXPLORE_FILTER_CONSTRUCTION_YEAR")} ${
              cons[0]
            } - ${cons[1]}`
          } else {
            name += `${this.$t("EXPLORE_FILTER_CONSTRUCTION_AFTER")} ${cons[0]}`
          }
        } else {
          name += `${this.$t("EXPLORE_FILTER_CONSTRUCTION_BEFORE")} ${cons[1]}`
        }
        arr.push({ id: 5, name: name, close: "clearConstruction" })
      }

      return arr
    },
    drawingsEnabled() {
      return this.$store.getters["explore/getPolygonEnabled"]
    }
  },

  methods: {
    closeGeography(geo) {
      this.$store.commit("explore/removeGeography", geo)
    },
    closeCvr(cvr) {
      this.$store.commit("explore/removeCvr", cvr)
    },
    closeOwnership(ownr) {
      this.$store.commit("explore/removeOwnership", ownr)
    },
    closeUsage(usg) {
      this.$store.commit("explore/removeUsage", usg)
    },
    removeDrawings() {
      this.$map.then(map => {
        map?.fire("draw.deleteAllDrawings")
      })
    }
  }
}
