// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(function(i){return i[1]});
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".container_thXP8{display:flex;flex-direction:column}.container_thXP8.enabled_fzquO{outline:2px solid green;outline-offset:-1px;pointer-events:auto}.container_thXP8.disabled_6wRgI{outline:2px solid red;outline-offset:-1px;pointer-events:none}", ""]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"container": "container_thXP8",
	"enabled": "enabled_fzquO",
	"disabled": "disabled_6wRgI"
};
module.exports = ___CSS_LOADER_EXPORT___;
