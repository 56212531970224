export const state = () => ({
  template: [],
  isExampleViewEnabled: true,
  isDraggingDocument: false,
  namingModalConfig: {
    open: false,
    item: null,
  },
  setKeys: null,
  separator: null,
  isDraggingKey: false,
  hasEdited: false,
});

export const getters = {
  getIsDraggingDocument(state) {
    return state.isDraggingDocument;
  },
  getTemplate(state) {
    return state.template;
  },
  getNamingModalItem(state) {
    return state.namingModalConfig.item;
  },
  getIsExampleViewEnabled(state) {
    return state.isExampleViewEnabled;
  },
  getSetKeys(state) {
    return state.setKeys;
  },
  getSeparator(state) {
    return state.separator;
  },
};

export const mutations = {
  setIsDraggingDocument(state, isDraggingDocument) {
    return (state.isDraggingDocument = isDraggingDocument);
  },
  openNamingModal(state, item) {
    state.namingModalConfig.open = true;
    state.namingModalConfig.item = item;
  },
  closeNamingModal(state) {
    state.namingModalConfig.item = false;
  },
  setIsDraggingKey(state, isDraggingKey) {
    return (state.isDraggingKey = isDraggingKey);
  },
  setIsExampleViewEnabled(state, isExampleViewEnabled) {
    return (state.isExampleViewEnabled = isExampleViewEnabled);
  },
  setHasEdited(state, hasEdited) {
    return (state.hasEdited = hasEdited);
  },
  setNamingModalConfigItem(state, item) {
    return (state.namingModalConfig.item = item);
  },
  setKeys(state, item) {
    return (state.setKeys = item);
  },
  setTemplate(state, template) {
    return (state.template = template);
  },
  setSeparator(state, separator) {
    return (state.separator = separator);
  },
};

export default {
  nampespaced: true,
  state,
  getters,
  mutations,
};
