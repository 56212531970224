import "core-js/modules/es6.function.name.js";
import { useApolloClient } from "@vue/apollo-composable";
import { MeAuthDocument } from "~/graphql/generated/graphql";
export var useAmplitude = function useAmplitude() {
  var identified = ref(false);
  var _ref = useNuxtApp(),
    $amplitude = _ref.$amplitude;
  if (!identified.value) {
    identify($amplitude);
    identified.value = true;
  }
  return $amplitude;
};
var identify = function identify(ampli) {
  var client = useApolloClient().client;
  client.query({
    query: MeAuthDocument
  }).then(function (result) {
    var _data$me$organization, _data$me$organization2;
    var data = result.data;
    if (!data.me) {
      return;
    }
    ampli.identify(data.me.id, {
      name: data.me.name,
      orgId: (_data$me$organization = data.me.organization) === null || _data$me$organization === void 0 ? void 0 : _data$me$organization.id,
      orgName: (_data$me$organization2 = data.me.organization) === null || _data$me$organization2 === void 0 ? void 0 : _data$me$organization2.name
    });
  });
};