
import ownershipcodes from "~/static/ownershipcodes.json";
import SearchCvr from "~/graphql/Company/SearchCvr.gql";
import Subcompanies from "~/graphql/Company/Subcompanies.gql";

export default {
    data() {
        return {
            ownershipSearch: "",
            search: "",
            searchResults: [],
            debounce: false,
            isTyping: false,
            loading: false,
            ownershipArr: [],
        };
    },
    computed: {
        ownershipEnabled() {
            return this.$store.getters["explore/getOwnershipEnabled"];
        },
        ownership: {
            get: function () {
                return this.$store.getters["explore/getOwnership"];
            },
            set: function (value) {
              this.$store.commit("explore/setOwnership", value);
            },
        },
        ownerships() {
            return ownershipcodes.filter((x) => !x.disabled);
        },
        cvr() {
            return this.$store.getters["explore/getCvr"];
        },
        subcompanies() {
            return this.$store.getters["explore/getSubcompanies"];
        },
        includeSubcompanies: {
            get: function () {
                return this.$store.getters["explore/getIncludeSubcompanies"];
            },
            set: function (val) {
                this.$store.commit("explore/setIncludeSubcompanies", val);
            },
        },
    },
    watch: {
        search(input) {
            this.debounceSearch();
            if (input != null) {
                this.isLoading = true;
            }
        },
        isTyping(val) {
            if (val == true || this.search == "" || this.search == null) {
                return;
            }
            this.$apollo
                .query({
                query: SearchCvr,
                variables: { input: this.search },
            })
                .then((resp) => {
                if (resp.data.searchCvr == null) {
                    this.searchResults = [];
                    return;
                }
                let arr = [];
                resp.data.searchCvr.forEach((result) => {
                    arr.push({ name: result.name, cvr: result.cvr });
                });
                this.searchResults = arr;
            })
                .finally(() => (this.isLoading = false));
        },
    },
    methods: {
        debounceSearch() {
            this.isTyping = true;
            clearTimeout(this.debounce);
            this.debounce = setTimeout(() => {
                this.isTyping = false;
            }, 300);
        },
        addCvrItem(item) {
            if (!item) {
                return;
            }
            this.debounce = false;
            this.isTyping = false;
            if (this.includeSubcompanies) {
                this.$apollo
                    .query({
                    query: Subcompanies,
                    variables: { cvr: item.cvr },
                })
                    .then((resp) => {
                    let companies = [];
                    if (resp.data.subCompanies != null) {
                        this.populateCompanyList(resp.data.subCompanies, companies, true);
                        this.$store.commit("explore/addCvr", {
                            ...item,
                            subcompanies: companies.length,
                        });
                        this.$store.commit("explore/setSubcompanies", companies.map((x) => {
                            return { ...x, parent: item.cvr };
                        }));
                        this.loading = false;
                    }
                })
                    .catch((err) => {
                    this.$store.commit("explore/addCvr", item);
                    console.error(err);
                });
            }
            this.$store.commit("explore/addCvr", item);
        },
        populateCompanyList(company, results, initial) {
            if (company == null) {
                return;
            }
            if (!initial) {
                results.push({
                    cvr: company.company.cvrNumber,
                    name: company.company.name,
                });
            }
            let children = [];
            if (!initial) {
                if (company.company.subcompanies != null &&
                    company.company.subcompanies.length > 0) {
                    children = company.company.subcompanies;
                }
            }
            else {
                if (company.subcompanies != null && company.subcompanies.length > 0) {
                    children = company.subcompanies;
                }
            }
            children.forEach((child) => {
                this.populateCompanyList(child, results, false);
            });
        },
        removeitem(item) {
            if (item.parent) {
                this.$store.commit("explore/removeSubcompanies", item);
            }
            else
                this.$store.commit("explore/removeCvr", item);
        },
    }
};
