// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(function(i){return i[1]});
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".container_4xIUa{display:flex;flex-direction:column}.container_4xIUa:not(:last-child){margin-bottom:1rem}.icon_vQ2iO{background-color:cyan;border-radius:.75rem;display:flex;height:.75rem;width:.75rem}", ""]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"container": "container_4xIUa",
	"icon": "icon_vQ2iO"
};
module.exports = ___CSS_LOADER_EXPORT___;
