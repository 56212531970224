
import PropertyCentroid from "~/graphql/Property/PropertyCentroid.gql";
import LokalebasenMapPins from "~/graphql/Property/LokalebasenMapPins.gql";

import moment from "moment";

import SymbolLayer from "./basicLayers/SymbolLayer.vue";

import { rent_level_pins, formatRenLevelMarkers } from "../MapBoxConfig.js";

export default {
  apollo: {
    propertyByBFENumber: {
      query: PropertyCentroid,

      variables: function () {
        return {
          bfeNumber: this.bfeNumber,
        };
      },

      skip: function () {
        return !this.bfeNumber;
      },
    },

    lokaleBasenRent: {
      query: LokalebasenMapPins,

      variables: function () {
        return {
          input: {
            centroid: { lat: this.centroid.lat, lon: this.centroid.lng },
            dateFrom: moment(this.$store.getters["mapstate/lokalebasenDateFrom"]).utc().format(),
            dateTo: moment(this.$store.getters["mapstate/lokalebasenDateTo"]).utc().format(),
            radius: this.searchRadius / 1000 - 0.0000000001,
          },
        };
      },

      result(data) {
        const locations = data.data?.lokaleBasenRent?.locations || [];

        const markers = formatRenLevelMarkers(locations);

        if (markers) {
          const features = {
            type: "FeatureCollection",
            features: [...markers],
          };

          this.$map.then((map) => {
            map?.getSource("rent-levels")?.setData(features);
          });
        }
      },

      skip: function () {
        return !this.centroid;
      },
    },
  },

  components: {
    SymbolLayer,
  },

  data() {
    return {
      rent_level_pins,
      searchRadiusTimer: undefined,
      searchRadius: this.$store.getters["mapstate/lokalebasenSearchRadius"],
    };
  },

  computed: {
    bfeNumber() {
      return parseInt(this.$router.history?.current?.params?.id, 10);
    },

    centroid() {
      return this.propertyByBFENumber?.centroid;
    },
  },

  mounted() {
    this.$store.watch(
      () => this.$store.getters["mapstate/lokalebasenSearchRadius"],

      (val) => {
        clearTimeout(this.searchRadiusTimer);

        this.searchRadiusTimer = setTimeout(() => {
          this.searchRadius = val;
        }, 600);
      }
    );
  },
};
