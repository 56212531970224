// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(function(i){return i[1]});
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".group_mTgFH{display:flex;flex-direction:column}.group_mTgFH:not(:last-child){border-bottom:1px dashed var(--color-supportive-3-900);margin-bottom:.5rem;padding-bottom:1rem}", ""]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"group": "group_mTgFH"
};
module.exports = ___CSS_LOADER_EXPORT___;
