import { render, staticRenderFns } from "./PersonMapLayer.vue?vue&type=template&id=e6ed1a06"
import script from "./PersonMapLayer.vue?vue&type=script&lang=js"
export * from "./PersonMapLayer.vue?vue&type=script&lang=js"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {SymbolLayer: require('/app/components/map/layers/basicLayers/SymbolLayer.vue').default})
