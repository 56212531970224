
export default {
  props: {
    value: {
      type: Array,
      required: true,
    },
    // eg. m2
    type: {
      type: String,
      default: "",
    },
    // eg. [0,30000]
    minmax: {
      type: Array,
      required: true,
    },
    // specifies placeholder
    noMaxPlaceholder: {
      type: String,
      required: true,
    },
  },
  data() {
    return {
      textFieldIsActive: false,
    };
  },
  methods: {
    update(val) {
      this.$emit("input", val);
    },
    updateFirst(val) {
      let val_asInt = parseInt(val);
      if (isNaN(val_asInt)) {
        this.$emit("input", [this.minmax[0], this.value[1]]);
      } else {
        this.$emit("input", [val, this.value[1]]);
      }
      // this.$emit("input", [val, this.value[1]]);
    },
    updateLast(val) {
      let val_asInt = parseInt(val);
      if (isNaN(val_asInt)) {
        this.$emit("input", [this.value[0], this.minmax[1]]);
      } else {
        this.$emit("input", [this.value[0], val]);
      }
      // this.$emit("input", [this.value[0], val]);
    },
    getsteps() {
      if (this.minmax[1] > 10000 && !this.textFieldIsActive) {
        return 100;
      } else {
        return 1;
      }
    },
    getPlaceholder() {
      return this.noMaxPlaceholder;
    },
    setTextFieldActive(val) {
      this.textFieldIsActive = val;
    },
  },
};
