
export default {
  props: {
    loading: {
      type: Boolean,
      required: true,
    },
    ready: {
      type: Boolean,
      required: true,
    },
  },
};
