export const COLORS = ["blue", "magenta", "cyan", "autumn", "yellow", "brown", "maroon", "supportive-3", "pink", "purple", "green", "red", "black"];

export const getAllColors = function () {
  return COLORS;
};

export const getColorFromIndex = function (id: number) {
  return COLORS[id % COLORS.length];
};

export const getColorFromHex = function (hex: string) {
  if (!hex) return "white";

  switch (hex.toUpperCase()) {
    case "#1C1C1C":
      return "black";

    case "#F0C137":
      return "yellow";

    case "#805AD2":
      return "purple";

    case "#4E7FFF":
      return "blue";

    case "#FEACAB":
      return "pink";

    case "#8FDAB2":
      return "green";

    case "#FF4E4E":
      return "red";

    case "#DABC8F":
      return "brown";

    case "#D48FDA":
      return "magenta";

    case "#8FD1DA":
      return "cyan";

    case "#DA8F8F":
      return "maroon";

    case "#CBDA8F":
      return "autumn";

    default:
      return "white";
  }
};

export const getHexFromColor = function (color: string, saturation: number = 500) {
  if (color == "white") return "#FFFFFF";

  const hex = getColorHex(`--color-${color}-${saturation}`).trim();

  return hex ? hex : "#1C1C1C";
};

export const getColorHex = (cssVariable: string) => {
  return getComputedStyle(document.documentElement).getPropertyValue(cssVariable).replace(" ", "");
};

export const getColorsHex = () => {
  return COLORS.map((color) => {
    return getColorHex(`--color-${color}-500`);
  });
};

export const getColorNamesRepeating = (n: number) => {
  const a: string[] = [];

  let i = 0;

  for (let j = 0; j < n; j++) {
    if (i >= COLORS.length) {
      i = 0;
    }

    a.push(COLORS[i]);

    i++;
  }

  return a;
};

// Determistic get a color from COLORS array based on a string
export const getColorFromString = (str: string) => {
  const hash = str.split("").reduce((acc, char) => {
    return acc + char.charCodeAt(0);
  }, 0);

  return getColorFromIndex(hash);
};
