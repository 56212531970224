import ListEntities from '../graphql/Lists/Collaboration/ListEntities.gql';
import CreateListStatus from '../graphql/Lists/Collaboration/CreateListStatus.gql';
import DeleteListStatus from '../graphql/Lists/Collaboration/DeleteListStatus.gql';
import UpdateListStatus from '../graphql/Lists/Collaboration/UpdateListStatus.gql';

import UpdateListProperty from '../graphql/Lists/Collaboration/UpdateListProperty.gql';
import UpdateListCompany from '../graphql/Lists/Collaboration/UpdateListCompany.gql';
import RemovePropertyFromList from "~/graphql/Lists/RemovePropertyFromList.gql";

import ListPropertyFull from "~/graphql/Lists/ListPropertyFull.gql";
import CreateNote from "~/graphql/Lists/CreateNote.gql";
import DeleteNote from "~/graphql/Lists/DeleteNote.gql";
import RebalanceListWeights from "../graphql/Lists/Collaboration/RebalanceListWeights.gql"

export const state = () => ({
  statusForRenaming: null,
  statusForDeletion: null,
  creatingStatus: false,
  listId: localStorage.getItem('list-id'),
  statuses: [],
  listEntities: [],
  initialLoad: false,
  listLoading: false,
});

export const mutations = {
  setStatuses(state, statuses) {
    state.statuses = statuses;
  },

  setListEntities(state, listEntities) {
    state.listEntities = listEntities;
  },

  setListId(state, listId) {
    state.listId = listId;
    localStorage.setItem('list-id', listId);
  },

  moveListProperty: (state, { element, newStatus }) => {
    const index = state.listEntities.indexOf(element);
    state.listEntities[index].status = newStatus;
  },

  setStatusForRenaming: (state, status) => {
    state.statusForRenaming = status;
  },

  setStatusForDeletion: (state, status) => {
    state.statusForDeletion = status;
  },

  setCreatingStatus: (state, bool) => {
    state.creatingStatus = bool;
  },

  addListStatus: (state, status) => {
    state.statuses.push(status);
  },

  updateListStatus: (state, status) => {
    const i = state.statuses.findIndex((x) => x.id === status.id);
    state.statuses.splice(i, 1, status);
  },

  deleteListStatus: (state, statusId) => {
    for (let i = 0; i < state.listEntities.length; i++) {
      if (state.listEntities[i].statusId === statusId) {
        state.listEntities[i].statusId = null
      }
    }
    const i = state.statuses.findIndex((x) => x.id === statusId);
    state.statuses.splice(i, 1);
  },

  updateListEntity: (state, { id, status, weight }) => {
    const i = state.listEntities.findIndex((x) => x.id === id);
    state.listEntities.splice(i, 1, { ...state.listEntities[i], statusId: status, weight });
  },

  deleteListEntity: (state, id) => {
    const i = state.listEntities.findIndex((x) => x.id === id);
    state.listEntities.splice(i, 1);
  },

  updateListEntityDescription: (state, {id, description}) => {
    const i = state.listEntities.findIndex((x) => x.id === id);
    state.listEntities.splice(i, 1, { ...state.listEntities[i], description: description });
  },

  setInitialLoad: (state, value) => {
    state.initialLoad = value;
  },

  setListLoading: (state, value) => {
    state.listLoading = value;
  },

};

export const actions = {
  fetchListByIdWithLoading(context, listId) {
    context.commit('setInitialLoad', true);
    context.dispatch('fetchListById', listId);
  },

  fetchListById(context, listId) {
    context.commit('setListLoading', true)
    this.app.apolloProvider.defaultClient
      .query({
        query: ListEntities,
        variables: { input: listId },
        fetchPolicy: 'no-cache',
      })
      .then((resp) => {
        const { id, listEntities, statuses } = resp.data.list;
        context.commit('setListEntities', listEntities);
        context.commit('setStatuses', statuses);
        context.commit('setListId', id);
        context.commit('setInitialLoad', false);
        context.commit('setListLoading', false);
      })
      .catch(() => {
        context.commit('setListEntities', []);
        context.commit('setInitialLoad', false);
        context.commit('setListLoading', false);
      });
  },

  createListStatus(context, newListStatus) {
    context.commit('setCreatingStatus', false);
    context.commit('addListStatus', newListStatus);
    const weights = context.getters.getStatuses?.map(s => s.weight).filter(w => w !== undefined);
    const highestWeight = weights.length === 0 ? 0 : Math.max(...context.getters.getStatuses?.map(s => s.weight).filter(w => w !== undefined))
    const weight = highestWeight + 1000;
    this.app.apolloProvider.defaultClient.mutate({
      mutation: CreateListStatus,
      variables: { input: {...newListStatus, weight} },
      update() {
        context.dispatch('fetchListById', context.state.listId);
      },
    });
  },

  updateListStatus(context, updateListStatus) {
    context.commit('setStatusForRenaming', null);
    context.commit('updateListStatus', updateListStatus);
    this.app.apolloProvider.defaultClient.mutate({
      mutation: UpdateListStatus,
      variables: { input: updateListStatus },
      update() {
        context.dispatch('fetchListById', context.state.listId);
      },
    });
  },

  deleteListStatus(context, {statusId, listId}) {
    context.commit("deleteListStatus", statusId)
    context.commit('setStatusForDeletion', null);

    this.app.apolloProvider.defaultClient.mutate({
      mutation: DeleteListStatus,
      variables: { input: {statusId, listId} },
      update() {
        context.dispatch('fetchListById', context.state.listId);
      },

    });
  },

  rebalanceCurrentListWeights(context) {
    this.app.apolloProvider.defaultClient.mutate({
      mutation: RebalanceListWeights,
      variables: { input: context.getters.getListId },
    }).then((resp) => {
        const { id, listEntities, statuses } = resp.data.rebalanceListWeights;
        context.commit('setListEntities', listEntities);
        context.commit('setStatuses', statuses);
        context.commit('setListId', id);
        context.commit('setInitialLoad', false);
        context.commit('setListLoading', false);
    });
  },

  updateListEntity(context, {id, description, statusId, type, newIndex}) {
      const statusEntities = context.getters.getListEntities.filter(x => x.statusId === statusId).sort((a, b) => a.weight - b.weight)
      const currentIndex = statusEntities.findIndex(x => x.id === id)
      // Rebalance list if any two listEntities in the same stage is less than 10 weight apart.
      const shouldRebalance = statusEntities.some((x, i, arr) => arr[i+1] && Math.abs(x.weight - arr[i+1].weight) < 10 )

      let newWeight;

      // if dragged to first position
      if(newIndex == 0) {
        newWeight = statusEntities[0] ? statusEntities[0].weight - 1000 : 0
      }
      // if dragged from another stage
      else if(currentIndex == -1 ) {

        // if dragged to last position
        if (newIndex == statusEntities.length) {
          newWeight = statusEntities[statusEntities.length - 1].weight + 1000
        }
        // if dragged between two
        else {
          newWeight = Math.floor((statusEntities[newIndex - 1].weight + statusEntities[newIndex].weight)/2);
        }
      }
      // if relocated in same stage
      else {
        // if dragged to last position
        if (newIndex == statusEntities.length - 1) {
          newWeight = statusEntities[statusEntities.length - 1].weight + 1000
        }
        // if dragged between two from higher index
        else if(newIndex < currentIndex) {
          newWeight = Math.floor((statusEntities[newIndex - 1].weight + statusEntities[newIndex].weight)/2);
        }
        // if dragged between two from lower index
        else if (newIndex > currentIndex) {
          newWeight = Math.floor((statusEntities[newIndex].weight + statusEntities[newIndex + 1].weight)/2);
        }
      }

      const updateListEntity = {id, weight: newWeight, status: statusId, description: description}
      context.commit('updateListEntity', updateListEntity)

      let mutation;
      if (type === 'ListProperty') {
        mutation = UpdateListProperty
      } else if (type === 'ListCompany') {
        mutation = UpdateListCompany
      } else {
        console.error("lists/updateListEntity expects a type")
        return;
      }

      this.app.apolloProvider.defaultClient.mutate({
        mutation,
        variables: { input: updateListEntity },
        update() {
          if(shouldRebalance) {
            context.dispatch('rebalanceCurrentListWeights')
          } else {
            context.dispatch('fetchListById', context.state.listId);
          }
        },
      })
  },

  updateListEntityDescription(context, {id, description, type }) {
    let mutation;
    if (type === 'ListProperty') {
      mutation = UpdateListProperty
    } else if (type === 'ListCompany') {
      mutation = UpdateListCompany
    } else {
      console.error("lists/updateListEntityDescription expects a type")
      return;
    }

    const updateListEntity = {id, description}

    context.commit('updateListEntityDescription', updateListEntity)

    this.app.apolloProvider.defaultClient.mutate({
      mutation: mutation,
      variables: {
        input: updateListEntity
      },
      update: (store, {data: updateListProperty}) => {
        // Read the data from our cache for this query.
        const data = structuredClone(store.readQuery({
          query: ListPropertyFull,
          variables: {input: id}
        }));

        data.listProperty.description = updateListProperty.updateListProperty.description || ""

        store.writeQuery({
          query: ListPropertyFull,
          variables: {input: id},
          data
        });
    }
    });
  },

  deleteListEntity(context, id) {
    context.commit("deleteListEntity", id)

    this.app.apolloProvider.defaultClient.mutate({
      mutation: RemovePropertyFromList,
      variables: {
        input: id
      },
      update() {
        context.dispatch('fetchListById', context.state.listId);
      },
    });
  },

  deleteNote(_, note) {
    this.app.apolloProvider.defaultClient.mutate({
      mutation: DeleteNote,
      variables: {
          input: note.id,
      },
      update: (store) => {
        // Read the data from our cache for this query.
        const data = structuredClone(store.readQuery({
          query: ListPropertyFull,
          variables: {input: note.entityId}
        }));

        data.listProperty.notes = data.listProperty.notes.filter((x) =>  x.id != note.id)

        store.writeQuery({
          query: ListPropertyFull,
          variables: {input: note.entityId},
          data
        });
      }
    });
  },

  createNote(_, {listPropertyId, message}) {
    this.app.apolloProvider.defaultClient.mutate({
      mutation: CreateNote,
      variables: {
          entityId: listPropertyId,
          message: message,
      },
      update: (store, { data: { createNote } }) => {
          // Read the data from our cache for this query.
          const data = structuredClone(store.readQuery({
            query: ListPropertyFull,
            variables: {input: listPropertyId}
          }));

          data.listProperty.notes? data.listProperty.notes.push(createNote) : data.listProperty.notes = [createNote]

          store.writeQuery({
            query: ListPropertyFull,
            variables: {input: listPropertyId},
            data
          });
      }
  });
  }
};

export const getters = {
  getListId: (state) => state.listId,

  getStatuses: (state) => state.statuses,

  getStatusForRenaming: (state) => state.statusForRenaming,

  getStatusForDeletion: (state) => state.statusForDeletion,

  getCreatingStatus: (state) => state.creatingStatus,

  getInitialLoad: (state) => state.initialLoad,

  getListLoading: (state) => state.listLoading,

  getKanbanData: (state) => {
    const result = [{
      status: null,
      entities: state.listEntities.filter((x) => x.statusId === null).sort((a, b) => a.weight - b.weight)  || [],
    }];

    for (let i = 1; i <= state.statuses.length; i++) {
      result[i] = {
        status: state.statuses[i - 1],
        entities: state.listEntities.filter((x) => x.statusId === state.statuses[i - 1].id).sort((a, b) => a.weight - b.weight) || [],
      };
    }
    return result;
  },

  getListEntities: (state) => state.listEntities,
};

export default {
  namespaced: true,
  state,
  getters,
  mutations,
  actions,
};
