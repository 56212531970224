// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(function(i){return i[1]});
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".tabContainer_ypTFJ{height:100%;overflow-x:hidden;overflow-y:auto;scroll-behavior:smooth;-webkit-overflow-scrolling:touch;scrollbar-color:var(--color-black-300) var(--color-white);scrollbar-width:thin}.tabContainer_ypTFJ::-webkit-scrollbar{width:1rem}.tabContainer_ypTFJ::-webkit-scrollbar-track{background:var(--color-white)}.tabContainer_ypTFJ::-webkit-scrollbar-thumb{background-color:var(--color-black-300);border:.25rem solid var(--color-white);border-radius:calc(1rem - var(--border-radius-medium))}.tabContainer_ypTFJ::-webkit-scrollbar-thumb:hover{border-width:.2rem}.buttonContainer_BqK7I{align-items:flex-end;border-top:1px solid var(--color-black-100);display:flex;padding-top:1rem}", ""]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"tabContainer": "tabContainer_ypTFJ",
	"buttonContainer": "buttonContainer_BqK7I"
};
module.exports = ___CSS_LOADER_EXPORT___;
