import "core-js/modules/es6.object.keys.js";
import "core-js/modules/es6.symbol.js";
import "core-js/modules/es6.array.filter.js";
import "core-js/modules/es6.object.get-own-property-descriptor.js";
import "core-js/modules/es7.object.get-own-property-descriptors.js";
import _defineProperty from "@babel/runtime/helpers/esm/defineProperty";
function ownKeys(e, r) { var t = Object.keys(e); if (Object.getOwnPropertySymbols) { var o = Object.getOwnPropertySymbols(e); r && (o = o.filter(function (r) { return Object.getOwnPropertyDescriptor(e, r).enumerable; })), t.push.apply(t, o); } return t; }
function _objectSpread(e) { for (var r = 1; r < arguments.length; r++) { var t = null != arguments[r] ? arguments[r] : {}; r % 2 ? ownKeys(Object(t), !0).forEach(function (r) { _defineProperty(e, r, t[r]); }) : Object.getOwnPropertyDescriptors ? Object.defineProperties(e, Object.getOwnPropertyDescriptors(t)) : ownKeys(Object(t)).forEach(function (r) { Object.defineProperty(e, r, Object.getOwnPropertyDescriptor(t, r)); }); } return e; }
import "core-js/modules/es6.regexp.replace.js";
import "core-js/modules/es6.object.to-string.js";
import "core-js/modules/es6.date.to-string.js";
import "core-js/modules/es6.regexp.to-string.js";
import "core-js/modules/es6.regexp.split.js";
import "core-js/modules/es6.array.map.js";
import moment from "moment";
import { prepareNumber } from "~/helpers/common-helpers";
var unfunc = function unfunc(v) {
  return typeof v === "function" ? v() : v;
};

/**
 * Composabel function to work with url params as reactive variables.
 */
export var useUrlParam = function useUrlParam(key, defaultValue, fromQueryParams, toQueryParams) {
  var route = useRoute();
  var router = useRouter();
  var value = computed(function () {
    return route.query[key] ? fromQueryParams(route.query[key]) : unfunc(defaultValue);
  });
  return {
    key: key,
    value: value,
    setValue: function setValue(newValue) {
      var _ref = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : {},
        dry = _ref.dry,
        replace = _ref.replace;
      var params = toQueryParams(newValue);
      if (!dry) {
        var query = _objectSpread({}, route.query);
        if (params === "") delete query[key];else query[key] = params;
        replace ? router.replace({
          query: query
        }) : router.push({
          query: query
        });
      }
      return params;
    }
  };
};
export var useUrlParamNumber = function useUrlParamNumber(key, defaultValue) {
  return useUrlParam(key, defaultValue, function (value) {
    return parseInt(value, 10) || defaultValue;
  }, function (value) {
    try {
      return prepareNumber(value).toString();
    } catch (_unused) {
      return "";
    }
  });
};
export var useUrlParamBoolean = function useUrlParamBoolean(key, defaultValue) {
  return useUrlParam(key, defaultValue, function (value) {
    return value === "true";
  }, function (value) {
    var _value$toString;
    return (_value$toString = value === null || value === void 0 ? void 0 : value.toString()) !== null && _value$toString !== void 0 ? _value$toString : "";
  });
};
export var useUrlParamString = function useUrlParamString(key, defaultValue) {
  return useUrlParam(key, defaultValue, function (value) {
    try {
      return decodeURIComponent(value);
    } catch (e) {
      console.log(e, value);
      return "";
    }
  }, function (value) {
    return encodeURIComponent(value !== null && value !== void 0 ? value : "");
  });
};
export var useUrlParamDate = function useUrlParamDate(key, defaultValue) {
  return useUrlParam(key, defaultValue, function (value) {
    return moment(value);
  }, function (value) {
    var _value$format;
    return (_value$format = value === null || value === void 0 ? void 0 : value.format("YYYY-MM-DD")) !== null && _value$format !== void 0 ? _value$format : "";
  });
};
export var useUrlParamStringArray = function useUrlParamStringArray(key, defaultValue) {
  return useUrlParam(key, defaultValue, function (value) {
    var _value$split;
    return (_value$split = value.split(",")) === null || _value$split === void 0 ? void 0 : _value$split.map(function (v) {
      return decodeURIComponent(v);
    });
  }, function (value) {
    return value ? value.map(function (v) {
      return encodeURIComponent(v);
    }).join(",") : "";
  });
};
export var useUrlParamSort = function useUrlParamSort(key, defaultValue) {
  return useUrlParam(key, defaultValue, function (value) {
    var _ref2 = JSON.parse(value),
      key = _ref2.key,
      direction = _ref2.direction,
      relation = _ref2.relation,
      overrideNullOrder = _ref2.overrideNullOrder;
    return {
      key: key,
      direction: direction,
      relation: relation,
      overrideNullOrder: overrideNullOrder
    };
  }, function (value) {
    if (value) {
      var sort = {};

      /** This is sometimes a graphql querysort, so we make sure to only grab the relevant fields */
      if (value.key) sort.key = value.key;
      if (value.direction) sort.direction = value.direction;
      if (value.relation) sort.relation = value.relation;
      if (value.overrideNullOrder) sort.overrideNullOrder = value.overrideNullOrder;
      return JSON.stringify(sort);
    } else return "";
  });
};
var urlParamTypeToFunctionMap = {
  number: useUrlParamNumber,
  string: useUrlParamString,
  boolean: useUrlParamBoolean,
  date: useUrlParamDate,
  stringArray: useUrlParamStringArray,
  sort: useUrlParamSort
};
export var useUrlParams = function useUrlParams(values) {
  return function (key) {
    var entry = values[key];
    var fn = urlParamTypeToFunctionMap[entry.type];
    return fn(key, entry.defaultValue);
  };
};