/**
 * A list of MapBox layers that are used in the map.
 */

export const mapboxLayers = [
  {
    section: "Delområder",
    translationKey: "MAP_LAYER_LOCAL_PLAN_SUB_AREA",
    icon: "map",
    layer: [
      {
        id: "lokalplandelomraade-vedtaget-v",
        name: "Lokalplan delområde vedtaget",
        translationKey: "MAPBPOX_LOCAL_PLAN_SUB_AREA_ADOPTED",
        layerKey: "anvendelsegenerel",
        hasLegend: true,
        legend: {
          header: {
            translationKey: "MAPBPOX_LOCAL_PLAN_SUB_AREA_ADOPTED",
            color: "--color-black-900",
          },
          items: [
            { translationKey: "MAPBOX_RESIDENTIAL_AREA", color: "hsla(30, 96%, 55%, 0.6)" },
            { translationKey: "MAPBOX_MIXED_HOUSING_AND_OCCUPATION", color: "hsla(0, 94%, 65%, 0.6)" },
            { translationKey: "MAPBOX_COMMERCIAL_AREA", color: "hsla(180, 38%, 50%, 0.59)" },
            { translationKey: "MAPBOX_CITY_CENTER_AREA", color: "hsla(300, 52%, 38%, 0.6)" },
            { translationKey: "MAPBOX_RECREATIONAL_AREA", color: "hsla(113, 75%, 29%, 0.6)" },
            { translationKey: "MAPBOX_SUMMERHOUSE_AREA", color: "hsla(50, 100%, 53%, 0.6)" },
            { translationKey: "MAPBOX_TECHNICAL_FACILITIES", color: "hsla(217, 94%, 47%, 0.6)" },
            { translationKey: "MAPBOX_AREA_FOR_PUBLIC_PURPOSES", color: "hsla(197, 79%, 19%, 0.6)" },
            { translationKey: "MAPBOX_OTHER", color: "hsla(0, 1%, 45%, 0.6)" },
            { translationKey: "MAPBOX_COMPLEX_AREA", color: "hsla(0, 68%, 77%, 0.6)" },
            { translationKey: "MAPBOX_USAGE_NOT_REGULATED", color: "hsla(320, 84%, 53%, 0.6)" },
          ],
        },
      },
      {
        id: "lokalplandelomraade-forslag-v",
        name: "Lokalplan delområde forslag",
        translationKey: "MAPBPOX_LOCAL_PLAN_SUB_AREA_PROPOSAL",
        layerKey: "anvendelsegenerel",
        hasLegend: true,
        legend: {
          header: {
            translationKey: "MAPBPOX_LOCAL_PLAN_SUB_AREA_PROPOSAL",
            color: "--color-black-900",
          },
          items: [
            { translationKey: "MAPBOX_RESIDENTIAL_AREA", color: "hsla(30, 96%, 55%, 0.6)" },
            { translationKey: "MAPBOX_MIXED_HOUSING_AND_OCCUPATION", color: "hsla(0, 94%, 65%, 0.6)" },
            { translationKey: "MAPBOX_COMMERCIAL_AREA", color: "hsla(180, 38%, 50%, 0.59)" },
            { translationKey: "MAPBOX_CITY_CENTER_AREA", color: "hsla(300, 52%, 38%, 0.6)" },
            { translationKey: "MAPBOX_RECREATIONAL_AREA", color: "hsla(113, 75%, 29%, 0.6)" },
            { translationKey: "MAPBOX_SUMMERHOUSE_AREA", color: "hsla(50, 100%, 53%, 0.6)" },
            { translationKey: "MAPBOX_TECHNICAL_FACILITIES", color: "hsla(217, 94%, 47%, 0.6)" },
            { translationKey: "MAPBOX_AREA_FOR_PUBLIC_PURPOSES", color: "hsla(197, 79%, 19%, 0.6)" },
            { translationKey: "MAPBOX_OTHER", color: "hsla(0, 1%, 45%, 0.6)" },
            { translationKey: "MAPBOX_COMPLEX_AREA", color: "hsla(0, 68%, 77%, 0.6)" },
            { translationKey: "MAPBOX_USAGE_NOT_REGULATED", color: "hsla(320, 84%, 53%, 0.6)" },
          ],
        },
      },
      {
        id: "lokalplandelomraade-aflyst",
        name: "Lokalplan delområde aflyst",
        translationKey: "MAPBPOX_LOCAL_PLAN_SUB_AREA_CANCELLED",
        layerKey: "anvendelsegenerel",
        hasLegend: true,
        legend: {
          header: {
            translationKey: "MAPBPOX_LOCAL_PLAN_SUB_AREA_CANCELLED",
            color: "--color-black-900",
          },
          items: [
            { translationKey: "MAPBOX_RESIDENTIAL_AREA", color: "hsla(30, 96%, 55%, 0.6)" },
            { translationKey: "MAPBOX_MIXED_HOUSING_AND_OCCUPATION", color: "hsla(0, 94%, 65%, 0.6)" },
            { translationKey: "MAPBOX_COMMERCIAL_AREA", color: "hsla(180, 38%, 50%, 0.59)" },
            { translationKey: "MAPBOX_CITY_CENTER_AREA", color: "hsla(300, 52%, 38%, 0.6)" },
            { translationKey: "MAPBOX_RECREATIONAL_AREA", color: "hsla(113, 75%, 29%, 0.6)" },
            { translationKey: "MAPBOX_SUMMERHOUSE_AREA", color: "hsla(50, 100%, 53%, 0.6)" },
            { translationKey: "MAPBOX_TECHNICAL_FACILITIES", color: "hsla(217, 94%, 47%, 0.6)" },
            { translationKey: "MAPBOX_AREA_FOR_PUBLIC_PURPOSES", color: "hsla(197, 79%, 19%, 0.6)" },
            { translationKey: "MAPBOX_OTHER", color: "hsla(0, 1%, 45%, 0.6)" },
            { translationKey: "MAPBOX_COMPLEX_AREA", color: "hsla(0, 68%, 77%, 0.6)" },
            { translationKey: "MAPBOX_USAGE_NOT_REGULATED", color: "hsla(320, 84%, 53%, 0.6)" },
          ],
        },
      },
    ],
  },

  {
    section: "Lokalplaner",
    translationKey: "MAP_LAYER_LOCAL_PLAN",
    layerKey: "anvendelsegenerel",
    icon: "map",
    layer: [
      {
        id: "lokalplan-vedtaget-v",
        name: "Lokalplan vedtaget",
        translationKey: "MAPBPOX_LOCAL_PLAN_ADOPTED",
        layerKey: "anvendelsegenerel",
        hasLegend: true,
        legend: {
          header: {
            translationKey: "MAPBPOX_LOCAL_PLAN_ADOPTED",
            color: "--color-black-900",
          },
          items: [
            { translationKey: "MAPBOX_RESIDENTIAL_AREA", color: "hsla(30, 96%, 55%, 0.6)" },
            { translationKey: "MAPBOX_MIXED_HOUSING_AND_OCCUPATION", color: "hsla(0, 94%, 65%, 0.6)" },
            { translationKey: "MAPBOX_COMMERCIAL_AREA", color: "hsla(180, 38%, 50%, 0.59)" },
            { translationKey: "MAPBOX_CITY_CENTER_AREA", color: "hsla(300, 52%, 38%, 0.6)" },
            { translationKey: "MAPBOX_RECREATIONAL_AREA", color: "hsla(113, 75%, 29%, 0.6)" },
            { translationKey: "MAPBOX_SUMMERHOUSE_AREA", color: "hsla(50, 100%, 53%, 0.6)" },
            { translationKey: "MAPBOX_TECHNICAL_FACILITIES", color: "hsla(217, 94%, 47%, 0.6)" },
            { translationKey: "MAPBOX_AREA_FOR_PUBLIC_PURPOSES", color: "hsla(197, 79%, 19%, 0.6)" },
            { translationKey: "MAPBOX_OTHER", color: "hsla(0, 1%, 45%, 0.6)" },
            { translationKey: "MAPBOX_COMPLEX_AREA", color: "hsla(0, 68%, 77%, 0.6)" },
            { translationKey: "MAPBOX_USAGE_NOT_REGULATED", color: "hsla(320, 84%, 53%, 0.6)" },
            { translationKey: "MAPBOX_LAND_AREA", color: "hsla(121, 86%, 21%, 0.65)" },
          ],
        },
      },
      {
        id: "lokalplan-forslag-v",
        name: "Lokalplan forslag",
        translationKey: "MAPBPOX_LOCAL_PLAN_PROPOSAL",
        layerKey: "anvendelsegenerel",
        hasLegend: true,
        legend: {
          header: {
            translationKey: "MAPBPOX_LOCAL_PLAN_PROPOSAL",
            color: "--color-black-900",
          },
          items: [
            { translationKey: "MAPBOX_RESIDENTIAL_AREA", color: "hsla(30, 96%, 55%, 0.6)" },
            { translationKey: "MAPBOX_MIXED_HOUSING_AND_OCCUPATION", color: "hsla(0, 94%, 65%, 0.6)" },
            { translationKey: "MAPBOX_COMMERCIAL_AREA", color: "hsla(180, 38%, 50%, 0.59)" },
            { translationKey: "MAPBOX_CITY_CENTER_AREA", color: "hsla(300, 52%, 38%, 0.6)" },
            { translationKey: "MAPBOX_RECREATIONAL_AREA", color: "hsla(113, 75%, 29%, 0.6)" },
            { translationKey: "MAPBOX_SUMMERHOUSE_AREA", color: "hsla(50, 100%, 53%, 0.6)" },
            { translationKey: "MAPBOX_TECHNICAL_FACILITIES", color: "hsla(217, 94%, 47%, 0.6)" },
            { translationKey: "MAPBOX_AREA_FOR_PUBLIC_PURPOSES", color: "hsla(197, 79%, 19%, 0.6)" },
            { translationKey: "MAPBOX_OTHER", color: "hsla(0, 1%, 45%, 0.6)" },
            { translationKey: "MAPBOX_COMPLEX_AREA", color: "hsla(0, 68%, 77%, 0.6)" },
            { translationKey: "MAPBOX_USAGE_NOT_REGULATED", color: "hsla(320, 84%, 53%, 0.6)" },
            { translationKey: "MAPBOX_LAND_AREA", color: "hsla(121, 86%, 21%, 0.65)" },
          ],
        },
      },
      {
        id: "lokalplan-aflyst",
        name: "Lokalplan aflyst",
        translationKey: "MAPBPOX_LOCAL_PLAN_CANCELLED",
        layerKey: "anvendelsegenerel",
        hasLegend: true,
        legend: {
          header: {
            translationKey: "MAPBPOX_LOCAL_PLAN_CANCELLED",
            color: "--color-black-900",
          },
          items: [
            { translationKey: "MAPBOX_RESIDENTIAL_AREA", color: "hsla(30, 96%, 55%, 0.6)" },
            { translationKey: "MAPBOX_MIXED_HOUSING_AND_OCCUPATION", color: "hsla(0, 94%, 65%, 0.6)" },
            { translationKey: "MAPBOX_COMMERCIAL_AREA", color: "hsla(180, 38%, 50%, 0.59)" },
            { translationKey: "MAPBOX_CITY_CENTER_AREA", color: "hsla(300, 52%, 38%, 0.6)" },
            { translationKey: "MAPBOX_RECREATIONAL_AREA", color: "hsla(113, 75%, 29%, 0.6)" },
            { translationKey: "MAPBOX_SUMMERHOUSE_AREA", color: "hsla(50, 100%, 53%, 0.6)" },
            { translationKey: "MAPBOX_TECHNICAL_FACILITIES", color: "hsla(217, 94%, 47%, 0.6)" },
            { translationKey: "MAPBOX_AREA_FOR_PUBLIC_PURPOSES", color: "hsla(197, 79%, 19%, 0.6)" },
            { translationKey: "MAPBOX_OTHER", color: "hsla(0, 1%, 45%, 0.6)" },
            { translationKey: "MAPBOX_COMPLEX_AREA", color: "hsla(0, 68%, 77%, 0.6)" },
            { translationKey: "MAPBOX_USAGE_NOT_REGULATED", color: "hsla(320, 84%, 53%, 0.6)" },
            { translationKey: "MAPBOX_LAND_AREA", color: "hsla(121, 86%, 21%, 0.65)" },
          ],
        },
      },
    ],
  },
  {
    section: "Kommuneplaner",
    translationKey: "MAPBOX_MUNICIPALITY_PLAN",
    layerKey: "anvendelsegenerel",
    icon: "map",
    layer: [
      {
        id: "kommuneplanramme-vedtaget-v",
        name: "Kommuneplanramme vedtaget",
        translationKey: "MAPBPOX_MUNICIPALITY_PLAN_ADOPTED",
        hasLegend: true,
        legend: {
          header: {
            translationKey: "MAPBPOX_MUNICIPALITY_PLAN_ADOPTED",
            color: "--color-black-900",
          },
          items: [
            { translationKey: "MAPBOX_RESIDENTIAL_AREA", color: "hsla(36, 100%, 51%, 0.6)" },
            { translationKey: "MAPBOX_MIXED_HOUSING_AND_OCCUPATION", color: "hsla(0, 98%, 52%, 0.6)" },
            { translationKey: "MAPBOX_COMMERCIAL_AREA", color: "hsla(180, 38%, 50%, 0.59)" },
            { translationKey: "MAPBOX_CITY_CENTER_AREA", color: "hsla(300, 69%, 39%, 0.6)" },
            { translationKey: "MAPBOX_RECREATIONAL_AREA", color: "hsla(113, 90%, 47%, 0.6)" },
            { translationKey: "MAPBOX_SUMMERHOUSE_AREA", color: "hsla(50, 100%, 53%, 0.6)" },
            { translationKey: "MAPBOX_TECHNICAL_FACILITIES", color: "hsla(217, 94%, 47%, 0.6)" },
            { translationKey: "MAPBOX_AREA_FOR_PUBLIC_PURPOSES", color: "hsla(197, 79%, 19%, 0.6)" },
            { translationKey: "MAPBOX_OTHER", color: "hsla(0, 1%, 49%, 0.6)" },
            { translationKey: "MAPBOX_COMPLEX_AREA", color: "hsla(0, 68%, 77%, 0.6)" },
            { translationKey: "MAPBOX_USAGE_NOT_REGULATED", color: "hsla(320, 84%, 53%, 0.6)" },
            { translationKey: "MAPBOX_LAND_AREA", color: "hsla(121, 86%, 21%, 0.65)" },
          ],
        },
      },
      {
        id: "kommuneplanramme-forslag-v",
        name: "Kommuneplanramme forslag",
        translationKey: "MAPBPOX_MUNICIPALITY_PLAN_PROPOSAL",
        layerKey: "anvendelsegenerel",
        hasLegend: true,
        legend: {
          header: {
            translationKey: "MAPBPOX_MUNICIPALITY_PLAN_PROPOSAL",
            color: "--color-black-900",
          },
          items: [
            { translationKey: "MAPBOX_RESIDENTIAL_AREA", color: "hsla(36, 100%, 51%, 0.6)" },
            { translationKey: "MAPBOX_MIXED_HOUSING_AND_OCCUPATION", color: "hsla(0, 98%, 52%, 0.6)" },
            { translationKey: "MAPBOX_COMMERCIAL_AREA", color: "hsla(180, 38%, 50%, 0.59)" },
            { translationKey: "MAPBOX_CITY_CENTER_AREA", color: "hsla(300, 69%, 39%, 0.6)" },
            { translationKey: "MAPBOX_RECREATIONAL_AREA", color: "hsla(113, 90%, 47%, 0.6)" },
            { translationKey: "MAPBOX_SUMMERHOUSE_AREA", color: "hsla(50, 100%, 53%, 0.6)" },
            { translationKey: "MAPBOX_TECHNICAL_FACILITIES", color: "hsla(217, 94%, 47%, 0.6)" },
            { translationKey: "MAPBOX_AREA_FOR_PUBLIC_PURPOSES", color: "hsla(197, 79%, 19%, 0.6)" },
            { translationKey: "MAPBOX_OTHER", color: "hsla(0, 1%, 49%, 0.6)" },
            { translationKey: "MAPBOX_COMPLEX_AREA", color: "hsla(0, 68%, 77%, 0.6)" },
            { translationKey: "MAPBOX_USAGE_NOT_REGULATED", color: "hsla(320, 84%, 53%, 0.6)" },
            { translationKey: "MAPBOX_LAND_AREA", color: "hsla(121, 86%, 21%, 0.65)" },
          ],
        },
      },
      {
        id: "kommuneplanramme-aflyst",
        name: "Kommuneplanramme aflyst",
        translationKey: "MAPBPOX_MUNICIPALITY_PLAN_CANCELLED",
        layerKey: "anvendelsegenerel",
        hasLegend: true,
        legend: {
          header: {
            translationKey: "MAPBPOX_MUNICIPALITY_PLAN_CANCELLED",
            color: "--color-black-900",
          },
          items: [
            { translationKey: "MAPBOX_RESIDENTIAL_AREA", color: "hsla(36, 100%, 51%, 0.6)" },
            { translationKey: "MAPBOX_MIXED_HOUSING_AND_OCCUPATION", color: "hsla(0, 98%, 52%, 0.6)" },
            { translationKey: "MAPBOX_COMMERCIAL_AREA", color: "hsla(180, 38%, 50%, 0.59)" },
            { translationKey: "MAPBOX_CITY_CENTER_AREA", color: "hsla(300, 69%, 39%, 0.6)" },
            { translationKey: "MAPBOX_RECREATIONAL_AREA", color: "hsla(113, 90%, 47%, 0.6)" },
            { translationKey: "MAPBOX_SUMMERHOUSE_AREA", color: "hsla(50, 100%, 53%, 0.6)" },
            { translationKey: "MAPBOX_TECHNICAL_FACILITIES", color: "hsla(217, 94%, 47%, 0.6)" },
            { translationKey: "MAPBOX_AREA_FOR_PUBLIC_PURPOSES", color: "hsla(197, 79%, 19%, 0.6)" },
            { translationKey: "MAPBOX_OTHER", color: "hsla(0, 1%, 49%, 0.6)" },
            { translationKey: "MAPBOX_COMPLEX_AREA", color: "hsla(0, 68%, 77%, 0.6)" },
            { translationKey: "MAPBOX_USAGE_NOT_REGULATED", color: "hsla(320, 84%, 53%, 0.6)" },
            { translationKey: "MAPBOX_LAND_AREA", color: "hsla(121, 86%, 21%, 0.65)" },
          ],
        },
      },
    ],
  },
  {
    section: "Byggefelt",
    translationKey: "MAPBOX_BUILDING_PLOT",
    icon: "construction",
    layer: [
      {
        id: "byggefelt-vedtaget",
        name: "Byggefelt vedtaget",
        translationKey: "MAPBOX_BUILDING_PLOT_ADOPTED",
        layerKey: null,
        hasLegend: false,
      },
      {
        id: "byggefelt-forslag",
        name: "Byggefelt forslag",
        translationKey: "MAPBOX_BUILDING_PLOT_PROPOSAL",
        layerKey: null,
        hasLegend: false,
      },
      {
        id: "byggefelt-aflyst",
        name: "Byggefelt aflyst",
        translationKey: "MAPBOX_BUILDING_PLOT_CANCELLED",
        layerKey: null,
        hasLegend: false,
      },
    ],
  },
  {
    section: "Planlagttrafikanlæg",
    translationKey: "MAPBOX_PLANNED_TRAFFIC_SYSTEM",
    icon: "route",
    layer: [
      {
        id: "planlagttrafikanlaeg-vedtaget-v-line",
        name: "Planlagttrafikanlæg vedtaget",
        translationKey: "MAPBPOX_PLANNED_TRAFFIC_SYSTEM_ADOPTED",
        layerKey: "vaerdi1105",
        hasLegend: true,
        legend: {
          header: {
            translationKey: "MAPBPOX_PLANNED_TRAFFIC_SYSTEM_ADOPTED",
            color: "--color-black-900",
          },
          items: [
            { translationKey: "MAPBOX_PARKING_LOTS", color: "hsl(146, 87%, 22%)" },
            { translationKey: "MAPBOX_TRANSPORT_CORRIDORS", color: "hsla(255, 60%, 45%, 0.6)" },
            { translationKey: "MAPBOX_TRAINSTATIONS_AND_RANGER_AREAS", color: "hsla(41, 82%, 53%, 0.6)" },
            { translationKey: "MAPBOX_AIRPORTS", color: "#8d8d8d" },
            { translationKey: "MAPBOX_PORTS", color: "#254fff" },
            { translationKey: "MAPBOX_RAILROADS", color: "#3d3d3d" },
            { translationKey: "MAPBOX_PATH_SYSTEMS", color: "#1b7e16" },
            { translationKey: "MAPBOX_ROADS", color: "hsl(0, 92%, 38%)" },
          ],
        },
      },
      {
        id: "planlagttrafikanlaeg-forslag-v-line",
        name: "Planlagttrafikanlæg forslag",
        translationKey: "MAPBPOX_PLANNED_TRAFFIC_SYSTEM_PROPOSAL",
        layerKey: "vaerdi1105",
        hasLegend: true,
        legend: {
          header: {
            translationKey: "MAPBPOX_PLANNED_TRAFFIC_SYSTEM_PROPOSAL",
            color: "--color-black-900",
          },
          items: [
            { translationKey: "MAPBOX_PARKING_LOTS", color: "hsl(146, 87%, 22%)" },
            { translationKey: "MAPBOX_TRANSPORT_CORRIDORS", color: "hsla(255, 60%, 45%, 0.6)" },
            { translationKey: "MAPBOX_TRAINSTATIONS_AND_RANGER_AREAS", color: "hsla(41, 82%, 53%, 0.6)" },
            { translationKey: "MAPBOX_AIRPORTS", color: "#8d8d8d" },
            { translationKey: "MAPBOX_PORTS", color: "#254fff" },
            { translationKey: "MAPBOX_RAILROADS", color: "#3d3d3d" },
            { translationKey: "MAPBOX_PATH_SYSTEMS", color: "#1b7e16" },
            { translationKey: "MAPBOX_ROADS", color: "hsl(0, 92%, 38%)" },
          ],
        },
      },
      {
        id: "planlagttrafikanlaeg-aflyst-v-line",
        name: "Planlagttrafikanlæg aflyst",
        translationKey: "MAPBPOX_PLANNED_TRAFFIC_SYSTEM_CANCELLED",
        layerKey: "vaerdi1105",
        hasLegend: true,
        legend: {
          header: {
            translationKey: "MAPBPOX_PLANNED_TRAFFIC_SYSTEM_CANCELLED",
            color: "--color-black-900",
          },
          items: [
            { translationKey: "MAPBOX_PARKING_LOTS", color: "hsl(146, 87%, 22%)" },
            { translationKey: "MAPBOX_TRANSPORT_CORRIDORS", color: "hsla(255, 60%, 45%, 0.6)" },
            { translationKey: "MAPBOX_TRAINSTATIONS_AND_RANGER_AREAS", color: "hsla(41, 82%, 53%, 0.6)" },
            { translationKey: "MAPBOX_AIRPORTS", color: "#8d8d8d" },
            { translationKey: "MAPBOX_PORTS", color: "#254fff" },
            { translationKey: "MAPBOX_RAILROADS", color: "#3d3d3d" },
            { translationKey: "MAPBOX_PATH_SYSTEMS", color: "#1b7e16" },
            { translationKey: "MAPBOX_ROADS", color: "hsl(0, 92%, 38%)" },
          ],
        },
      },
    ],
  },
  {
    section: "Detailhandel",
    translationKey: "MAPBPOX_RETAIL_ZONE",
    icon: "shopping_cart",
    layer: [
      {
        id: "detailhandel-vedtaget-v",
        name: "Detailhandel vedtaget",
        translationKey: "MAPBPOX_RETAIL_ZONE_ADOPTED",
        layerKey: "type1103",
        hasLegend: true,
        legend: {
          header: {
            translationKey: "MAPBPOX_RETAIL_ZONE_ADOPTED",
            color: "--color-black-900",
          },
          items: [
            { translationKey: "MAPBOX_CITY_CENTER", color: "hsla(36, 100%, 51%, 0.6)" },
            { translationKey: "MAPBOX_DISTRICT_CENTER", color: "hsla(0, 98%, 52%, 0.6)" },
            { translationKey: "MAPBOX_RELIEF_AREA", color: "hsla(180, 38%, 50%, 0.59)" },
            { translationKey: "MAPBOX_LOCAL_CENTER", color: "hsla(300, 69%, 39%, 0.6)" },
            { translationKey: "MAPBOX_AREA_FOR_SHOPS_WITH_PARTICULARLY_SPACE_CONSUMING_WARES", color: "hsla(113, 90%, 47%, 0.6)" },
            { translationKey: "MAPBOX_SINGULAR_SHOPS", color: "hsla(50, 100%, 53%, 0.6)" },
          ],
        },
      },
      {
        id: "detailhandel-forslag-v",
        name: "Detailhandel forslag",
        translationKey: "MAPBPOX_RETAIL_ZONE_PROPOSAL",
        layerKey: "vaerdi1105",
        hasLegend: true,
        legend: {
          header: {
            translationKey: "MAPBPOX_RETAIL_ZONE_PROPOSAL",
            color: "--color-black-900",
          },
          items: [
            { translationKey: "MAPBOX_CITY_CENTER", color: "hsla(36, 100%, 51%, 0.6)" },
            { translationKey: "MAPBOX_DISTRICT_CENTER", color: "hsla(0, 98%, 52%, 0.6)" },
            { translationKey: "MAPBOX_RELIEF_AREA", color: "hsla(180, 38%, 50%, 0.59)" },
            { translationKey: "MAPBOX_LOCAL_CENTER", color: "hsla(300, 69%, 39%, 0.6)" },
            { translationKey: "MAPBOX_AREA_FOR_SHOPS_WITH_PARTICULARLY_SPACE_CONSUMING_WARES", color: "hsla(113, 90%, 47%, 0.6)" },
            { translationKey: "MAPBOX_SINGULAR_SHOPS", color: "hsla(50, 100%, 53%, 0.6)" },
          ],
        },
      },
      {
        id: "detailhandel-aflyst",
        name: "Detailhandel aflyst",
        translationKey: "MAPBPOX_RETAIL_ZONE_CANCELLED",
        layerKey: "vaerdi1105",
        hasLegend: true,
        legend: {
          header: {
            translationKey: "MAPBPOX_RETAIL_ZONE_CANCELLED",
            color: "--color-black-900",
          },
          items: [
            { translationKey: "MAPBOX_CITY_CENTER", color: "hsla(36, 100%, 51%, 0.6)" },
            { translationKey: "MAPBOX_DISTRICT_CENTER", color: "hsla(0, 98%, 52%, 0.6)" },
            { translationKey: "MAPBOX_RELIEF_AREA", color: "hsla(180, 38%, 50%, 0.59)" },
            { translationKey: "MAPBOX_LOCAL_CENTER", color: "hsla(300, 69%, 39%, 0.6)" },
            { translationKey: "MAPBOX_AREA_FOR_SHOPS_WITH_PARTICULARLY_SPACE_CONSUMING_WARES", color: "hsla(113, 90%, 47%, 0.6)" },
            { translationKey: "MAPBOX_SINGULAR_SHOPS", color: "hsla(50, 100%, 53%, 0.6)" },
          ],
        },
      },
    ],
  },
  {
    section: "Økologiske forbindelser",
    translationKey: "MAPBOX_ECOLOGICAL_LINKS",
    icon: "eco",
    layer: [
      {
        id: "oekologiskforbindelse-vedtaget",
        name: "Økologiske forbindelser vedtaget",
        translationKey: "MAPBOX_ECOLOGICAL_LINKS_ADOPTED",
        layerKey: "vaerdi1115",
        hasLegend: true,
        legend: {
          header: {
            translationKey: "MAPBOX_ECOLOGICAL_LINKS_ADOPTED",
            color: "--color-black-900",
          },
          items: [
            { translationKey: "MAPBOX_ECOLOGICAL_LINKS_ADOPTED", color: "hsla(30, 96%, 55%, 0.6)" },
            { translationKey: "MAPBOX_POTENTIAL_ECOLOGICAL_LINKS", color: "hsla(0, 94%, 65%, 0.6)" },
          ],
        },
      },
      {
        id: "oekologiskforbindelse-forslag",
        name: "Økologiske forbindelser forslag",
        translationKey: "MAPBOX_ECOLOGICAL_LINKS_PROPOSED",
        layerKey: "vaerdi1115",
        hasLegend: true,
        legend: {
          header: {
            translationKey: "MAPBOX_ECOLOGICAL_LINKS_PROPOSED",
            color: "--color-black-900",
          },
          items: [
            { translationKey: "MAPBOX_ECOLOGICAL_LINKS_PROPOSED", color: "hsla(30, 96%, 55%, 0.6)" },
            { translationKey: "MAPBOX_POTENTIAL_ECOLOGICAL_LINKS", color: "hsla(0, 94%, 65%, 0.6)" },
          ],
        },
      },
      {
        id: "oekologiskforbindelse-aflyst",
        name: "Økologiske forbindelser aflyst",
        translationKey: "MAPBOX_ECOLOGICAL_LINKS_CANCELLED",
        layerKey: "vaerdi1115",
        hasLegend: true,
        legend: {
          header: {
            translationKey: "MAPBOX_ECOLOGICAL_LINKS_CANCELLED",
            color: "--color-black-900",
          },
          items: [
            { translationKey: "MAPBOX_ECOLOGICAL_LINKS_CANCELLED", color: "hsla(30, 96%, 55%, 0.6)" },
            { translationKey: "MAPBOX_POTENTIAL_ECOLOGICAL_LINKS_CANCELLED", color: "hsla(0, 94%, 65%, 0.6)" },
          ],
        },
      },
    ],
  },
  {
    section: "Byzone",
    translationKey: "MAPBOX_URBAN_ZONE",
    icon: "location_city",
    layer: [
      {
        id: "byzonesommerhusomraade-vedtaget-v",
        name: "Arealer til byzone og sommerhus vedtaget",
        translationKey: "MAPBPOX_AREAS_FOR_URBAN_ZONE_AND_SUMMERHOUSE_ADOPTED",
        layerKey: "type1101",
        hasLegend: true,
        legend: {
          header: {
            translationKey: "MAPBPOX_AREAS_FOR_URBAN_ZONE_AND_SUMMERHOUSE_ADOPTED",
            color: "--color-black-900",
          },
          items: [
            { translationKey: "MAPBOX_FUTURE_URBAN_ZONE_WITHIN_MUNICIPALITY_PLAN", color: "hsla(0, 97%, 47%, 0.6)" },
            { translationKey: "MAPBOX_POTENTIAL_FUTURE_AREA_FOR_URBAN_ZONE", color: "hsla(0, 100%, 42%, 0.7)" },
            { translationKey: "MAPBOX_FUTURE_REVERSAL_OF_URBAN_ZONE_TO_LAND_ZONE", color: "hsla(45, 97%, 49%, 0.7)" },
            { translationKey: "MAPBOX_FUTURE_REVERSAL_OF_SUMMERHOUSE_AREA_FOR_LAND_ZONE", color: "hsla(124, 86%, 24%, 0.7)" },
            { translationKey: "MAPBOX_FUTURE_COTTAGE_AREA_WITHIN_MUNICIPALITY_PLAN", color: "hsla(45, 97%, 49%, 0.7)" },
            { translationKey: "MAPBOX_POTENTIAL_FUTURE_SUMMERHOUSE_AREA", color: "hsla(45, 97%, 49%, 0.7)" },
          ],
        },
      },
      {
        id: "byzonesommerhusomraade-forslag-v",
        name: "Arealer til byzone og sommerhus forslag",
        translationKey: "MAPBPOX_AREAS_FOR_URBAN_ZONE_AND_SUMMERHOUSE_PROPOSAL",
        layerKey: "type1101",
        hasLegend: true,
        legend: {
          header: {
            translationKey: "MAPBPOX_AREAS_FOR_URBAN_ZONE_AND_SUMMERHOUSE_PROPOSAL",
            color: "--color-black-900",
          },
          items: [
            { translationKey: "MAPBOX_FUTURE_URBAN_ZONE_WITHIN_MUNICIPALITY_PLAN", color: "hsla(0, 97%, 47%, 0.6)" },
            { translationKey: "MAPBOX_POTENTIAL_FUTURE_AREA_FOR_URBAN_ZONE", color: "hsla(0, 100%, 42%, 0.7)" },
            { translationKey: "MAPBOX_FUTURE_REVERSAL_OF_URBAN_ZONE_TO_LAND_ZONE", color: "hsla(45, 97%, 49%, 0.7)" },
            { translationKey: "MAPBOX_FUTURE_REVERSAL_OF_SUMMERHOUSE_AREA_FOR_LAND_ZONE", color: "hsla(124, 86%, 24%, 0.7)" },
            { translationKey: "MAPBOX_FUTURE_COTTAGE_AREA_WITHIN_MUNICIPALITY_PLAN", color: "hsla(45, 97%, 49%, 0.7)" },
            { translationKey: "MAPBOX_POTENTIAL_FUTURE_SUMMERHOUSE_AREA", color: "hsla(45, 97%, 49%, 0.7)" },
          ],
        },
      },
      {
        id: "byzonesommerhusomraade-aflyst-v",
        name: "Arealer til byzone og sommerhus aflyst",
        translationKey: "MAPBPOX_AREAS_FOR_URBAN_ZONE_AND_SUMMERHOUSE_CANCELLED",
        layerKey: "type1101",
        hasLegend: true,
        legend: {
          header: {
            translationKey: "MAPBPOX_AREAS_FOR_URBAN_ZONE_AND_SUMMERHOUSE_CANCELLED",
            color: "--color-black-900",
          },
          items: [
            { translationKey: "MAPBOX_FUTURE_URBAN_ZONE_WITHIN_MUNICIPALITY_PLAN", color: "hsla(0, 97%, 47%, 0.6)" },
            { translationKey: "MAPBOX_POTENTIAL_FUTURE_AREA_FOR_URBAN_ZONE", color: "hsla(0, 100%, 42%, 0.7)" },
            { translationKey: "MAPBOX_FUTURE_REVERSAL_OF_URBAN_ZONE_TO_LAND_ZONE", color: "hsla(45, 97%, 49%, 0.7)" },
            { translationKey: "MAPBOX_FUTURE_REVERSAL_OF_SUMMERHOUSE_AREA_FOR_LAND_ZONE", color: "hsla(124, 86%, 24%, 0.7)" },
            { translationKey: "MAPBOX_FUTURE_COTTAGE_AREA_WITHIN_MUNICIPALITY_PLAN", color: "hsla(45, 97%, 49%, 0.7)" },
            { translationKey: "MAPBOX_POTENTIAL_FUTURE_SUMMERHOUSE_AREA", color: "hsla(45, 97%, 49%, 0.7)" },
          ],
        },
      },
    ],
  },
  {
    section: "Fredninger",
    translationKey: "MAPBOX_PROTECTION",
    icon: "castle",
    layer: [
      {
        id: "fredede-omr",
        name: "Fredede områder",
        translationKey: "MAPBOX_PROTECTED_AREAS",
        layerKey: null,
        hasLegend: false,
      },
      {
        id: "fredede-omr-bk",
        name: "Bekendtgørelsesfredninger",
        translationKey: "MAPBOX_PROCLAMATION_PROTECTIONS",
        layerKey: null,
        hasLegend: false,
      },
      {
        id: "kirkebyggelinjer",
        name: "Kirkebyggelinjer",
        translationKey: "MAPBOX_CHURCH_BUILDING_LINES",
        layerKey: null,
        hasLegend: false,
      },
      {
        id: "bes-naturtyper",
        name: "Beskyttede naturtyper",
        translationKey: "MAPBOX_PROTECTED_NATURE_TYPES",
        layerKey: "Natyp_navn",
        hasLegend: true,
        legend: {
          header: {
            translationKey: "MAPBOX_PROTECTED_NATURE_TYPES",
            color: "--color-black-900",
          },
          items: [
            { translationKey: "MAPBOX_MEADOW", color: "hsla(30, 96%, 55%, 0.6)" },
            { translationKey: "MAPBOX_MOOR", color: "hsla(0, 94%, 65%, 0.6)" },
            { translationKey: "MAPBOX_BOG", color: "hsla(180, 38%, 50%, 0.59)" },
            { translationKey: "MAPBOX_WILDERNESS", color: "hsla(300, 52%, 38%, 0.6)" },
            { translationKey: "MAPBOX_SALT_MEADOW", color: "hsla(113, 75%, 29%, 0.6)" },
            { translationKey: "MAPBOX_LAKE", color: "hsla(50, 100%, 53%, 0.6)" },
          ],
        },
      },
      {
        id: "bes-vandloeb",
        name: "Beskyttede vandløb",
        translationKey: "MAPBOX_PROTECTED_WATERCOURSES",
        layerKey: null,
        hasLegend: false,
      },
      {
        id: "bes-sten-jorddiger",
        name: "Beskyttede sten- og jorddiger",
        translationKey: "MAPBOX_PROTECTED_STONE_AND_EARTH_DIKES",
        layerKey: null,
        hasLegend: false,
      },
      {
        id: "soe-bes-linjer",
        name: "Søbeskyttelseslinjer",
        translationKey: "MAPBOX_SEA_PROTECTION_LINES",
        layerKey: null,
        hasLegend: false,
      },
      {
        id: "aa-bes-linjer",
        name: "Åbeskyttelseslinjer",
        translationKey: "MAPBOX_STREAM_PROTECTION_LINES",
        layerKey: null,
        hasLegend: false,
      },
      {
        id: "skovbyggelinjer",
        name: "Skovbyggelinjer",
        translationKey: "MAPBOX_FOREST_CONSTRUCTION_LINES",
        layerKey: "Skovs_navn",
        hasLegend: true,
        legend: {
          header: {
            translationKey: "MAPBOX_FOREST_CONSTRUCTION_LINES",
            color: "--color-black-900",
          },
          items: [
            { translationKey: "MAPBOX_APPLICABLE", color: "hsla(30, 96%, 55%, 0.6)" },
            { translationKey: "MAPBOX_REPEALED", color: "hsla(0, 94%, 65%, 0.6)" },
          ],
        },
      },
      {
        id: "natur-vildt-reservat",
        name: "Natur- og vildtreservater",
        translationKey: "MAPBOX_NATURE_AND_WILDLIFE_RESERVES",
        layerKey: null,
        hasLegend: false,
      },
    ],
  },
  {
    section: "Slots- og Kulturstyrelsen",
    translationKey: "MAPBOX_THE_PALACES_AND_CULTURE_AGENCY",
    icon: "account_balance",
    layer: [
      {
        id: "view-bygning-fredede",
        name: "Fredede bygninger",
        translationKey: "MAPBOX_PRESERVED_BUILDINGS",
        layerKey: null,
        hasLegend: false,
      },
      {
        id: "view-bygning-bevaringssagview",
        name: "Bygninger med bevaringssag",
        translationKey: "MAPBOX_BUILDINGS_WITH_CONSERVATION_CASE",
        layerKey: null,
        hasLegend: false,
      },
      {
        id: "fundogfortidsminder-areal-fredet",
        name: "Fortidsminde fredet (areal)",
        translationKey: "MAPBOX_HISTORIC_MONUMENT_PROTECTED_AREA",
        layerKey: null,
        hasLegend: false,
      },
    ],
  },
  {
    section: "Øvrige",
    translationKey: "MAPBOX_OTHERS",
    icon: "list_alt",
    layer: [
      {
        id: "naturbeskyttelsesomraade-vedtaget",
        name: "Naturbeskyttelsesområder vedtaget",
        translationKey: "MAPBOX_NATURE_CONSERVATION_AREAS_ADOPTED",
        layerKey: "vaerdi1114",
        hasLegend: true,
        legend: {
          header: {
            translationKey: "MAPBOX_NATURE_CONSERVATION_AREAS_ADOPTED",
            color: "--color-black-900",
          },
          items: [
            { translationKey: "MAPBOX_NATURE_CONSERVATION_INTERESTS_ADOPTED", color: "hsla(30, 96%, 55%, 0.6)" },
            { translationKey: "MAPBOX_POTENTIAL_NATURE_CONSERVATION_INTERESTS_ADOPTED", color: "hsla(0, 94%, 65%, 0.6)" },
          ],
        },
      },
      {
        id: "naturbeskyttelsesomraade-forslag",
        name: "Naturbeskyttelsesområder forslag",
        translationKey: "MAPBOX_NATURE_CONSERVATION_AREAS_PROPOSAL",
        layerKey: "vaerdi1114",
        hasLegend: true,
        legend: {
          header: {
            translationKey: "MAPBOX_NATURE_CONSERVATION_AREAS_PROPOSAL",
            color: "--color-black-900",
          },
          items: [
            { translationKey: "MAPBOX_NATURE_CONSERVATION_INTERESTS_PROPOSAL", color: "hsla(30, 96%, 55%, 0.6)" },
            { translationKey: "MAPBOX_POTENTIAL_NATURE_CONSERVATION_INTERESTS_PROPOSAL", color: "hsla(0, 94%, 65%, 0.6)" },
          ],
        },
      },
      {
        id: "naturbeskyttelsesomraade-aflyst",
        name: "Naturbeskyttelsesområder aflyst",
        translationKey: "MAPBOX_NATURE_CONSERVATION_AREAS_CANCELLED",
        layerKey: "vaerdi1114",
        hasLegend: true,
        legend: {
          header: {
            translationKey: "MAPBOX_NATURE_CONSERVATION_AREAS_CANCELLED",
            color: "--color-black-900",
          },
          items: [
            { translationKey: "MAPBOX_NATURE_CONSERVATION_INTERESTS_CANCELLED", color: "hsla(30, 96%, 55%, 0.6)" },
            { translationKey: "MAPBOX_POTENTIAL_NATURE_CONSERVATION_INTERESTS_CANCELLED", color: "hsla(0, 94%, 65%, 0.6)" },
          ],
        },
      },
    ],
  },
  {
    section: "GeoDanmark - Natur",
    translationKey: "MAPBOX_GEODANMARK_NATURE",
    icon: "list_alt",
    layer: [
      {
        id: "Brugsgraense",
        name: "Brugsgrænse",
        translationKey: "MAPBOX_USAGE_LIMIT",
        layerKey: null,
        hasLegend: false,
      },
      {
        id: "Daemning",
        name: "Dæmning",
        translationKey: "MAPBOX_DAMN",
        layerKey: null,
        hasLegend: false,
      },
      {
        id: "Dige",
        name: "Dige",
        translationKey: "MAPBOX_DIKE",
        layerKey: null,
        hasLegend: false,
      },
      {
        id: "Gartneri",
        name: "Gartneri",
        translationKey: "MAPBOX_GARDENING",
        layerKey: null,
        hasLegend: false,
      },
      {
        id: "Hegn",
        name: "Hegn",
        translationKey: "MAPBOX_FENCE",
        layerKey: null,
        hasLegend: false,
      },
      {
        id: "Skraent",
        name: "Skrænt",
        translationKey: "MAPBOX_SLOPE",
        layerKey: null,
        hasLegend: false,
      },
      {
        id: "Trae",
        name: "Træ",
        translationKey: "MAPBOX_TREE",
        layerKey: null,
        hasLegend: false,
      },
      {
        id: "Vaadomraade",
        name: "Vådområde",
        translationKey: "MAPBOX_WETLAND_AREA",
        layerKey: null,
        hasLegend: false,
      },
    ]
  },
  {
    section: "GeoDanmark - Hydro",
    translationKey: "MAPBOX_GEODANMARK_HYDRO",
    icon: "list_alt",
    layer: [
      {
        id: "Afvandingsgroeft",
        name: "Afvandingsgrøft",
        translationKey: "MAPBOX_DRAINAGE_DITCH",
        layerKey: null,
        hasLegend: false,
      },
      {
        id: "Kyst",
        name: "Kyst",
        translationKey: "MAPBOX_COAST",
        layerKey: null,
        hasLegend: false,
      },
      {
        id: "Vandloebskant",
        name: "Vandløbskant",
        translationKey: "MAPBOX_WATERCOURSE_EDGE",
        layerKey: null,
        hasLegend: false,
      },
      {
        id: "Vandloebsmidte",
        name: "Vandløbsmidte",
        translationKey: "MAPBOX_WATERCOURSE_CENTER",
        layerKey: null,
        hasLegend: false,
      }
    ]
  },
  {
    section: "GeoDanmark - Teknik",
    translationKey: "MAPBOX_ENGINEERING_FACILITIES",
    icon: "list_alt",
    layer: [
      {
        id: "AnlaegDiverse",
        name: "Anlæg diverse",
        translationKey: "MAPBOX_FACILITY_MISC",
        layerKey: null,
        hasLegend: false,
      },
      {
        id: "Bassin",
        name: "Bassin",
        translationKey: "MAPBOX_BASIN",
        layerKey: "bassintype",
        hasLegend: true,
        legend: {
          header: {
            translationKey: "MAPBOX_BASIN",
            "color": "--color-black-900",
          },
          items: [
            { translationKey: "MAPBOX_TREATMENT_PLAN", color: "hsla(36, 100%, 51%, 0.6)" },
            { translationKey: "MAPBOX_RAINWATER_BASIN", color: "hsla(0, 98%, 52%, 0.6)" },
            { translationKey: "MAPBOX_OVERFLOW_BASIN", color: "hsla(180, 38%, 50%, 0.59)" },
            { translationKey: "MAPBOX_POOL", color: "hsla(300, 69%, 39%, 0.6)" },
            { translationKey: "MAPBOX_NOT_ASSIGNED", color: "hsla(113, 90%, 47%, 0.6)" },
            { translationKey: "MAPBOX_MISCELLANEOUS", color: "hsla(50, 100%, 53%, 0.6)" },
            { translationKey: "MAPBOX_TECHNICAL_FACILITIES", color: "hsla(217, 94%, 47%, 0.6)" },
            { translationKey: "MAPBOX_UNKNOWN", color: "hsla(197, 79%, 19%, 0.6)" },
          ]
        }
      },
      {
        id: "Broenddaeksel",
        name: "Brønddæksel",
        translationKey: "MAPBOX_MANHOLE_COVER",
        layerKey: null,
        hasLegend: false,
      },
      {
        id: "Bygvaerk",
        name: "Bygværk",
        translationKey: "MAPBOX_CONSTRUCTION_STRUCTURE",
        layerKey: null,
        hasLegend: false,
      },
      {
        id: "Hoefde",
        name: "Høfde",
        translationKey: "MAPBOX_GROYNE",
        layerKey: null,
        hasLegend: false,
      },
      {
        id: "Mast",
        name: "Mast",
        translationKey: "MAPBOX_MAST",
        layerKey: null,
        hasLegend: false,
      },
      {
        id: "Nedloebsrist",
        name: "Nedløbsrist",
        translationKey: "MAPBOX_DRAN_GRATE",
        layerKey: null,
        hasLegend: false,
      },
      {
        id: "Parkering",
        name: "Parkering",
        translationKey: "MAPBOX_PARKING",
        layerKey: null,
        hasLegend: false,
      },
      {
        id: "SandKlit",
        name: "Sandklit",
        translationKey: "MAPBOX_SAND_DUNE",
        layerKey: null,
        hasLegend: false,
      },
      {
        id: "Skorsten",
        name: "Skorsten",
        translationKey: "MAPBOX_CHIMNEY",
        layerKey: null,
        hasLegend: false,
      },
      {
        id: "StatueSten",
        name: "Statue/Sten",
        translationKey: "MAPBOX_STATUE_STONE",
        layerKey: null,
        hasLegend: false,
      },
      {
        id: "TekniskAnlaegFlade",
        name: "Teknisk anlæg - flade",
        translationKey: "MAPBOX_TECHNICAL_FACILITY_FLAT",
        layerKey: null,
        hasLegend: false,
      },
      {
        id: "TekniskAnlaegPunkt",
        name: "Teknisk anlæg - punkt",
        translationKey: "MAPBOX_TECHNICAL_FACILITY_POINT",
        layerKey: null,
        hasLegend: false,
      },
      {
        id: "TekniskAreal",
        name: "Teknisk areal",
        translationKey: "MAPBOX_TECHNICAL_AREA",
        layerKey: null,
        hasLegend: false,
      },
      {
        id: "Telemast",
        name: "Telemast",
        translationKey: "MAPBOX_CELL_TOWER",
        layerKey: null,
        hasLegend: false,
      },
      {
        id: "Vindmoelle",
        name: "Vindmølle",
        translationKey: "MAPBOX_WINDMILL",
        layerKey: null,
        hasLegend: false,
      },
    ]
  },
  {
    section: "GeoDanmark - Øvrige",
    translationKey: "MAPBOX_GEODANMARK_OTHERS",
    icon: "list_alt",
    layer: [
      {
        id: "Bykerne",
        name: "Bykerne",
        translationKey: "MAPBOX_CITY_CENTRE",
        layerKey: null,
        hasLegend: false
      },
      {
        id: "DHMHestesko",
        name: "DHMHestesko",
        translationKey: "MAPBOX_DHMHESTESKO",
        layerKey: null, // Doesn't seem to have styles propeties in Mapbox studio
        hasLegend: true,
        legend: {
          header: {
            translationKey: "MAPBOX_DHMHESTESKO",
            color: "--color-black-900",
          },
          items: [
            { translationKey: "MAPBOX_OPEN_STREAM", color: "hsla(36, 100%, 51%, 0.6)" },
            { translationKey: "MAPBOX_PIPED_STREAM", color: "hsla(0, 98%, 52%, 0.6)" },
            { translationKey: "MAPBOX_NO_STREAM", color: "hsla(180, 38%, 50%, 0.59)" },
            { translationKey: "MAPBOX_NOT_ASSIGNED", color: "hsla(300, 69%, 39%, 0.6)" },
          ],
        },
      },
      {
        id: "DHMLinje",
        name: "DHMLinje",
        translationKey: "MAPBOX_DHMLINJE",
        layerKey: null, // Doesn't seem to have styles propeties in Mapbox studio
        hasLegend: true,
        legend: {
          header: {
            translationKey: "MAPBOX_DHMLINJE",
            color: "--color-black-900",
          },
          items: [
            { translationKey: "MAPBOX_OPEN_STREAM", color: "hsla(36, 100%, 51%, 0.6)" },
            { translationKey: "MAPBOX_PIPED_STREAM", color: "hsla(0, 98%, 52%, 0.6)" },
            { translationKey: "MAPBOX_NO_STREAM", color: "hsla(180, 38%, 50%, 0.59)" },
            { translationKey: "MAPBOX_NOT_ASSIGNED", color: "hsla(300, 69%, 39%, 0.6)" },
          ],
        },
      },
      {
        id: "UdpegningFlade",
        name: "Udpegningsflade",
        translationKey: "MAPBOX_DESIGNATION_FLAT",
        layerKey: null,
        hasLegend: false
      },
      {
        id: "UdpegningLinje",
        name: "Udpegningslinje",
        translationKey: "MAPBOX_DESIGNATION_LINE",
        layerKey: null,
        hasLegend: false
      },
      {
        id: "UdpegningPunkt",
        name: "Udpegningspunkt",
        translationKey: "MAPBOX_DESIGNATION_POINT",
        layerKey: null,
        hasLegend: false
      },
      {
        id: "Hede",
        name: "Hede",
        translationKey: "MAPBOX_MOOR",
        layerKey: null,
        hasLegend: false
      },
      {
        id: "Parkeringsomraade",
        name: "Parkeringsområde",
        translationKey: "MAPBOX_PARKING_AREA",
        layerKey: null,
        hasLegend: false
      },
      {
        id: "Plads",
        name: "Plads",
        translationKey: "MAPBOX_LOT",
        layerKey: null,
        hasLegend: false
      },
      {
        id: "Raastofomraade",
        name: "Råstofområde",
        translationKey: "MAPBOX_RAW_MATERIAL_AREA",
        layerKey: null,
        hasLegend: false
      },
      {
        id: "RekreativtOmraade",
        name: "Rekreativtområde",
        translationKey: "MAPBOX_RECREATIONAL_AREA",
        layerKey: null,
        hasLegend: false
      },
      {
        id: "Sportsbane",
        name: "Sportsbane",
        translationKey: "MAPBOX_SPORTS_FIELD",
        layerKey: null,
        hasLegend: false
      },
      {
        id: "Vandafstroemningsopland",
        name: "Vandafstrømningsopland",
        translationKey: "MAPBOX_WATER_DRAINAGE_BASIN",
        layerKey: null,
        hasLegend: false
      },
      {
        id: "Vandhaendelse",
        name: "Vandhændelse",
        translationKey: "MAPBOX_WATER_EVENT",
        layerKey: null,
        hasLegend: false
      },
    ]
  },
];
