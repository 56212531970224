
export default {
  inheritAttrs: false,

  props: {
    colorBackground: {
      type: String,
      required: true,
    },

    colorForeground: {
      type: String,
      required: true,
    },

    opacityBackground: {
      type: String,
      required: true,
    },

    opacityForeground: {
      type: String,
      required: true,
    },

    opacityEffectDisable: {
      type: Boolean,
      default: false,
    },

    calculateHeightByParent: {
      type: Boolean,
      default: false,
    },

    cursorPointerDisable: {
      type: Boolean,
      default: false,
    },
  },

  mounted: function () {
    if (this.calculateHeightByParent) {
      this.$refs.container.style.height = "100%";

      try {
        const height = Math.floor(this.$refs.container.parentElement.scrollHeight);

        if (height > 0) this.$refs.container.style.height = `${height}px`;
      } catch (e) {
        // swallow
      }
    }
  },
};
