// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(function(i){return i[1]});
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".row_zWowJ{display:flex;height:100%;width:100%}.row_zWowJ:not(:last-child){margin-bottom:1px}.cell_2tcIU{display:flex;height:100%;width:100%}.cell_2tcIU:not(:last-child){margin-right:1px}", ""]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"row": "row_zWowJ",
	"cell": "cell_2tcIU"
};
module.exports = ___CSS_LOADER_EXPORT___;
