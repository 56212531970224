
export default {
  props: {
    colorBackground: {
      type: String,
      required: true,
    },

    colorSeperators: {
      type: String,
      required: true,
    },

    shadow: {
      type: Boolean,
      default: false,
    },

    rounded: {
      type: Boolean,
      default: false,
    },

    alignControlsEnd: {
      type: Boolean,
      default: false,
    },
  },

  methods: {
    controlsStartPopulated() {
      return !!this.$slots.controlsStart;
    },

    controlsEndPopulated() {
      return !!this.$slots.controlsEnd;
    },

    controlsHeaderPopulated() {
      return !!this.$slots.header;
    },

    controlsBylinePopulated() {
      return !!this.$slots.byline;
    },

    controlsInfoPopulated() {
      return !!this.$slots.info;
    },

    controlsKeyFiguresPopulated() {
      return !!this.$slots.keyFigures;
    },

    controlsChipsPopulated() {
      return !!this.$slots.chips;
    },

    controlsBottomPopulated() {
      return !!this.$slots.controlsBottom;
    },
  },
};
