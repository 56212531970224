export const state = () => ({
  drawnCoordinates: [],
  coordinates: null,
});

export const getters = {
  getDrawnCoordinates(state) {
    return state.drawnCoordinates;
  },

  getCoordinates(state) {
    return state.coordinates;
  },
};

export const actions = {
  setDrawDone() {
    const coordinates = this.app.store.getters["areas/getDrawnCoordinates"];
    this.app.store.commit("areas/setCoordinates", coordinates);
    this.app.router.push({
      name: "explore-area-rent-levels",
    });
  },
};

export const mutations = {
  setCoordinates(state, coordinates) {
    state.coordinates = coordinates;
  },

  setDrawnCoordinates(state, coordinates) {
    state.drawnCoordinates = coordinates;
  },
};

export default {
  namespaced: true,
  state,
  getters,
  mutations,
  actions,
};
