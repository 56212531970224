// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(function(i){return i[1]});
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".container_6QfTN{align-items:center;background-color:var(--color-supportive-3-100);bottom:0;display:flex;justify-content:center;left:0;position:absolute;right:0;top:0;z-index:1}", ""]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"container": "container_6QfTN"
};
module.exports = ___CSS_LOADER_EXPORT___;
