var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('Composition',{staticClass:"widget-widget-blank",attrs:{"explodeHeight":""},scopedSlots:_vm._u([{key:"backgrounds",fn:function(){return [_c('CompositionBackgroundCard',{attrs:{"color":_vm.colorBackground,"rounded":_vm.rounded,"shadow":_vm.shadow}})]},proxy:true},{key:"layouts",fn:function(){return [_c('Composition',{attrs:{"explodeHeight":"","loadingEnabled":_vm.loadingEnabled,"loading":_vm.loading,"loadingColor":"--color-black-50"},scopedSlots:_vm._u([{key:"layouts",fn:function(){return [_c('CompositionLayoutSingle',{attrs:{"omitPadding":""},scopedSlots:_vm._u([{key:"container",fn:function(){return [_c('div',{class:[
                _vm.$style.layout,
                _setup.widgetScroll && _vm.$style.widgetScroll,
                _vm.paddingDouble && _vm.$style.paddingDouble,
                _vm.omitPadding && _vm.$style.omitPadding,
                _vm.omitPaddingTop && _vm.$style.omitPaddingTop,
                _vm.omitPaddingRight && _vm.$style.omitPaddingRight,
                _vm.omitPaddingBottom && _vm.$style.omitPaddingBottom,
                _vm.omitPaddingLeft && _vm.$style.omitPaddingLeft,
                _vm.omitPaddingContent && _vm.omitClickMore && _vm.$style.omitPaddingBottom,
                _vm.omitPaddingContent && !_vm.omitClickMore && _vm.$style.omitPaddingBottomClickMore,
              ]},[(_vm.label.length > 0 || _vm.byline.length > 0 || _setup.slotControlsPopulated)?_c('div',{class:[_vm.$style.texts, _vm.byline.length === 0 && _vm.$style.textsOmitPaddingBottom]},[_c('div',{class:_vm.$style.header},[(_vm.label.length > 0)?_c('div',{class:[_vm.$style.label, _vm.byline.length === 0 && _vm.$style.textsOmitPaddingBottom, _vm.justifyTextsCenter && _vm.$style.justifyTextsCenter]},[_c('Copy',{attrs:{"type":"label","size":"small","color":_vm.colorForeground}},[_vm._v("\n                      "+_vm._s(_vm.label)+"\n                    ")])],1):_vm._e(),_vm._v(" "),(_setup.slotControlsPopulated)?_c('div',[_vm._t("controls")],2):_vm._e()]),_vm._v(" "),(_vm.byline.length > 0)?_c('div',{class:[_vm.$style.byline, _vm.justifyTextsCenter && _vm.$style.justifyTextsCenter]},[_c('Copy',{attrs:{"type":"body","size":"medium","color":_vm.colorForeground}},[_vm._v("\n                    "+_vm._s(_vm.byline)+"\n                  ")])],1):_vm._e()]):_vm._e(),_vm._v(" "),_c('div',{class:[
                  _vm.$style.slot,
                  _vm.row && _vm.$style.row,
                  _setup.widgetScroll && _vm.$style.widgetScroll,
                  _vm.justifyContentCenter && _vm.$style.justifyContentCenter,
                  _vm.justifyContentEnd && _vm.$style.justifyContentEnd,
                  _vm.alignItemsEnd && _vm.$style.alignItemsEnd,
                  _vm.alignItemsCenter && _vm.$style.alignItemsCenter,
                  _vm.omitPaddingContent && _vm.$style.omitPaddingContent,
                ]},[_vm._t("container")],2),_vm._v(" "),(!_vm.omitClickMore)?_c('div',{class:_vm.$style.cta},[_c(_setup.Cta,{attrs:{"color":_vm.colorForeground,"label":_vm.clickMoreLabel ? _vm.clickMoreLabel : _setup.t('OVERVIEW_DASHBOARD_SEE_MORE')},on:{"click":() => _setup.emit('widget-click-more')}})],1):_vm._e()])]},proxy:true}],null,true)})]},proxy:true}])})]},proxy:true}])})
}
var staticRenderFns = []

export { render, staticRenderFns }