import { render, staticRenderFns } from "./MapLayerControlAccordion.vue?vue&type=template&id=3713f546"
import script from "./MapLayerControlAccordion.vue?vue&type=script&lang=js"
export * from "./MapLayerControlAccordion.vue?vue&type=script&lang=js"
import style0 from "./MapLayerControlAccordion.vue?vue&type=style&index=0&id=3713f546&prod&module=true&lang=css"




function injectStyles (context) {
  
  this["$style"] = (style0.locals || style0)

}

/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  injectStyles,
  null,
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {Icon: require('/app/node_modules/@estaid/ui/dist/runtime/atoms/Icon/Icon.vue').default,Copy: require('/app/node_modules/@estaid/ui/dist/runtime/atoms/Copy/Copy.vue').default,KeyValuePair: require('/app/node_modules/@estaid/ui/dist/runtime/molecules/KeyValuePair/KeyValuePair.vue').default,Label: require('/app/node_modules/@estaid/ui/dist/runtime/molecules/Label/Label.vue').default,MapLayerControlAccordionLayer: require('/app/components/map/controls/MapLayerControlAccordionLayer.vue').default,Accordion: require('/app/node_modules/@estaid/ui/dist/runtime/molecules/Accordion/Accordion.vue').default})
