
import ExploreChips from "~/components/explore/ExploreChips.vue";
import Geography from "~/components/explore/filters/Geography.vue";
import Ownership from "~/components/explore/filters/Ownership.vue";
import Usage from "~/components/explore/filters/Usage.vue";
import Size from "~/components/explore/filters/Size.vue";
import Construction from "~/components/explore/filters/Construction.vue";

export default {
  components: {
    ExploreChips,
    Geography,
    Ownership,
    Usage,
    Size,
    Construction,
  },

  data() {
    return {
      modalOpen: false,
      dialog: false,
      tab: 0
    };
  },

  computed: {
    geographyLength() {
      return this.$store.getters["explore/getGeography"].length;
    },
    ownersLength() {
      return (
        this.$store.getters["explore/getCvr"].length +
        this.$store.getters["explore/getOwnership"].length
      );
    },
    usageLength() {
      return this.$store.getters["explore/getUsage"].length;
    },
    sizeLength() {
      let num = 0;
      if (this.$store.getters["explore/getSizeSiteAreaEnabled"]) num++;
      if (this.$store.getters["explore/getSizeBuildingAreaEnabled"]) num++;
      if (this.$store.getters["explore/getSizeNoOfFloorsEnabled"]) num++;
      if (this.$store.getters["explore/getSizeNoOfUnitsEnabled"]) num++;
      return num;
    },
    totalFiltersLength() {
      return (
        this.geographyLength +
        this.ownersLength +
        this.usageLength +
        this.sizeLength
      );
    },
    filters() {
      return this.$store.getters["explore/getNumberOfAppliedFilters"];
    }
  },

  methods: {
    initiateFilteredSearch() {
      this.$emit("close")

      if (this.$store.getters["explore/getQueryArray"].length > 0) {
        this.$store.commit("explore/setOffset", 0);
      }

      this.$store.commit("explore/setHasSearchedYet", false);

      this.$router.push({
        name: "explore-advanced",
        params: {},
        query: {}
      });
    }
  }
};
