
import { useThrottle } from "~/helpers/common-helpers"

export default {
  name: "MapPositionUpdateLayer",

  props: {
    throttle: {
      type: Number,
      default: 1000
    }
  },

  data() {
    return {
      callbacks: []
    }
  },

  mounted() {
    this.$map.then(map => {
      if(!map) return
      ;["move", "moveend", "zoom", "zoomend"].forEach(e => {
        const callback = useThrottle(() => {
          this.$emit(e, {
            zoom: map.getZoom(),
            center: map.getCenter(),
            bounds: map.getBounds()
          })
        }, this.throttle)

        this.callbacks.push(callback)

        map.on(e, callback)
      })
    })
  },

  destroyed() {
    this.$map.then(map => {
      ["move", "moveend", "zoom", "zoomend"].forEach((e, i) => {
        map.off(e, this.callbacks[i])
      })
    })
  },

  render() {
    return ""
  }
}
