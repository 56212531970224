import { i18n } from "~/plugins/translator";

export const get_company_address = function get_company_address(company) {
    if (company.street != "" && company.city != "" && company.zipCode != "") {
        let address =
            company.street +
            " " +
            company.houseNumberFrom +
            (company.houseNumberTo != 0 ? "-" + company.houseNumberTo : "") +
            ", " +
            company.zipCode +
            " " +
            company.city;
        return address;
    }
};
export const get_person_address = function get_person_address(person) {
    if (!person.streetName || !person.streetNumber) {
        return;
    }
    let address = person.streetName + " " + person.streetNumber;
    if (person.floorNumber != "" && person.floorNumber != null) {
        address += " " + person.floorNumber;
    }
    if (person.doorNumber != "" && person.doorNumber != null) {
        address += " " + person.doorNumber;
    }
    address += ", " + person.postalCode + " " + person.city;
    return address;
};



export const capitalize_first_letter = function capitalize_first_letter(text) {
    return text.charAt(0).toUpperCase() + text.substring(1);
};
export const group_digits_in_number = function group_digits_in_number(number) {
    if (!number) return null;
    return number.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ".");
};


export const filter_valuations = function filter_valuations(valuations) {
    // SORT VALUATIONS BY YEAR
    let sortedValuations = [...valuations].sort((a, b) => b.year - a.year);

    // GROUP VALUATIONS BY YEAR
    let grouped = sortedValuations.reduce(function (rv, x) {
        (rv[x["year"]] = rv[x["year"]] || []).push(x);
        return rv;
    }, {});

    let filtered = [];

    // ITERATE OVER GROUPS, SORT MULTIPLE VALUES BY MODIFICATION DATE AND SELECT NEWEST
    Object.entries(grouped).forEach((values) => {
        if (values[1].length > 1) {
            let sorted = values[1].sort((a, b) => {
                var d1 = Date.parse(a.valuationDate);
                var d2 = Date.parse(b.valuationDate);
                return d2 - d1;
            });
            filtered.push(sorted[0]);
        } else {
            filtered.push(values[1][0]);
        }
    });
    return filtered.sort((a, b) => b.year - a.year);
};

export const selectTabInUrl = function selectTabInUrl(tabIndex) {
    this.$router.replace({
        path: this.$route.path,
        query: {
            activeTab: tabIndex,
        },
    });
};
export const getSelectedTabFromUrl = function getSelectedTabFromUrl(that) {
    if (that.$route.query.activeTab && that.$route.query.activeTab !== "") {
        let tabIndex = parseInt(that.$route.query.activeTab);

        if (isNaN(tabIndex)) {
            selectTabInUrl(0);
            return 0;
        }
        return tabIndex;
    } else {
        return 0;
    }
};
export const get_owner_type = function get_owner_type(code, language) {
    switch (code) {
        case "10":
            return i18n.t("OWNERS_TYPE_PERSON");
        case "20":
            return i18n.t("OWNERS_TYPE_ALMBOLIG");
        case "30":
            return i18n.t("OWNERS_TYPE_COMPANY");
        case "40":
            return i18n.t("OWNERS_TYPE_FLI");
        case "41":
            return i18n.t("OWNERS_TYPE_AND");
        case "50":
            return i18n.t("OWNERS_TYPE_BKOM");
        case "60":
            return i18n.t("OWNERS_TYPE_AKOM");
        case "70":
            return i18n.t("OWNERS_TYPE_REG");
        case "80":
            return i18n.t("OWNERS_TYPE_STAT");
        case "90":
            return i18n.t("OWNERS_TYPE_OTHER");
        case "99":
            return i18n.t("OWNERS_TYPE_IKKEBEREGNET");
        default:
            return "-";
    }
};
