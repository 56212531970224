// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(function(i){return i[1]});
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".pano_4yD9o{height:100%}", ""]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"pano": "pano_4yD9o"
};
module.exports = ___CSS_LOADER_EXPORT___;
