
import { fitToGeoJsonBounds } from "~/helpers/map-helpers.js";

const yellow900 = getComputedStyle(document.documentElement).getPropertyValue("--color-yellow-600").replace(" ", "");

const black300 = getComputedStyle(document.documentElement).getPropertyValue("--color-black-300").replace(" ", "");

const black900 = getComputedStyle(document.documentElement).getPropertyValue("--color-black-900").replace(" ", "");

export default {
  props: {
    visible: {
      type: Boolean,
      default: false,
    },

    showOrtoForaar: {
      type: Boolean,
      default: false,
    },

    data: {
      type: Object,
      default: null,
    },

    layer: {
      type: Object,
      required: true,
    },

    source: {
      type: String,
      required: true,
    },

    fitToDataOnUpdate: {
      type: Boolean,
      default: false,
    },
  },

  watch: {
    visible(val) {
      this.setVisible(val);
    },

    showOrtoForaar(val) {
      this.setShowOrtoForaar(val);
    },

    data: {
      deep: true,
      handler(val) {
        this.setData(val);
      },
    },
  },

  mounted() {
    this.setVisible(this.visible);
    this.setData(this.data);

    const events = Object.keys(this.$listeners);
    this.$map.then((map) => {
      for (let i = 0; i < events.length; i++) {
        map?.on(
          events[i],
          this.layer.id,
          function (e) {
            this.$emit(events[i], e);
          }.bind(this)
        );
      }
    });
  },

  destroyed() {
    this.setVisible(false);

    const events = Object.keys(this.$listeners);
    this.$map.then((map) => {
      for (let i = 0; i < events.length; i++) {
        map?.off(
          events[i],
          this.layer.id,
          function (e) {
            this.$emit(events[i], e);
          }.bind(this)
        );
      }
    });
  },

  methods: {
    fitToGeoJsonBounds,

    setVisible(val) {
      this.$map.then((map) => {
        if (map == undefined || map?.getLayer(this.layer.id) == undefined) return;

        map.setLayoutProperty(this.layer.id, "visibility", val ? "visible" : "none");
      });
    },

    setShowOrtoForaar(val) {
      this.$map.then((map) => {
        if (map && map.getLayer("plot_polygon_borders")) {
          map.setPaintProperty("plot_polygon_borders", "line-color", val ? yellow900 : black300);
          map.setPaintProperty("plot_polygon_borders", "line-width", val ? 2 : 1);
        }

        if (map && map.getLayer("plot_polygon_matrikel_numbers")) {
          map.setPaintProperty("plot_polygon_matrikel_numbers", "text-color", val ? yellow900 : black900);
        }
      });
    },

    setData(val) {
      if (!this.data) {
        return;
      }

      this.$map.then((map) => {
        map?.getSource(this.source).setData(val);
        if (this.fitToDataOnUpdate) {
          this.fitToGeoJsonBounds(val);
        }
      });
    },
  },
};
