
import googleMapsLoader from "~/plugins/google-maps-loader.js";
import { isStreetviewAvailable } from "~/helpers/map-helpers.js";

// All map related functions are wrapped in mapLoaded.then(() => {}) to ensure
// loading of google maps before functions. Same could be done for coverage and
// panorama, but these can only be initialized when map is visible on screen.

export default {
  name: "StreetView",

  // Variables moved out of data() to avoid vue reactivity
  map: null,
  google: null,
  googleLoaded: null,
  streetViewCoverageLayer: null,
  streetViewPanorama: null,

  inheritAttrs: false,

  props: {
    mapType: {
      type: String,
      required: true,
    },

    bounds: {
      type: Object,
      default() {
        return {};
      },
    },
  },

  data() {
    return {
      mapLoaded: null,
      options: {
        mapId: "5d10d0ccd1a34703",
        zoomControl: false,
        fullscreenControl: false,
        mapTypeControl: false,
        streetViewControl: false,
        keyboardShortcuts: false,
        rotateControl: "disabled",
        center: {
          lat: 55.7426902,
          lng: 12.4924221,
        },
        zoom: 7,
      },
    };
  },

  watch: {
    mapType(val, oldVal) {
      if (oldVal == "streetview" && val != "streetview") {
        // Emit position and zoom to mapbox map
        this.$emit("bounds", {
          zoom: this.$options.map.getZoom(),
          center: this.$options.map.getCenter(),
        });
      }
      if (val === "streetview") {
        // Fit bounds and create coverage layer + panorama obj
        this.initializeMapFeatures();
      }
    },

    bounds(val, oldVal) {
      if (this.mapType === "streetview") {
        this.mapLoaded.then((map) => {
          map.moveCamera(val);
        });
      }
    },
  },

  async mounted() {
    if (this.mapLoaded) {
      return;
    }
    this.mapLoaded = new Promise((resolve) => {
      googleMapsLoader.then((google) => {
        this.$options.google = google;
        this.$options.map = new google.maps.Map(this.$refs.map, this.options);
        this.$options.map.addListener("click", this.mapClick);
        resolve(this.$options.map);
      });
    });
    this.initializeMapFeatures();
  },

  async destroyed() {
    this.mapLoaded.then((map) => {
      this.$options.streetViewCoverageLayer?.setMap(null);
      map.setStreetView(null);
    });
  },

  methods: {
    initializeMapFeatures() {
      if (this.mapType !== "streetview") {
        return;
      }
      if (!this.$options.streetViewCoverageLayer) {
        this.mapLoaded.then((map) => {
          this.$options.streetViewCoverageLayer = new this.$options.google.maps.StreetViewCoverageLayer();
          this.$options.streetViewCoverageLayer.setMap(map);
        });
      }
      if (!this.$options.streetViewPanorama) {
        this.mapLoaded.then((map) => {
          this.$options.streetViewPanorama = map.getStreetView();
          this.$options.streetViewPanorama.setPov({
            heading: 265,
            pitch: 0,
          });
          map.setStreetView(this.$options.streetViewPanorama);
        });
      }
    },

    async mapClick(e) {
      const location = { lat: e.latLng.lat(), lng: e.latLng.lng() };
      const res = await isStreetviewAvailable(location.lat, location.lng);
      if (!res) {
        return;
      }
      this.$options.streetViewPanorama.setPosition(location);
      this.$options.streetViewPanorama.setVisible(true);
    },

    zoomIn() {
      this.mapLoaded.then((map) => {
        map.setZoom(map.getZoom() + 1);
      });
    },

    zoomOut() {
      this.mapLoaded.then((map) => {
        map.setZoom(map.getZoom() - 1);
      });
    },
  },
};
