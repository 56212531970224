import { PERMISSIONS } from "~/composables/usePermissions";
export var useUrlTabsTenancy = function useUrlTabsTenancy() {
  var t = useTranslator();
  var hasSalesPermission = useHasPermissions(PERMISSIONS.PORTFOLIO_SALES_READ_ORG);
  var hasValuationsPermission = useHasPermissions(PERMISSIONS.PORTFOLIO_VALUATIONS_READ_ORG);
  return computed(function () {
    return [{
      key: "overview",
      label: t("PORTFOLIO_TENANCY_OVERVIEW"),
      default: true
    }, {
      key: "tenant",
      label: t("PORTFOLIO_TENANCY_TENANT")
    }, {
      key: "capexes",
      label: t("PORTFOLIO_TENANCY_CAPEX")
    }, {
      key: "sales",
      label: t("PORTFOLIO_TENANCY_SALES"),
      disabled: !hasSalesPermission.result.value
    }, {
      key: "valuations",
      label: t("PORTFOLIO_TENANCY_VALUATIONS"),
      disabled: !hasValuationsPermission.result.value
    }];
  });
};